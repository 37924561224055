import { Link, useLocation } from "react-router-dom";

const formatSegment = (segment) => {
  // You can add your own logic to format the segment as needed
  return segment.replace(/-/g, " ").toLowerCase();
};
export default function Breadcrumbs() {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");

  return (
    <div>
      <Link to="/">Home</Link>
      {/* {pathSegments.map((segment, index) => { */}

      <span>
        {" > "}
        <Link to={pathname}>{formatSegment(pathSegments[0])}</Link>
      </span>

      {/* })} */}
    </div>
  );
}
