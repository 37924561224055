import {
  Box,
  Container,
  Typography,
  CssBaseline,
  Button,
  Divider,
  Chip,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Avatar,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  OutlinedInput,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  CircularProgress,
  Autocomplete,
  Link,
  FormHelperText,
} from "@mui/material";
import React, { useContext } from "react";

import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
// import CameraAltIcon from "@mui/icons-material/CameraAlt";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PostService from "../services/PostService";
import { useCallback } from "react";
import { deepOrange } from "@mui/material/colors";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import { auth } from "../modules/auth/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { UserContext } from "../root";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";
import LocationService from "../services/LocationService";
import axios from "axios";
//UPDATED
import {
  Dropzone,
  FileMosaic,
  FullScreen,
  ImagePreview,
} from "@files-ui/react";
import Settings from "../services/settings";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(price) => {
        onChange({
          target: {
            name: props.name,
            value: price.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function PostAdForm(props) {
  let currentCountryCode = useLoaderData();

  const countryCode = currentCountryCode.toLowerCase();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  // console.log("decoded",decoded);

  const location = useLocation();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userCode, setUserCode] = useState("");

  const { selectedCategory, selectedSubCategory } = location.state || {};
  const [user, setUser] = useState([]);
  const [title, settitle] = useState("");
  const [description, setDescription] = useState("");
  const [phonenum, setphonenum] = useState("");
  const [firstname, setfirstname] = useState("");
  const [state, setstate] = useState(null);
  const [city, setcity] = useState(null);
  const [country, setcountry] = useState(null);
  const [latitude, setLatitude] = useState("");
  const [logtitude, setLongitude] = useState("");
  const [email, setemail] = useState("");
  const [addressDetails, setAddressDetails] = useState("");
  const [price, setprice] = useState({
    numberformat: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [isPhoneChecked, setIsPhoneChecked] = useState(false);
  const [getDynamicFieldList, setDynamicFieldList] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [selectedFilesImage, setSelectedFilesImage] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const progressInfosRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  // const [phone, setPhone] = useState(initialUserService.mobile);
  const [isVerified, setIsVerified] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [code, setCode] = useState("");
  const [formatValue, setFormatValue] = useState(code);
  const [fileError, setFileError] = useState(false);

  //----------------------------------------------------------

  const [extFiles, setExtFiles] = React.useState([]);
  const [imageSrc, setImageSrc] = React.useState(undefined);

  //Dropzone functionlity

  const updateFiles = (incommingFiles) => {
    debugger;
    console.log("incomming files", incommingFiles);
    setExtFiles(incommingFiles);
  };

  useEffect(() => {
    const extractedFiles = extFiles.map((fileObject) => fileObject.file);
    console.log("extractedFiles", extractedFiles);
    setSelectedFilesImage(extractedFiles);
  }, [extFiles]);

  console.log("selectedFilesImageNew", selectedFilesImage);
  const onDelete = (id) => {
    setExtFiles(extFiles.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const handleAbort = (id) => {
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" };
        } else return { ...ef };
      })
    );
  };
  const handleCancel = (id) => {
    console.log("Cancelimgid", id);
    debugger;
    setExtFiles(
      extFiles.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };

  //--------------------------------------------------- dropzone handlers logic ends here --------------

  console.log("selectedCategory", selectedCategory);

  const getCountriesList = () => {
    LocationService.getCountries()
      .then((response) => {
        setCountries(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getStateList = (id) => {
    debugger;
    LocationService.getStateByCountryId(id)
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data && response.data.data.length > 0) {
          setStates(response.data.data);
        } else {
          // If the response data is empty, set "N/A" as the state
          setStates([{ name: "N/A" }]);
          setCities([{ name: "N/A" }]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("setStates", states);
  const getCitiesList = (id) => {
    LocationService.getCityByStateId(id)
      .then((response) => {
        if (response.data.data && response.data.data.length > 0) {
          setCities(response.data.data);
        } else {
          // If the response data is empty, set "N/A" as the state
          setCities([{ name: "N/A" }]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCountriesList();
    getStateList();
    getCitiesList();
  }, []);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const handleSend = (event) => {
    debugger;
    event.preventDefault();
    if (!phonenum) {
      toast.error("Please verify your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setHasFilled(true);
    // setOtpDialogOpen(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+${code}${phonenum}`, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpDialogOpen(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        console.log(error);
      });
  };

  const handleResendOtp = (event) => {
    debugger;
    event.preventDefault();
    // Logic to resend OTP
    setTimer(60); // Reset timer to 60 seconds
    setTimerReachedZero(false); // Reset timer reached zero flag
    if (!phonenum) {
      toast.error("Please verify your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setHasFilled(true);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+${code}${phonenum}`, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpDialogOpen(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_RIGHT,
        });

        console.log(error);
      });
  };

  const verifyOtp = (event) => {
    debugger;
    // let otp = event.target.value;
    setOtp(otp);

    if (!otp) {
      toast.error("Please enter your Otp", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (otp.length > 6) {
      toast.error("Otp must be 6 number only", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (otp.length < 6) {
      toast.error("Otp must be 6 number only", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          let user = result.user;
          // console.log(user);
          console.log("User signed in successfully");
          // Close the confirmationResult window
          window.confirmationResult = null;

          // Reset the OTP dialog and data

          setOtpDialogOpen(false);
          setHasFilled(false);
          setIsVerified(true);
          toast.success("Success : OTP verified Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          toast.error("Invalid Otp!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // alert('User couldn\'t sign in (bad verification code?)');
        });
    }
  };

  const handleCloseOtpDialog = () => {
    window.confirmationResult = null;
    setOtpDialogOpen(false);
    setHasFilled(false);
  };

  const handleChangeCategory = () => {
    navigate("/categories");
  };
  const UserId = decoded.sub;

  const getUser = () => {
    PostService.getUser(UserId)
      .then((response) => {
        setUser(response.data.data);
        setfirstname(response.data.data.name);
        setphonenum(response.data.data.mobile);
        setPrevPhone(response.data.data.mobile);
        setIsChecked(response.data.data.hideMobile);
        setPrevPhoneCode(response.data.data.code);
        setCode(response.data.data.code);
        if (response.data.data.mobile) {
          setIsVerified(true);
        }
        setemail(response.data.data.email);

        setUserName(response.data.data.name);
        setUserEmail(response.data.data.email);
        // setUserPhone(response.data.data.mobile)
        console.log("User data", response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const formattedCountryCode = code.startsWith("+") ? code : `+${code}`;

  var updateProfileData = {
    id: UserId,
    name: userName,
    email: userEmail,
    mobile: phonenum,
    code: formattedCountryCode,
  };

  // const phoneCode = `${updateProfileData.code}`; // Combine country code with "+"

  // const phonevalue = code.indexOf("+") == 0 ? `${code}` : `+${code}`;
  // console.log("phonevalue", phonevalue);
  const saveContactNumber = () => {
    if (!phonenum.trim()) {
      toast.error("Please enter your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!isVerified) {
      toast.error("Please verifiy your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if email is blank
    }
    // const phoneCode = `${phonevalue}`; // Combine country code with "+"
    Settings.updateProfile(updateProfileData)
      .then((response) => {
        console.log(response.data.data);
        toast.success("Success: User Mobile No Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSaveContactNo(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSaveContactNo(true);
          //   setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // //   setLoading(false);
        // }

        console.log(error);
      });
  };

  const [initialMobileNumber, setInitialMobileNumber] = useState("");

  // Add an effect to initialize initialMobileNumber when the component mounts
  useEffect(() => {
    // Assuming you can retrieve the initial mobile number from your user data
    if (user.mobile) {
      setInitialMobileNumber(user.mobile);
      setIsVerified(true); // Set isVerified to true when the mobile number is pre-filled
    }
  }, [user.mobile]);

  // Get Dynamic Field list function
  const getDynamicFields = () => {
    PostService.getDynamicFieldList(selectedSubCategory.id)
      .then((response) => {
        setDynamicFieldList(response.data.data);
        setLoading(false);
        console.log("Dynamic List", response.data.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUser();
    getDynamicFields();
    console.log("Here");
  }, []);

  let data = [];
  getDynamicFieldList?.map((item, index) => {
    let temp = {
      [item.title]: "",
    };
    data.push(temp);
  });

  let finalObj = {};
  for (let i = 0; i < data.length; i++) {
    Object.assign(finalObj, data[i]);
  }

  const [dynamicFeild, setDynamic] = useState(finalObj);
  const [dynamicFeildID, setDynamicID] = useState(finalObj);

  const handleChange = useCallback(
    (value) => {
      setDynamic((state) => ({ ...state, ...value }));
      console.log("dynamicFeild", dynamicFeild);
    },
    [dynamicFeild]
  );

  const handleChangeID = useCallback(
    (value) => {
      setDynamicID((state) => ({ ...state, ...value }));
      //console.log("dynamicFeildID",dynamicFeildID);
    },
    [dynamicFeildID]
  );

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch address using reverse geocoding
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCFGhHTFklBNiQGjvV3h03rc_vN995emG0`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              const address = data.results[0].formatted_address;
              console.log("Current Location Address: " + address);

              let country, state, city;

              for (const component of data.results[0].address_components) {
                console.log(component);
                if (component.types.includes("country")) {
                  country = component.long_name;
                }
                if (component.types.includes("administrative_area_level_1")) {
                  state = component.long_name;
                }

                if (
                  component.types.includes("administrative_area_level_3")
                  // component.types.includes("political")
                ) {
                  city = component.long_name;
                }
              }
              console.log("Current Location Country: " + country);
              console.log("Current Location State: " + state);
              console.log("Current Location City: " + city);

              setAddressDetails(address);
              setcountry({ name: country });
              setstate({ name: state });
              setcity({ name: city });
              // Do something with the address, e.g., update UI
            })
            .catch((error) => {
              console.error("Error getting address:", error);
            });

          // Set latitude and longitude in state or do other tasks
          setLatitude(latitude);
          setLongitude(longitude);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.error("Geolocation not available in this browser.");
    }
  };

  // Move the updateCities logic into a separate function
  const updateCities = async () => {
    debugger;
    if (country && state && state.name) {
      // Find the matching country based on the selected country
      const matchingCountry = countries.find((c) => c.name === country.name);

      // Check if a matching country is found and it has the 'states' property
      if (matchingCountry) {
        // Fetch the states for the matching country
        await getStateList(matchingCountry.id);
        console.log("states", states);

        // Find the matching state based on the selected state within the matching country's states
        const matchingState = states.find((s) => s.name === state.name);

        // Check if a matching state is found
        if (matchingState) {
          // Fetch the cities for the matching state
          await getCitiesList(matchingState.id);

          // Now you have the cities list, and you can do any further processing
          // For example, set the cities in state or update the UI
        }
      }
    }
  };

  // Use useEffect to call updateCities when necessary
  useEffect(() => {
    updateCities();
  }, [country, state]);

  // Call the function to get the user's location when the component mounts
  useEffect(() => {
    getUserLocation();
  }, []);

  const isValidTitle = (title) => {
    // Regular expression to allow only letters and numbers
    const pattern = /^[A-Za-z0-9]+$/;
    return pattern.test(title);
  };

  //validating the elements

  const [saveContactNo, setSaveContactNo] = useState();

  const uploadPOST = async (event) => {
    debugger;
    event.preventDefault();
    setFormSubmitted(true);

    // if (isPhoneChecked) {
    //   console.log("isPhoneChecked", isPhoneChecked);
    //   saveContactNumber();
    // } else {
    //   return;
    // }

    try {
      if (!title.trim()) {
        toast.error("Please enter your Title", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }
      if (!description.trim()) {
        toast.error("Please enter your Description", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }

      let dynamicFieldValid = true;

      for (let i = 0; i < getDynamicFieldList.length; i++) {
        const item = getDynamicFieldList[i];
        switch (item.type) {
          case "dropdown":
            if (!dynamicFeild[item.title]) {
              toast.error(`${item.title} field can't be blank`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              dynamicFieldValid = false;
              return; // Stop validation and return after showing the error
            }
            break;
          case "text":
            if (!dynamicFeild[item.title]) {
              toast.error(`${item.title} field can't be blank`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              dynamicFieldValid = false;
              return; // Stop validation and return after showing the error
            } else if (item.title === "Salary From") {
              const salaryFrom = parseInt(dynamicFeild["Salary From"]);
              const salaryTo = parseInt(dynamicFeild["Salary To"]);
              if (salaryFrom >= salaryTo) {
                toast.error("Salary To must be more than Salary From", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                dynamicFieldValid = false;
                return; // Stop validation and return after showing the error
              }
            }
            break;
          case "radiobutton":
            if (!dynamicFeild[item.title]) {
              toast.error(`${item.title} field can't be blank`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              dynamicFieldValid = false;
              return; // Stop validation and return after showing the error
            }
            break;
          // Add more cases for other field types if needed
          default:
            break;
        }
      }

      // If any dynamic field validation fails, return early
      if (!dynamicFieldValid) {
        return;
      }

      if (selectedCategory.priceRequired) {
        if (price.numberformat === "") {
          // Display a custom error message for blank values
          toast.error("Price cannot be blank", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        if (!price || price.numberformat <= 0) {
          // Display an error message if 'price' is falsy or less than or equal to zero
          toast.error("Price must be a positive value", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return; // Return early if 'price' is not valid
        }
        if (price.numberformat > 9999999999) {
          // Display a custom error message for blank values
          toast.error("Price has a maximum value of 100000000.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      }
      console.log("file length", extFiles.length);
      // Check if selectedFiles is not empty
      if (extFiles.length === 0) {
        toast.error("No files selected.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      // Check if the number of selected files is greater than 6
      if (extFiles.length > 6) {
        toast.error("You can only select up to 6 images.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      // Validate file extensions
      const allowedExtensions = ["png", "jpg", "jpeg", "heic"];
      debugger;
      const invalidFiles = selectedFilesImage.filter((file) => {
        const fileName = file.name.toLowerCase();
        const fileExtension = fileName.split(".").pop();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length > 0) {
        toast.error("Only PNG, JPG, and JPEG files are allowed.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (!addressDetails.trim()) {
        toast.error("Please enter your Location", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }
      if (!country) {
        toast.error("please enter your Country", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }
      if (!state) {
        toast.error("Please enter your State", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }
      if (!city) {
        toast.error("Please enter your City", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }
      if (!firstname.trim()) {
        toast.error("please Enter your Name", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }
      if (!phonenum.trim()) {
        toast.error("Please enter your mobile number", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      if (!isVerified) {
        toast.error("Please verifiy your mobile number", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return; // Return early if email is blank
      }

      if (isPhoneChecked) {
        debugger;
        console.log("isPhoneChecked", isPhoneChecked);
        // Validate and save contact number
        const saveContactNumberResult = saveContactNumber();

        if (saveContactNo) {
          toast.error("Please deselect save a primary contact", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
      }
      // else {
      //   // If isPhoneChecked is false, return early
      //   return;
      // }

      const productAttributesvalues = Object.values(dynamicFeild).filter(
        (value) => value !== ""
      );
      const productAttributesId = Object.values(dynamicFeildID).filter(
        (value) => value !== ""
      );

      console.log(title, description, phonenum, firstname, state);

      // Define formData here and initialize it as an empty FormData object
      let formData = new FormData();
      formData.append("userId", UserId);
      formData.append("description", description.trim());
      formData.append("slug", title.trim().replace(/\s+/g, "-"));
      formData.append("title", title.trim());
      formData.append("subCategoryId", selectedSubCategory.id);
      formData.append("contactPhone", code + phonenum);
      // formData.append("contactCode", code);
      formData.append("contactName", firstname.trim());
      formData.append("hide", isChecked);
      // formData.append("isMobileVisibility", isPhoneChecked);
      // formData.append("state", state.name);
      if (state.name === "N/A") {
        formData.append("state", " ");
      } else {
        formData.append("state", state.name);
      }
      if (city.name === "N/A") {
        formData.append("city", "");
      } else {
        formData.append("city", city.name);
      }
      formData.append("country", country.name);
      formData.append("lat", latitude);
      formData.append("lng", logtitude);
      if (state.name === "N/A") {
        formData.append("location", addressDetails.trim());
      } else if (state.name === "") {
        formData.append("location", addressDetails.trim() + " " + state.name);
      } else {
        formData.append("location", addressDetails.trim());
      }
      console.log("form-data", formData); //form-data

      formData.append("productTagsId", []);
      formData.append("price", price.numberformat);
      formData.append("productAttributesId", productAttributesId);
      formData.append("productAttributesvalue", productAttributesvalues);

      // Create an empty JavaScript object to store the formData contents
      const formDataObject = {};
      // Iterate over the formData object and populate the formDataObject
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      // console.log(JSON.stringify(formDataObject));
      console.log("selectedFilesImage", selectedFilesImage);
      // Check if formData and selectedCategory are defined before navigating
      if (formData && selectedCategory) {
        navigate("/postad-step-next", {
          state: {
            formDataNew: formDataObject,
            selectedFilesIMage: selectedFilesImage,
            selectedSubCategoryNew: selectedCategory,
            countryselected: country,
          },
        });
      } else {
        console.error("formData or selectedCategory is undefined or invalid.");
      }
    } catch (error) {
      // Handle any errors that occur during the execution of the function
      console.error("An error occurred:", error);
    }
  };

  const maxTitleCharacterCount = 70;
  const maxDescriptionCharacterCount = 1000;

  const [timer, setTimer] = useState(60);
  const [timerReachedZero, setTimerReachedZero] = useState(false);

  useEffect(() => {
    let interval;
    if (otpDialogOpen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerReachedZero(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [otpDialogOpen, timer]);

  const [prevPhone, setPrevPhone] = useState("");
  const [prevPhoneCode, setPrevPhoneCode] = useState("");

  const phoneNumberWithCode = `${code}${phonenum}`;
  const previousNumber = `${prevPhoneCode}${prevPhone}`;

  useEffect(() => {
    console.log("prevPhone", previousNumber, "Phone", phoneNumberWithCode);

    if (previousNumber === phoneNumberWithCode) {
      setIsVerified(true);
    }
  }, [previousNumber, phoneNumberWithCode]);

  if (!hasFilled) {
    return (
      <div className="app__container">
        <Container component="main" maxWidth="md" sx={{ width: 750 }}>
          <CssBaseline />

          {loading ? (
            <div className="loading-spinner-overlay">
              <div className="loading-spinner">
                <CircularProgress size={60} /> {/* Customize size as needed */}
              </div>
            </div>
          ) : (
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                borderRadius: "15px",
                padding: "15px",
                background: "#fff",
              }}
            >
              <Typography component="h1" variant="h5" sx={{ fontWeight: 500 }}>
                SELECTED CATEGORY
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography
                  component="h3"
                  variant="h6"
                  sx={{ fontWeight: 400, marginRight: "auto" }}
                >
                  {selectedCategory?.name}
                  <i
                    class="fa fa-angle-right"
                    style={{ marginLeft: "10px", fontWeight: 600 }}
                  ></i>{" "}
                  {selectedSubCategory?.name}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleChangeCategory}
                >
                  Change Category
                </Button>
              </Box>
              <Divider>
                <Chip label="INCLUDE SOME DETAILS" />
              </Divider>
              <div>
                <TextField
                  name="title"
                  label="Ad Title"
                  fullWidth
                  margin="normal"
                  helperText="Mention the key features of your Ads"
                  required
                  error={formSubmitted && !title.trim()} //error on empty field
                  autoFocus
                  id="adtitle"
                  autoComplete="title"
                  value={title}
                  // onChange={(e) => settitle(e.target.value)}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= maxTitleCharacterCount) {
                      settitle(inputValue);
                    }
                  }}
                  inputProps={{ maxLength: maxTitleCharacterCount }}
                />
                <div
                  style={{
                    marginTop: "-25px",
                    textAlign: "right",
                    color:
                      title.length > maxTitleCharacterCount ? "red" : "inherit",
                  }}
                >
                  {title.length}/{maxTitleCharacterCount}
                </div>
              </div>
              <TextField
                label="Description"
                margin="normal"
                id="addescription"
                // helperText="Include condition, features and reason for selling"
                error={formSubmitted && !description.trim()} //error on empty field
                required
                multiline
                rows={4}
                value={description}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= maxDescriptionCharacterCount) {
                    setDescription(inputValue);
                  }
                }}
                inputProps={{ maxLength: maxDescriptionCharacterCount }}
              />
              <div
                style={{
                  textAlign: "right",
                  color:
                    description.length > maxDescriptionCharacterCount
                      ? "red"
                      : "inherit",
                }}
              >
                {description.length}/{maxDescriptionCharacterCount}
              </div>

              {/* Dynamic attribute list  */}

              {getDynamicFieldList.map((item, index) => (
                <HandleDynamicField
                  key={index}
                  item={item}
                  handleChange={handleChange}
                  handleChangeID={handleChangeID}
                  formSubmitted={formSubmitted} // Pass the formSubmitted prop
                />
              ))}

              {selectedCategory.priceRequired && (
                <TextField
                  fullWidth
                  id="adprice"
                  label="Price"
                  required
                  margin="normal"
                  variant="outlined"
                  name="numberformat"
                  error={formSubmitted && !price.numberformat.trim()}
                  value={price.numberformat}
                  onChange={(e) =>
                    setprice({ ...price, [e.target.name]: e.target.value })
                  }
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  inputProps={{ maxLength: 12 }}
                />
              )}

              <Typography component="h5" variant="h6" sx={{ mb: 1, mt: 2 }}>
                Upload up to 6 photos
              </Typography>

              {/* New functionlity for image upload */}
              <div>
                <Dropzone
                  onChange={updateFiles}
                  // onDrop={handleDrop}
                  minHeight="195px"
                  maxFiles={6}
                  maxFileSize={50 * 1024 * 1024}
                  label="Drag'n drop files here or click to browse"
                  uploadConfig={{
                    autoUpload: true,
                    cleanOnUpload: true,
                  }}
                  actionButtons={{
                    position: "after",
                    abortButton: {},
                    deleteButton: {},
                  }}
                  accept="image/png, image/jpeg, image/jpg"
                >
                  {extFiles.map((file) => (
                    <FileMosaic
                      {...file}
                      key={file.id}
                      onDelete={onDelete}
                      onSee={handleSee}
                      onAbort={handleAbort}
                      onCancel={handleCancel}
                      resultOnTooltip
                      alwaysActive
                      preview
                      info
                    />
                  ))}
                </Dropzone>
                <FullScreen
                  open={imageSrc !== undefined}
                  onClose={() => setImageSrc(undefined)}
                >
                  <ImagePreview src={imageSrc} />
                </FullScreen>
              </div>

              {/* dropzone functionlity ends here*/}

              <Typography
                component="h5"
                variant="h6"
                style={{ marginTop: "8px" }}
              >
                Confirm your location
              </Typography>

              <TextField
                name="addressDetails"
                label="Enter your full address"
                fullWidth
                id="addressDetails"
                margin="normal"
                error={formSubmitted && !addressDetails.trim()} //error on empty field
                required
                autoComplete="location"
                value={addressDetails}
                onChange={(e) => setAddressDetails(e.target.value)}
                inputProps={{ maxLength: 100 }}
              />

              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  {/* <Autocomplete
              options={countries}
              getOptionLabel={(country) => country?.name} // Adjust this to match your data structure
              value={country}
              onChange={(event, newValue) => {
                setcountry(newValue);
                if (newValue) {
                  getStateList(newValue.id)
                } else {
                  // Handle the case where newValue is null, e.g., clear the city data
                  setstate(null);
                  setcity(null);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Country" autoComplete="country" required  margin="normal" variant="outlined" />
              )}
            /> */}
                  <Autocomplete
                    options={countries}
                    id="adnewcountry"
                    getOptionLabel={(country) => country?.name}
                    value={country}
                    disabled={!!state} // Disable country selection if state is selected
                    onChange={(event, newValue) => {
                      setcountry(newValue); // Update the selected country
                      if (newValue) {
                        getStateList(newValue.id);
                      } else {
                        // Handle the case where newValue is null, e.g., clear the city data
                        setcountry(null); // Reset the country value to null when a different country is selected
                        setstate(null); // Reset the state value to null
                        setcity(null); // Reset the city value to null
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Country"
                        autoComplete="country"
                        required
                        error={formSubmitted && !country?.name?.trim()}
                        id="adcountry"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={states}
                    id="adnewstate"
                    getOptionLabel={(state) => state?.name} // Adjust this to match your data structure
                    value={state}
                    disabled={!!city} // Disable country selection if state is selected
                    onChange={(event, newValue) => {
                      setstate(newValue);
                      if (newValue) {
                        getCitiesList(newValue.id);
                      } else {
                        // Handle the case where newValue is null, e.g., clear the city data
                        setstate(null); // Reset the state value to null
                        setcity(null);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select State"
                        autoComplete="state"
                        required
                        error={formSubmitted && !state?.name?.trim()}
                        id="adstate"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    options={cities}
                    id="adnewcity"
                    getOptionLabel={(city) => city?.name} // Adjust this to match your data structure
                    value={city}
                    onChange={(event, newValue) => {
                      setcity(newValue);
                    }}
                    onFocus={updateCities}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select City"
                        autoComplete="city"
                        required
                        error={formSubmitted && !city?.name?.trim()}
                        id="adcity"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Typography component="h5" variant="h6">
                Review your details
              </Typography>
              <Stack direction="row" spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <Avatar
                      alt={user.name}
                      src={user.imageUrl}
                      sx={{ width: 75, height: 75 }}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      id="name"
                      label="Name"
                      margin="normal"
                      required
                      fullWidth
                      error={formSubmitted && !firstname.trim()}
                      value={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      id="email"
                      label="Email"
                      margin="normal"
                      required
                      fullWidth
                      disabled
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Typography component="h5" variant="h6">
                Let's verify your account
              </Typography>
              <Typography component="h6" variant="p">
                Changing mobile number to a non-verified will require
                verification by OTP before proceeding to next step.
              </Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <div style={{ display: "flex" }}>
                  <PhoneInput
                    country={countryCode}
                    name="phone"
                    value={code + phonenum}
                    onChange={(value, formattedValue) => {
                      const phoneNumberWithoutCode = value.replace(
                        formattedValue.dialCode,
                        ""
                      );

                      console.log(
                        "phoneNumberWithoutCode",
                        phoneNumberWithoutCode
                      );
                      setphonenum(phoneNumberWithoutCode);
                      setCode(formattedValue.dialCode);
                      setIsVerified(false);
                    }}
                  />
                  {/* <PhoneInput
      country={"lc"}
      value={phonenum}
      onChange={(value) => {setphonenum(value);  setIsVerified(false);} } // Add the plus symbol here
      // onChange={(value) => setphonenum(`+${value}`)} // Add the plus symbol here
    /> */}
                  {isVerified ? (
                    <CheckCircleIcon
                      style={{ color: "green" }}
                      sx={{ mt: 1 }}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSend}
                      id="verifyotp"
                    >
                      Verify
                    </Button>
                  )}
                </div>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mt: 2, justifyContent: "space-between" }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                  The provided contact number is saved as the primary contact.
                </Typography>
                <Typography>
                  <Typography>
                    <Checkbox
                      checked={isPhoneChecked}
                      id="showphonenumber"
                      onChange={(e) => setIsPhoneChecked(e.target.checked)}
                      sx={{
                        color: deepOrange[800],
                        "&.Mui-checked": {
                          color: deepOrange[600],
                        },
                      }}
                    />
                  </Typography>
                  {/* <Button
                    variant="outlined"
                    size="small"
                    color="warning"
                    onClick={saveContactNumber}
                  >
                    Save
                  </Button> */}
                  {/* <Checkbox
                    checked={isPhoneChecked}
                    id="phonenumberischecked"
                    onChange={(e) => setIsPhoneChecked(e.target.checked)}
                    sx={{
                      color: deepOrange[800],
                      "&.Mui-checked": {
                        color: deepOrange[600],
                      },
                    }}
                  /> */}
                </Typography>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "space-between" }}
              >
                <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                  Show my phone number in this ad
                </Typography>
                <Typography>
                  <Checkbox
                    checked={isChecked}
                    id="showphonenumber"
                    onChange={(e) => setIsChecked(e.target.checked)}
                    sx={{
                      color: deepOrange[800],
                      "&.Mui-checked": {
                        color: deepOrange[600],
                      },
                    }}
                  />
                </Typography>
              </Stack>
              <div style={{ padding: 2, margin: 5 }}>
                <Button variant="contained" onClick={uploadPOST} id="nextstep">
                  Next
                </Button>
              </div>
            </Box>
          )}
        </Container>
        <div id="recaptcha"></div>
      </div>
    );
  } else if (hasFilled) {
    return (
      <div className="app__container">
        <Dialog
          open={otpDialogOpen}
          onClose={handleCloseOtpDialog}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogContent>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseOtpDialog}
              aria-label="close"
              sx={{ position: "absolute", top: 5, right: 12 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ mt: "10px", mb: "10px" }}
              variant="h5"
              component="div"
            >
              Enter the OTP
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            {timerReachedZero ? (
              <div style={{ marginTop: 10 }}>
                <span variant="body2">If you didn't received a code? </span>
                <Link
                  variant="outlined"
                  color="primary"
                  onClick={handleResendOtp}
                  sx={{ mt: 2, cursor: "pointer" }}
                  size="small"
                >
                  Resend OTP
                </Link>
              </div>
            ) : (
              <div style={{ marginTop: "10px" }}>
                Time left:{" "}
                {Math.floor(timer / 60).toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                })}
                :
                {(timer % 60).toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                })}
              </div>
            )}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="warning"
              onClick={verifyOtp}
              loading={loading}
              loadingPosition="start"
              startIcon={loading ? <CircularProgress size={20} /> : null}
              sx={{ mt: 2 }}
            >
              Verify Code
            </LoadingButton>
          </DialogContent>
        </Dialog>
        <div id="recaptcha"></div>
      </div>
    );
  }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function HandleDynamicField({
  item,
  handleChange,
  handleChangeID,
  formSubmitted,
}) {
  console.log("HandleDynamicFieldformSubmitted", formSubmitted);
  const [selectedName, setSelectedName] = useState("");
  const [selectedSubName, setSelectedSubName] = useState("");
  const [selectedSubId, setselectedSubId] = useState("");
  const [selectedOption, setSelecteOption] = useState("");

  const [dropdownError, setDropdownError] = useState(true);
  const [textError, setTextError] = useState(true);
  const [radioButtonError, setRadioButtonError] = useState(true);
  const [subDropdownError, setSubDropdownError] = useState(true);

  console.log("HandleDynamicFieldformSubmitted", formSubmitted, dropdownError);

  // // Define the 'setSelected' and 'select' variables here
  // const [setSelected, select] = useState("");

  let data = [];
  let option = [];

  if (item.type === "dropdown") {
    item.values.forEach((value) => {
      data.push(value.name);
      if (selectedName === value.name) {
        value.options.forEach((optionValue) => {
          option.push(optionValue.name);
        });
      }
    });
  }

  switch (item.type) {
    case "dropdown":
      return (
        <div>
          <FormControl
            fullWidth
            sx={{ mt: 2 }}
            error={formSubmitted && dropdownError}
          >
            <InputLabel id={item.title}>
              Please Select {item.title} *
            </InputLabel>
            <Select
              id={item.title}
              required
              error={formSubmitted && dropdownError}
              input={<OutlinedInput label={`Please Select ${item.title}`} />}
              value={selectedName}
              MenuProps={MenuProps}
              onChange={(event) => {
                const itemName = event.target.value;
                setSelectedName(itemName);
                handleChange({ [item.title]: itemName });
                handleChangeID({ [item.title]: item.id });
                setDropdownError(!itemName);
                // Add your logic for handling selectedSubName and selectedSubId here
                if (item.values && item.values.length > 0) {
                  const selectedItem = item.values.find(
                    (value) => value.name === itemName
                  );
                  if (selectedItem) {
                    const fieldType = selectedItem.fieldType;
                    if (fieldType) {
                      setSelectedSubName(fieldType.title);
                      setselectedSubId(fieldType.id);
                    } else {
                      setSelectedSubName(""); // Handle the case where fieldType is null
                      setselectedSubId(""); // Handle the case where fieldType is null
                    }
                  }
                }
              }}
            >
              {data.map((itemName, index) => (
                <MenuItem key={index} value={itemName} id={index + itemName}>
                  {itemName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {option.length > 0 && (
            <FormControl
              fullWidth
              sx={{ mt: 2 }}
              error={formSubmitted && subDropdownError}
            >
              <InputLabel>Please Select {selectedSubName} *</InputLabel>
              <Select
                value={selectedOption}
                error={formSubmitted && subDropdownError}
                input={
                  <OutlinedInput label={`Please Select ${selectedSubName}`} />
                }
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setSelecteOption(selectedValue);
                  handleChange({ [selectedSubName]: selectedValue });
                  handleChangeID({ [selectedSubName]: selectedSubId });
                  setSubDropdownError(!selectedValue);
                }}
                id={selectedSubName}
              >
                {option.map((optionName, index) => (
                  <MenuItem
                    key={index}
                    value={optionName}
                    id={index + optionName}
                  >
                    {optionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      );
    case "text":
      return (
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          id={item.title}
          required
          error={formSubmitted && textError}
          label={`Enter ${item.placeHolder}`}
          variant="outlined"
          type={item.inputType}
          onChange={(event) => {
            const textValue = event.target.value;
            handleChange({ [item.title]: textValue });
            handleChangeID({ [item.title]: item.id });
            setTextError(!textValue);
          }}
          inputProps={{ maxLength: item.maxLength }}
        />
      );
    case "radiobutton":
      return (
        <FormControl
          component="fieldset"
          sx={{ mt: 2 }}
          error={formSubmitted && radioButtonError}
        >
          <FormLabel component="legend">{`Select ${item.title} *`}</FormLabel>
          <RadioGroup
            aria-label={`Select ${item.title}`}
            name={`Select ${item.title}`}
            value={selectedOption}
            onChange={(event) => {
              const selectedData = event.target.value;
              setSelecteOption(selectedData);
              handleChange({ [item.title]: selectedData });
              handleChangeID({ [item.title]: item.id });
              setRadioButtonError(!selectedData || selectedData === ""); // Set error to true if no option is selected
            }}
            id={item.title}
          >
            {item.values.map((item1) => (
              <FormControlLabel
                key={item1.name}
                value={item1.name}
                control={<Radio />}
                label={item1.name}
                id={item1.id}
              />
            ))}
          </RadioGroup>
          {formSubmitted && radioButtonError && (
            <FormHelperText>Please select an option.</FormHelperText>
          )}
        </FormControl>
      );
    default:
      return null;
  }
}
