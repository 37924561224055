import { Swiper, SwiperSlide, breakpoints } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppService from "../services/AppService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import jwtDecode from "jwt-decode";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const swiperBreakpoints = {
  // When window width is >= 320px
  320: {
    slidesPerView: 1,
  },
  // When window width is >= 480px
  480: {
    slidesPerView: 2,
  },
  // When window width is >= 768px
  768: {
    slidesPerView: 4,
  },
  // Add more breakpoints as needed
};

function ProductSlider(prop) {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  // console.log("datad", prop.data);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Use setTimeout to change loading to false after 1000 milliseconds (1 second)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Clear the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timer);
  }, []);

  const data = prop.data;
  const initializeLikes = () => {
    const defaultLikes = {};
    data.forEach((item) => {
      defaultLikes[item.id] = item.liked || false; // Use item.liked if available, or default to false
    });
    return defaultLikes;
  };
  const [likes, setLikes] = useState(initializeLikes());

  const like = (productId) => {
    const newStatus = !likes[productId];
    setLikes((prevLikes) => ({
      ...prevLikes,
      [productId]: newStatus,
    }));

    AppService.productLikeByUserId(productId, newStatus)
      .then((response) => {
        console.log("Like response", response.data);
        if (newStatus) {
          toast.success("Success: Added to your Wishlist", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.success("Success: Removed from your Wishlist", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // }
        console.log(error);
      });
  };

  const navigate = useNavigate();

  // Function to handle like button click for a specific item
  const handleLikeClick = (itemId) => {
    // Check if the user is logged in
    if (token) {
      // Toggle the like status for the item
      setLikes((prevLikes) => ({
        ...prevLikes,
        [itemId]: !prevLikes[itemId], // Toggle the like status for the item ID
      }));
      // Call the like function (passing the item ID)
      like(itemId);
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    // Update likes when data changes
    setLikes(initializeLikes());
  }, [data]);

  return (
    <>
      <section className="swiper-padding">
        <div class="container container-color">
          <div class="row my-5">
            <div class="slider-view-wrap">
              <div class="text-slide">
                <h2>{prop.heading}</h2>
              </div>
              <div class="text-slide-v">
                {prop.view == false ? (
                  ""
                ) : (
                  <Link
                    to={"/product/items"}
                    state={{
                      search: prop.heading,
                      view: true,
                    }}
                  >
                    View All
                  </Link>
                )}
              </div>
            </div>
            <div class="col">
              <Swiper
                spaceBetween={50}
                slidesPerView={3}
                navigation={true}
                breakpoints={swiperBreakpoints}
                modules={[Keyboard, Navigation]}
                className="mySwiper"
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {data.map((item, i) => (
                  <SwiperSlide>
                    {loading ? (
                      <Skeleton
                        count={1}
                        wrapper={InlineWrapperWithMargin}
                        inline
                        width={400}
                        height={275}
                      />
                    ) : (
                      <div class="fd-x-wraper">
                        {item.featured ? (
                          <div class="featured-s">
                            <h3>Featured </h3>
                          </div>
                        ) : null}
                        <div class="heart-cx-icon">
                          <button
                            className="heartBtn"
                            onClick={() => handleLikeClick(item.id)}
                          >
                            <i
                              class="fa fa-heart"
                              aria-hidden="true"
                              id="button"
                              style={{
                                color: likes[item.id] ? "#f00" : "#ddd",
                              }}
                            ></i>
                          </button>
                        </div>
                        <Link to={"/productdetail"} state={{ data: data[i] }}>
                          <div
                            className={`s-img ${item.sold ? "proImg" : null}`}
                          >
                            <img
                              src={item.productImages[0].imageUrl}
                              alt={item.name}
                            />
                          </div>
                          <div class="cover-wh">
                            <div class="cx-h-text">
                              <h3>
                                {loading ? (
                                  <Skeleton width={250} />
                                ) : item?.price === "$0.00" ? (
                                  <div>
                                    {"$"}
                                    {item?.attribute?.map((item, index) => {
                                      if (
                                        item.type === "Salary From" ||
                                        item.type === "Salary To"
                                      ) {
                                        return (
                                          <span key={index}>
                                            {item.name}
                                            {item.type === "Salary From"
                                              ? "-"
                                              : null}
                                          </span>
                                        );
                                      }
                                      return null; // Return null for other items in the attribute array
                                    })}
                                  </div>
                                ) : (
                                  item?.price
                                )}
                              </h3>
                              <p>{item.title}</p>
                            </div>
                            <div class="bottom-covers">
                              <div class="_3rmDx">
                                <div class="_2VQu4">
                                  {item.productAddresses.city}{" "}
                                  {item.productAddresses.state}{" "}
                                  {item.productAddresses.country}
                                </div>
                                <div class="_2jcGx">
                                  <span>
                                    {dayjs(item.createdAt).format("DD/MMM")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductSlider;

function InlineWrapperWithMargin({ children }) {
  return React.createElement(
    "span",
    { style: { marginRight: "1rem " } },
    children
  );
}
