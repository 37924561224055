import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Collapse,
  Container,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import CategoriesService from "../services/CategoriesService";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Categories() {
  const [openIndex, setOpenIndex] = React.useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleListItemClick = (index) => {
    setOpenIndex((prevOpenIndex) => (prevOpenIndex === index ? null : index));
  };

  const isSubListOpen = (index) => {
    return openIndex === index;
  };

  const [data, setData] = React.useState([]);

  const getCategories = () => {
    CategoriesService.getCategories()
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Container component="main" maxWidth="md" sx={{ width: 750 }}>
      <CssBaseline />

      {loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <CircularProgress size={60} /> {/* Customize size as needed */}
          </div>
        </div>
      ) : (
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "15px",
            padding: "10px",
            background: "#fff",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ fontWeight: 500 }}>
            SELECTED CATEGORY
          </Typography>
          <Divider />
          <List component="nav">
            {data.map((category, index) => (
              <div key={category.id}>
                <ListItem
                  button
                  onClick={() => handleListItemClick(index)}
                  sx={{ height: 70 }}
                >
                  {/* Display the image using ListItemIcon */}
                  <ListItemIcon>
                    <img
                      src={category.icon}
                      alt={category.name}
                      style={{ width: 50, height: 50, marginRight: 16 }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={category.name} id={category.name} />
                  {isSubListOpen(index) ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={isSubListOpen(index)}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {category.subCategory.map((subCategory) => (
                      <ListItem
                        button
                        key={subCategory.id}
                        id={subCategory.id}
                        // Use onClick to navigate and pass state
                        onClick={() => {
                          navigate("/postad", {
                            state: {
                              selectedCategory: category,
                              selectedSubCategory: subCategory,
                            },
                          });
                        }}
                      >
                        <ListItemText
                          primary={subCategory.name}
                          style={{ marginLeft: 50 }}
                          id={subCategory.name}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </div>
            ))}
            <Divider />
          </List>
        </Box>
      )}
    </Container>
  );
}
