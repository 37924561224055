import { useEffect, useRef, useState, React } from "react";
import dayjs from "dayjs";
import { Link, useLocation, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { UserContext } from "../root";
import { useContext } from "react";
import PostService from "../services/PostService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Productdata from "./productitem";
import Footer from "../footer/footer";
import CircularProgress from "@mui/material/CircularProgress";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useCallback } from "react";
import authHeader from "../services/auth-header";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuPropsInputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import CategoriesService from "../services/CategoriesService";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

function valuetext(value) {
  return `$${value}`;
}
const minDistance = 10;
const maxValue = 100000000;

function ProductSearch() {
  // let { id } = useParams();
  let param = useParams();
  console.log("useParams", param);

  let { slug } = useParams();
  console.log("useParams", slug);

  const [categoryParamId, setCategoryParamId] = useState({});

  const getCategoriesBySlug = () => {
    CategoriesService.getCategoriesBySlug(slug)
      .then((response) => {
        setCategoryParamId(response.data.id);
        console.log("response.data.id", response.data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategoriesBySlug();
  }, [slug]);

  const [getMetaData, setMetaData] = useState();

  const getCategoryMetaData = () => {
    CategoriesService.getCategoryMeta(categoryParamId)
      .then((response) => {
        setMetaData(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getCategoryMetaData();
  }, []);

  const [resetDynamicFields, setResetDynamicFields] = useState(false);

  // Check if combinedArray is empty
  let dynamicattribute = "";
  const combinedArray = [];

  const [getDynamicFieldList, setDynamicFieldList] = useState([]);
  // Get Dynamic Field list function

  const getDynamicFields = () => {
    PostService.getDynamicFieldList(categoryParamId)
      .then((response) => {
        setDynamicFieldList(response.data.data);
        setDynamic(null);
        setDynamicID(null);
        let data = [];
        response.data.data?.map((item, index) => {
          let temp = {
            [item.title]: "",
          };
          data.push(temp);
        });

        let finalObj = {};
        for (let i = 0; i < data.length; i++) {
          Object.assign(finalObj, data[i]);
        }
        setDynamic(finalObj);
        setDynamicID(finalObj);
        console.log("Dynamic List", response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [dynamicFeild, setDynamic] = useState();
  const [dynamicFeildID, setDynamicID] = useState();

  // Loop through the keys in dynamicFeild
  for (const key in dynamicFeild) {
    const value = dynamicFeild[key];
    if (value !== "") {
      const id = dynamicFeildID[key];
      combinedArray.push({ id, value });
    }
  }
  if (combinedArray.length > 0) {
    dynamicattribute = JSON.stringify(combinedArray);
  }
  console.log("dynamicattribute:", dynamicattribute);

  const {
    countrySate,
    categories,
    categoriesState,
    categoryId,
    categoryNameGlobel,
    searchContaxt,
  } = useContext(UserContext);
  const [setcountryC, setSetCountryC] = countrySate;
  const [stateCategory, setStateCategory] = categories;
  const [mainCategoryId, setMainCategoryId] = categoryId;
  const [allcategorystate, setAllCategorystate] = categoriesState;
  const [mainCategoryName, setMainCategoryName] = categoryNameGlobel;
  const [searchText, setSearchTest] = searchContaxt;

  const [loading, setLoading] = useState(true);

  console.log("categories", categories);
  console.log("mainCategoryId", mainCategoryId);

  const [dataR, setDataR] = useState([]);
  const location = useLocation();
  console.log("location", location);
  // const searchs = location.state.search;
  const searchs = "";
  // const subMenuName = location.state.subcategotyName;
  const subMenuName = "";

  // const [subcategory, setSubcategory] = useState(location.state.subcategory);
  const [allSubcategory, setAllsubcategory] = useState(stateCategory);

  console.log("categories", categories);

  const setSearchsubs = localStorage.getItem("searchsub");
  useEffect(() => {
    debugger;
    setMainCategoryId(mainCategoryId);
    setMainCategoryName(mainCategoryName);
    if (mainCategoryName == "" || mainCategoryName == undefined) {
      setMainCategoryName(subMenuName);
    }

    if (setSearchsubs === "true") {
      // setSubcategory("");
      setDynamic(null);
      setDynamicID(null);
      setDynamicFieldList([]);
      // setMainCategoryName("");
      // setSearchState(false);
      localStorage.setItem("searchsub", "false");
    }
  }, [dynamicattribute, mainCategoryId, setSearchsubs, mainCategoryName]);

  useEffect(() => {
    debugger;
    setAllsubcategory(stateCategory);
  }, [stateCategory, dynamicattribute]);

  useEffect(() => {
    debugger;
    setDynamicFieldList([]);
    //console.log("dynamicattribute",dynamicattribute)
  }, [categoryParamId]);

  const [country, setCountry] = useState([]);
  const [setcountry, setSetCountry] = useState("");
  const [value1, setValue1] = useState([0, maxValue]);
  const [totalItem, setTotalItem] = useState("");
  const [minSliderValue, setMinSliderValue] = useState(0);
  const [maxSliderValue, setMaxSliderValue] = useState(maxValue);
  const [minTextFieldValue, setMinTextFieldValue] = useState(0);
  const [maxTextFieldValue, setMaxTextFieldValue] = useState(maxValue);
  const [categoryMenu, setCategoryMenu] = useState({});
  const [categoryName, setCategoryName] = useState({});

  const [mainCategoryMenu, setmainCategoryMenu] = useState("");

  // // const searchs = location.state.search;
  // const view = location.state.view;
  // // const view = false;
  console.log("categoryMenu", categoryMenu);

  function search(minPrice, maxPrice) {
    debugger;
    setLoading(true);
    console.log("dynamicattributeSearch", dynamicattribute);
    const requestData = {
      name: searchText,
      minprice: minPrice,
      maxprice: maxPrice,
      categoryid: categoryParamId,
      location: setcountry,
      page: 0,
      size: 100,
      country: setcountryC[0],
      dynamicattribute: dynamicattribute,
    };
    fetch("https://api.caribbargains.com/product/post/all/category/web", {
      method: "POST", // Use POST method for sending JSON data
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data && result.data.products) {
          setDataR(result.data.products);
          console.log("SearchData", result.data);
          setTotalItem(result.data.totalItems);
          setCategoryMenu(result.data.categories.subCategory);
          setmainCategoryMenu(result.data.categories);
          setCategoryName(result.data.appliedCategory);

          setLoading(false);
        } else {
          console.error("Data not available or is in an unexpected format");
          setDataR([]);
          setTotalItem(0);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
        setDataR([]);
        setTotalItem(0);
        setLoading(false);
      });
  }

  // function viewAll() {
  //   debugger;
  //   fetch(
  //     `https://api.caribbargains.com/product/viewall?title=${searchText}&country=${setcountryC[0]}&page=0&size=100`,
  //     {
  //       headers: {
  //         ...authHeader(),
  //       },
  //     }
  //   )
  //     .then((response) => {
  //       if (!response.ok) {
  //         setLoading(false);
  //         throw new Error("Network response was not ok");
  //       }
  //       setLoading(false);
  //       return response.json();
  //     })
  //     .then((result) => {
  //       setDataR(result.data.products);
  //       setTotalItem(result.data.totalItems);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error(
  //         "There has been a problem with your fetch operation:",
  //         error
  //       );
  //       setLoading(false);
  //     });
  // }

  useEffect(() => {
    debugger;
    getDynamicFields();
  }, [categoryParamId]);

  useEffect(() => {
    debugger;

    search();

    // document.documentElement.scrollTop = 0;
  }, [
    setcountry,
    categories,
    setcountryC,
    dynamicattribute,
    mainCategoryId,
    categoryParamId,
    slug,
  ]);
  const scriptAddedRef = useRef(false);

  const getFilterStateByCountryCode = () => {
    PostService.getStateByCountryCode(setcountryC[1])
      .then((response) => {
        setCountry(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        // else {
        //     toast.error("Something went wrong!", {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        // }
        console.log(error);
      });
  };

  const handleChange = useCallback(
    (value) => {
      setDynamic((state) => ({ ...state, ...value }));
      console.log("dynamicFeild", dynamicFeild);
    },
    [dynamicFeild]
  );
  const handleChangeID = useCallback(
    (value) => {
      setDynamicID((state) => ({ ...state, ...value }));
    },
    [dynamicFeildID]
  );

  useEffect(() => {
    debugger;
    //  search()
    getFilterStateByCountryCode();
    setSetCountry("");
    getDynamicFields();
    setDynamicFieldList([]);
  }, [setcountryC]);

  const formatAsCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
    return formattedValue.replace(/\.00$/, "");
  };
  const handleClearAll = () => {
    debugger;
    setDynamic({});
    setDynamicID({});
    setResetDynamicFields(true);
  };
  useEffect(() => {
    if (resetDynamicFields) {
      setResetDynamicFields(false);
    }
  }, [resetDynamicFields]);

  //   const [selectedSubcategoryName, setSelectedSubcategoryName] = useState(subMenuName);
  const handleApplyPrice = () => {
    // Trigger the API call with the selected price range
    search(minSliderValue, maxSliderValue);
  };

  console.log("getDynamicFieldList", getDynamicFieldList[0]?.type);

  return (
    <>
      <Helmet>
        <title>{getMetaData?.name}</title>
        <meta name="description" content={getMetaData?.metaDescription}></meta>
        <meta name="keyword" content={getMetaData?.metaKeyword}></meta>
      </Helmet>
      <section className="privacy-policy mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <>
                <div className="wraping-categories">
                  <div className="uxc-flex">
                    <div className="uc-cx-child">
                      <h3 className="ur_lg">CATEGORIES</h3>
                    </div>
                    <div className="uc-cx-child">
                      <span className="arrow-icon-yt">
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </div>
                  </div>
                  <div className="list-cx-h">
                    {mainCategoryMenu !== "" ? (
                      <span className="gx-dv gx-dv0 main_Catetory">
                        {mainCategoryMenu.name}
                      </span>
                    ) : (
                      ""
                    )}
                    <ul>
                      {categoryMenu.length > 0
                        ? categoryMenu?.map((item) => (
                            <li
                              className={`gx-dv gx-dv0 ${
                                item.id == categoryParamId ? "active" : ""
                              }`}
                            >
                              <Link
                                to={`/${item.slug}`}
                                style={{ backgroundColor: "transparent" }}
                                onClick={(e) => {
                                  console.log("setCategory e", e);
                                  if (
                                    item.id === categoryParamId &&
                                    categoryParamId !== ""
                                  ) {
                                    debugger;
                                    // If the button is clicked again, unselect it
                                    // setSubcategory("");
                                    setDynamic(null);
                                    setDynamicID(null);
                                    setDynamicFieldList([]);
                                    setMainCategoryName("");
                                  } else if (categoryParamId == "") {
                                    debugger;
                                    // setMainCategoryId(item.id);
                                    // setSubcategory("");
                                    setMainCategoryName(item.name); // Set the selected subcategory name

                                    // Otherwise, setSubcategory to the clicked item's id
                                  } else {
                                    debugger;
                                    // setCategorySet("");
                                    // setSubcategory(item.id);
                                    setMainCategoryName(item.name);
                                  }
                                }}
                              >
                                {item.name}
                              </Link>
                            </li>
                          ))
                        : ""}
                    </ul>
                  </div>
                  <div className="bdr-cd-f"></div>
                </div>
                <div className="wraping-categories">
                  <div className="uxc-flex">
                    <div className="uc-cx-child">
                      <h3 className="ur_lg">LOCATIONS</h3>
                    </div>
                    <div className="uc-cx-child">
                      <span className="arrow-icon-yt">
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </div>
                  </div>
                  <div className="list-cx-h">
                    <ul className="scro-fc-d">
                      {country.map((item) => (
                        <li
                          className={`gx-dv gx-dv1 ${
                            item.name === setcountry ? "active" : ""
                          }`}
                        >
                          <button
                            style={{ backgroundColor: "transparent" }}
                            onClick={(e) => {
                              console.log("setCategory e", e);
                              if (item.name === setcountry) {
                                // If the button is clicked again, unselect it
                                setSetCountry("");
                              } else {
                                // Otherwise, setSetCountry to the clicked item's name
                                setSetCountry(item.name);
                              }
                            }}
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="bdr-cd-f"></div>
                </div>

                <div className="wraping-categories">
                  <div className="uxc-flex">
                    <div className="uc-cx-child">
                      <h3 className="ur_lg">CHOOSE A RANGE BELOW</h3>
                    </div>
                    <div className="uc-cx-child">
                      <span className="arrow-icon-yt">
                        <i className="fa fa-angle-down"></i>
                      </span>
                    </div>
                  </div>
                  <p>
                    <b>
                      {formatAsCurrency(minSliderValue)} -{" "}
                      {formatAsCurrency(maxSliderValue)}
                    </b>
                  </p>

                  <Box sx={{ width: "100%" }}>
                    <div className="container-fluid pl-0">
                      <Slider
                        getAriaLabel={() => "Pirce"}
                        // value={value1}
                        value={[minSliderValue, maxSliderValue]}
                        onChange={(event, newValue) => {
                          setMinSliderValue(newValue[0]);
                          setMaxSliderValue(newValue[1]);
                          setMinTextFieldValue(newValue[0]); // Synchronize text field values with slider
                          setMaxTextFieldValue(newValue[1]); // Synchronize text field values with slider
                        }}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        min={0}
                        max={maxValue}
                        //    color='warning'
                        className="slider-color"
                        disableSwap
                        style={{ flex: 1 }} // Take up remaining space in the flex container
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        width: "100%",
                      }}
                    >
                      <TextField
                        className="mr-3 mt-2"
                        label="Min"
                        size="small"
                        value={minTextFieldValue}
                        onChange={(event) => {
                          let newValue = parseInt(event.target.value);
                          // Check if the input is a valid number and within the allowed range
                          if (
                            !isNaN(newValue) &&
                            newValue >= 0 &&
                            newValue <= maxValue
                          ) {
                            // Synchronize slider value with text field
                            setMinSliderValue(newValue);
                            // Swap values if min is greater than max
                            if (newValue > maxSliderValue) {
                              setMaxSliderValue(newValue);
                              setMaxTextFieldValue(newValue);
                            }
                            setMinTextFieldValue(newValue);
                          }
                        }}
                        // ...other props
                      />

                      <TextField
                        className="mr-3 mt-2"
                        label="Max"
                        size="small"
                        value={maxTextFieldValue}
                        onChange={(event) => {
                          let newValue = parseInt(event.target.value);
                          // Check if the input is a valid number and within the allowed range
                          if (
                            !isNaN(newValue) &&
                            newValue >= 0 &&
                            newValue <= maxValue
                          ) {
                            // Synchronize slider value with text field
                            setMaxSliderValue(newValue);
                            // Swap values if max is smaller than min
                            if (newValue < minSliderValue) {
                              setMinSliderValue(newValue);
                              setMinTextFieldValue(newValue);
                            }
                            setMaxTextFieldValue(newValue);
                          }
                        }}
                        // ...other props
                      />
                    </div>

                    <div>
                      <Button
                        className="mt-3 range_btn btn "
                        color="warning"
                        size="small"
                        onClick={handleApplyPrice}
                      >
                        Apply
                      </Button>
                    </div>
                  </Box>
                </div>

                {getDynamicFieldList.length > 0 &&
                  getDynamicFieldList[0]?.type !== "text" && (
                    <div
                      className="wraping-categories p-3"
                      style={{ border: "1px solid #0083ca" }}
                    >
                      {getDynamicFieldList.map((item, index) => (
                        <>
                          <HandleDynamicField
                            key={index}
                            item={item}
                            handleChange={handleChange}
                            handleChangeID={handleChangeID}
                            resetSelectedValues={resetDynamicFields}
                          />
                        </>
                      ))}
                      {getDynamicFieldList.length > 0 && (
                        <div className="clear-all-link">
                          <a
                            className="ur_lg text_end"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="This will clear only Custom Attributes"
                            onClick={handleClearAll}
                          >
                            Clear All
                          </a>
                        </div>
                      )}
                    </div>
                  )}
              </>
            </div>

            {loading ? (
              <div className="loading-spinner-overlay">
                <div className="loading-spinner">
                  <CircularProgress size={60} />
                </div>
              </div>
            ) : (
              <div className="col-lg-9">
                <Breadcrumbs />
                <div className="wraping-categories">
                  <div className="_3eiOr _1xlea">
                    <div className="_1DW26">
                      <div className="_1PnNb">
                        <p className="oJxlA" id="adsResultsIn">
                          Showing {totalItem} ads{" "}
                          {categoryName && (
                            <>
                              in{" "}
                              <h1
                                style={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  fontSize: "14px",
                                }}
                              >
                                {" "}
                                "{categoryName?.name}"{" "}
                              </h1>
                            </>
                          )}{" "}
                          {searchText && (
                            <>
                              for{" "}
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                "{searchText}"{" "}
                              </span>
                            </>
                          )}{" "}
                          in{" "}
                          <h2
                            style={{
                              fontWeight: "bold",
                              display: "inline",
                              fontSize: "14px",
                            }}
                          >
                            "{setcountryC[0]}"
                          </h2>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card-drx-wrap row center-ads">
                    {dataR?.length === 0 ? (
                      <>
                        <img
                          src="/./images/ImageNotFound.png"
                          style={{
                            display: "block",
                            width: "180px",
                            margin: "0 auto",
                          }}
                          alt="Image Not found"
                        />
                        <p
                          className="text-center"
                          style={{ fontWeight: "700", fontSize: "20px" }}
                        >
                          No Post Found
                        </p>
                        <p className="text-center" style={{ fontSize: "20px" }}>
                          No advertisement in this Search Result!
                        </p>
                      </>
                    ) : (
                      dataR?.map((item, i) => (
                        <Productdata allData={dataR} data={item} />
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ProductSearch;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function HandleDynamicField({
  item,
  handleChange,
  handleChangeID,
  resetSelectedValues,
}) {
  const [selectedName, setSelectedName] = useState("");
  const [selectedSubName, setSelectedSubName] = useState("");
  const [selectedSubId, setselectedSubId] = useState("");
  const [selectedOption, setSelecteOption] = useState("");

  // // Define the 'setSelected' and 'select' variables here
  // const [setSelected, select] = useState("");

  // Reset selected values when resetSelectedValues prop changes
  useEffect(() => {
    if (resetSelectedValues) {
      setSelectedName("");
      setSelectedSubName("");
      setselectedSubId("");
      setSelecteOption("");
    }
  }, [resetSelectedValues]);

  let data = [];
  let option = [];

  if (item.type === "dropdown") {
    item.values.forEach((value) => {
      data.push(value.name);
      if (selectedName === value.name) {
        value.options.forEach((optionValue) => {
          option.push(optionValue.name);
        });
      }
    });
  }

  switch (item.type) {
    case "dropdown":
      return (
        <div>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id={item.title} sx={{ fontWeight: "bold" }}>
              Select {item.title}
            </InputLabel>
            <Select
              id={item.title}
              required
              input={<OutlinedInput label={`Select ${item.title}`} />}
              value={selectedName}
              MenuProps={MenuProps}
              onChange={(event) => {
                const itemName = event.target.value;
                setSelectedName(itemName);
                handleChange({ [item.title]: itemName });
                handleChangeID({ [item.title]: item.id });

                if (item.values && item.values.length > 0) {
                  const selectedItem = item.values.find(
                    (value) => value.name === itemName
                  );
                  if (selectedItem) {
                    const fieldType = selectedItem.fieldType;
                    if (fieldType) {
                      setSelectedSubName(fieldType.title);
                      setselectedSubId(fieldType.id);
                    } else {
                      setSelectedSubName("");
                      setselectedSubId("");
                    }
                  }
                }
              }}
              disabled={selectedOption ? true : false} // Disable the first select if selectedOption has a value
            >
              {data.map((itemName, index) => (
                <MenuItem key={index} value={itemName}>
                  {itemName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {option.length > 0 && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Select {selectedSubName}</InputLabel>
              <Select
                value={selectedOption}
                input={<OutlinedInput label={`Select ${selectedSubName}`} />}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  setSelecteOption(selectedValue);
                  handleChange({ [selectedSubName]: selectedValue });
                  handleChangeID({ [selectedSubName]: selectedSubId });
                }}
              >
                {/* Empty Option */}
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {/* Other Options */}
                {option.map((optionName, index) => (
                  <MenuItem key={index} value={optionName}>
                    {optionName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      );
    case "radiobutton":
      return (
        <FormControl component="fieldset" sx={{ mt: 2, width: "100%" }}>
          <FormLabel
            component="legend"
            sx={{
              fontWeight: "700 !important",
              color: "#000 !important",
              fontSize: "16px !important",
              borderBottom: "1px solid #0083ca !important",
              paddingBottom: "10px !important",
              fontFamily: "inherit",
            }}
          >{`Select ${item.title}`}</FormLabel>
          <RadioGroup
            aria-label={`Select ${item.title}`}
            name={`Select ${item.title}`}
            value={selectedOption}
            onChange={(event) => {
              debugger;
              const selectedData = event.target.value;
              setSelecteOption(selectedData);
              handleChange({ [item.title]: selectedData });
              handleChangeID({ [item.title]: item.id });
            }}
          >
            {item.values.map((item1) => (
              <FormControlLabel
                key={item1.id}
                value={item1.name}
                control={<Radio size="small" />}
                label={item1.name}
                className="dynamic_sidebar"
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    default:
      return null;
  }
}
