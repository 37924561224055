import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/top-up.css";
import SubscriptionService from "../services/subscription.services";
import PaymentServices from "../services/payment.services";
import Payment from "../modules/profile/components/Payment";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../footer/footer";

const TopUpPage = () => {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const UserId = decoded.sub;
  const [topupDetails, setTopupDetails] = useState(null);
  const [topupSubscription, setTopupSubscription] = useState(null);
  const [coinsValue, setCoinsValue] = useState("$");
  const [showPaypalBtnTopUp, setShowPaypalBtnTopUp] = useState(false);
  const [showPaypalBtnPasses, setShowPaypalBtnPasses] = useState(false);
  const [isTopup, setIsTopup] = useState(false);

  const getTopupDetailsHandler = async () => {
    try {
      let res = await SubscriptionService.getTopup();
      setTopupDetails(res.data.data.topups);
      setTopupSubscription(res.data.data.subscriptions[0]);
    } catch (error) {}
  };

  const saveOrderHandler = async (paymentData) => {
    let numberAmount;
    let numberOfCoins;
    if (isTopup) {
      const stringValue = coinsValue;
      numberAmount = parseFloat(stringValue.replace("$", ""));
      numberOfCoins = topupDetails?.find((item) => item.cost === numberAmount);
    }
    let payload = {
      UserId,
      nonce: paymentData.id,
      correlation_id: paymentData.purchase_units[0].payments.captures[0].id,
      payment_type: "paypal",
      orderId: paymentData.payer.payer_id,
      description: "Payment for purchasing ads package.",
      type: isTopup ? "coin_purchase" : "subscription_purchase",
      status: true,
      amount: isTopup ? numberAmount : topupSubscription?.price,
      currency_code: "USD",
      remark: "Testing",
      subscriptionId: isTopup ? null : topupSubscription.id,
      coins: isTopup ? numberOfCoins.coins : null,
    };
    try {
      let res = await PaymentServices.createSaveOrder(payload);
      console.log(res.data.message);
      toast.success("Success : " + res.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getTopupDetailsHandler();
  }, []);

  return (
    <>
      <section className="privacy-policy mt-4 mb-2">
        <div className="container">
          <Link to="/my-profile" className="text-white contact_form_submit-2">
            <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
          </Link>
          <div className="row">
            <div className="col-xs-12  col-sm-6 ">
              <h3 className="my-4 text-center">
                {" "}
                <b>ADs Top Up</b>
              </h3>
              <div className="bg-primary px-5 fix_size py-4">
                <div className="text-center text-white py-3 ">
                  <h4>Add Coins to Post Ads</h4>
                </div>
                <div className="input-type">
                  <input
                    type="text"
                    placeholder=" $"
                    className="form-control custom-input-number-2"
                    value={coinsValue}
                  />
                </div>
                <div className="text-center text-white cx_margin">
                  {topupDetails?.map((item) => (
                    <span className="py-3 mx-2 " key={item.id}>
                      <a
                        className="rounded-pill"
                        onClick={() => setCoinsValue("$" + item.cost)}
                      >
                        <button className="py-2 px-3 ">+{item.coins}</button>
                      </a>
                    </span>
                  ))}
                </div>
                <div className="text-center py-3 ">
                  <button
                    //  onClick={()=>{
                    //   const stringValue =coinsValue
                    //   const numberValue = parseFloat(stringValue.replace("$", ""));
                    //   paymentHandler(numberValue,"Coins purchase.")}
                    // }
                    onClick={() => {
                      setShowPaypalBtnTopUp(true);
                      setIsTopup(true);
                    }}
                    type="button"
                    className="btn btn-primary side-use-btn mb-3 mt-1"
                    disabled={coinsValue.length > 1 ? false : true}
                  >
                    Proceed to Pay
                  </button>
                  {showPaypalBtnTopUp && (
                    <Payment
                      isTopup={true}
                      price={coinsValue}
                      handleClose={() => setShowPaypalBtnTopUp(false)}
                      saveOrderHandler={saveOrderHandler}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-12  col-sm-6 ">
              <div className="text-center">
                <div className="" style={{margin:"1.1rem 18px"}}>
                  <h4 className="">
                    <b>Power Passes</b>
                  </h4>
                  <p>Post more Ads  at low cost by purchasing Power Pass</p>
                </div>
              </div>
              <div className="bg-primary fix_size text-center px-5 py-4">
                <div className="row mb-3 mt-2 p-0 ">
                  <div className="col-12">
                    <div className="">
                      <div className="pb-3 pt-3 text-center">
                        <h3>{topupSubscription?.type}</h3>
                      </div>
                    </div>
                  </div>
                
                  <div className="col-xs-12 text-left">
                  <div>
                      <div className="row mt-4">
                        <div className="col-xs-12 col-sm-4 p-0">
                          <div className="box-my box-myboder">
                            <div className="my-points">
                              <div className="pl-2">
                                <p>
                                  Discount <sup>*</sup>
                                </p>
                                <h3>{topupSubscription?.discount}%</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 p-0">
                          <div className="box-my box-myboder  ">
                            <div className="my-points">
                              <div className="pl-2">
                                <p>Validity</p>
                                <h3>{topupSubscription?.validity} Days</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 p-0">
                          <div className="box-my  ">
                            <div className="my-points">
                              <div className="pl-2">
                                <p>Total Ad Post</p>
                                <h3>{topupSubscription?.adsLimit} Ads</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                   
                  </div>
                  <div className="col-12 text-center">
                    <div className="">
                      <div className="py-3">
                        <button
                          type="button"
                          onClick={() => {
                            setShowPaypalBtnPasses(true);
                            setIsTopup(false);
                          }}
                          style={{ color: "white", padding: "12px 28px" }}
                          className="btn btn-primary side-use-btn"
                        >
                          Pay ${topupSubscription?.price}
                        </button>
                      </div>
                    </div>
                    <div className="text-left">
                      {/* <h3>${topupSubscription?.price}</h3> */}
                      {showPaypalBtnPasses && (
                        <div className="mt-2">
                          <Payment
                            isTopup={false}
                            handleClose={() => setShowPaypalBtnPasses(false)}
                            price={topupSubscription?.price}
                            saveOrderHandler={saveOrderHandler}
                          />
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default TopUpPage;
