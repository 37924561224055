import { Container, Link } from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container component="main">
      <div id="contact" class="form">
        <div class="container">
          <div class="row my-4">
            <Link
              component={RouterLink}
              to={"/"}
              className="col-lg-12 text-center"
            >
              <img
                src="images/logo/Asset 9 2.png"
                alt="aboutus"
                className="desk_tops"
              />
            </Link>
            <div class="col-lg-12 ">
              <h2>About Carib Bargains</h2>
              <ul class="list-unstyled li-space-lg">
                <li class="address">
                  Last Updated: Monday 11<sup>th</sup> September 2023
                </li>
              </ul>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper"></div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>
                  Discover the Hidden Treasures of the Caribbean, Right from
                  Your Home!
                </b>
              </p>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                Carib Bargains – your ultimate destination for buying, selling,
                and discovering! It allows you to dive into a vibrant
                marketplace, specially crafted for Caribbean users. Here users
                can find their dream ride, from cars to super bikes, can explore
                top-notch electronics, stylish fashion, home essentials, and
                more. Whether they are buying or selling, our user-friendly
                platform makes it a breeze.
              </p>
            </div>
            <div class="col-lg-12 about_secs">
              <h2>Our Story</h2>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                Carib Bargains is your passport to a world of unique, pre-loved
                finds from the heart of the Caribbean. Founded in 2023, our
                journey began with a shared love for exploring the stories
                behind old treasures. We believe that every item has a history,
                a tale to tell, and a new chapter waiting to be written.
              </p>
            </div>

            <div class="col-lg-12 about_secs">
              <h2>Our Mission</h2>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                Our mission at Carib Bargains is clear: to give old items new
                purpose and to make vintage and second-hand shopping a breeze.
                We're on a quest to:
              </p>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>Preserve Memories:</b>
              </p>

              <p>
                We're passionate about preserving the memories embedded in
                vintage items. Each piece in our marketplace carries a piece of
                history, waiting for someone new to create memories with it.
              </p>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>Reduce Waste:</b>
              </p>

              <p>
                We're committed to sustainability. By buying and selling
                pre-loved items, we're helping to reduce waste and promote
                eco-conscious choices.
              </p>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>Connect Communities:</b>
              </p>

              <p>
                {" "}
                We're here to connect buyers and sellers across the Caribbean.
                Whether you're seeking a unique find or ready to part with a
                cherished item, Carib Bargains is your trusted partner.
              </p>
            </div>

            <div class="col-lg-12 about_secs">
              <h2>Why Carib Bargains?</h2>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>Buyer-Friendly:</b>
              </p>

              <p>
                With a wide array of unique items waiting to be discovered and a
                seamless platform designed for the convenience.
              </p>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>Seller-Friendly:</b>
              </p>

              <p>
                If you're looking to sell your old treasures, we make it easy.
                List your items with ease and reach a global audience of vintage
                enthusiasts.
              </p>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>Wide Reach, Local Charm:</b>
              </p>

              <p>
                We're a global platform with a local touch. While our
                marketplace spans the world, we celebrate the uniqueness of
                Caribbean culture.
              </p>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                Join the community, connect with sellers, and unlock amazing
                deals, where the past meets the present and every item has a
                story to tell.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
