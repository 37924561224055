import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactService from "../services/contactservice";
import Footer from "../footer/footer";

  
  export default function ContactUs() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    const initialState = {
        name:'',
        email:'',
        message:''
    };

    const [user , setUser] = useState(initialState);

    const handleInputChange= (event) => {
        const { name, value } = event.target;
        setUser({ ...user, [name]: value });
      };

      const isValidEmail = (email) => {
        // Email validation regular expression
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      };

    
      const handleContact = async () => {
      
        if (!user.name) {
            toast.error("Please enter your name", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return; // Return early if email is blank
          }
        if (!user.email) {
          toast.error("Please enter your email", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return; // Return early if email is blank
        }
          const isEmailValid = isValidEmail(user.email);
    
        // Display error messages for invalid fields
        if (!isEmailValid) {
          toast.error("Invalid email format", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return;
        }
        if (!user.message) {
            toast.error("Please enter your message", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return; // Return early if email is blank
          }
    
      
    
        ContactService.sendData(user)
          .then((response) => {
            setUser(initialState);
            console.log(response.data.data);
           
            toast.success("Success :Thank you for contacting us. We will be back to you soon.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log(response.data.data);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              // If the response contains a message, display it as the error message
              toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
             
            } 
            // else {
            //   toast.error("Something went wrong!", {
            //     position: toast.POSITION.TOP_RIGHT,
            //   });
            // }
    
            console.log(error);
          });
      };
   
    return (
      <div>
      <Container component="main">
         <section class="contact_us">
        <div class="container">
            <div class="row">
                <div class="col-md-12 offset-md-1">
                    <div class="contact_inner">
                        <div class="row">
                            <div class="col-md-10"><grammarly-extension data-grammarly-shadow-root="true" class="dnXmp" style={{position: "absolute", top:" 0px", left: "0px", PointerEvent: "none"}}></grammarly-extension><grammarly-extension data-grammarly-shadow-root="true" class="dnXmp" style={{position: "absolute", top:" 0px", left: "0px", PointerEvent: "none"}}></grammarly-extension>
                                <div class="contact_form_inner">
                                    <div class="contact_field">
                                        <h3>Contact Us</h3>
                                        <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
                                        <input name="name" type="text" class="form-control form-group" placeholder="Name" value={user.name} onChange={handleInputChange} />
                                        <input name="email" type="text" class="form-control form-group" placeholder="Email" value={user.email} onChange={handleInputChange}  />
                                        <textarea name="message" class="form-control form-group" placeholder="Message" data-last-active-input="" spellcheck="false" value={user.message} onChange={handleInputChange}></textarea>
                                        <button onClick={handleContact} class="contact_form_submit">Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact_info_sec">
                            <h4>Contact Info</h4>
                            {/* <div class="d-flex info_single align-items-center">
                                <i class="fa fa-phone"></i>
                                <span><a href="tel:+91 8009 054294">+(1 869)465-3916</a></span>
                            </div> */}
                            <div class="d-flex info_single align-items-center">
                                <i class="fa fa-envelope"></i>
                                <span><a href="mailto:caribbargainscom@gmail.com">info@caribbargains.com</a></span>
                            </div>
                            <div class="d-flex info_single align-items-center">
                                <i class="fa fa-map-marker" aria-hidden="true"></i>
                                <span><a href="#">Carib Bargains Office
                                     Lee Moore Judicial Complex
                                    East Independence Square Street
                                    Basseterre, St. Kitts &amp; Navis</a></span>
                            </div>
            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="map_sec">
        <div class="container">
            <div class="row center-row">
                <div class="col-md-10 custom-auto ">
                    <div class="map_inner">
                        <h4>Find Us on Google Map</h4>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quo beatae quasi assumenda, expedita aliquam minima tenetur maiores neque incidunt repellat aut voluptas hic dolorem sequi ab porro, quia error.</p> */}
                        <div class="map_bind">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3809.407926395377!2d-62.72407892577582!3d17.2958563054374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c121b8d450b2661%3A0xe6e2421f77ea8afc!2sSir%20Lee%20L.%20Moore%20Judicial%20and%20Legal%20Services%20Complex!5e0!3m2!1sen!2sin!4v1695043379560!5m2!1sen!2sin" width="100%" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
      </Container>
  
        <Footer />
     </div>
    );
  }
  
