import {
    Box,
  } from "@mui/material";
  import React, { useState,useEffect } from "react";
  import clsx from 'clsx';
  import { styled } from '@mui/system';
  import { Modal } from '@mui/base/Modal';
  import jwtDecode from "jwt-decode";
import Payment from "../modules/profile/components/Payment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentServices from "../services/payment.services";
import SubscriptionService from "../services/subscription.services";
import "../styles/top-up.css";
export const ShowTopUpModal=({open,handleClose,getProductSummary,balance})=>{
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const UserId = decoded.sub;
  const [topupDetails, setTopupDetails] = useState(null);
  const [topupSubscription, setTopupSubscription] = useState(null);
  const [coinsValue, setCoinsValue] = useState("$");
  const [showPaypalBtnTopUp, setShowPaypalBtnTopUp] = useState(false);
  const [isTopup, setIsTopup] = useState(false);

  const getTopupDetailsHandler = async () => {
    try {
      let res = await SubscriptionService.getTopup();
      setTopupDetails(res.data.data.topups);
      setTopupSubscription(res.data.data.subscriptions[0]);
    } catch (error) {}
  };

  const saveOrderHandler = async (paymentData) => {
    let numberAmount;
    let numberOfCoins;
    if (isTopup) {
      const stringValue = coinsValue;
      numberAmount = parseFloat(stringValue.replace("$", ""));
      numberOfCoins = topupDetails?.find((item) => item.cost === numberAmount);
    }
    let payload = {
      UserId,
      nonce: paymentData.id,
      correlation_id: paymentData.purchase_units[0].payments.captures[0].id,
      payment_type: "paypal",
      orderId: paymentData.payer.payer_id,
      description: "Payment for purchasing ads package.",
      type: isTopup ? "coin_purchase" : "subscription_purchase",
      status: true,
      amount: isTopup ? numberAmount : topupSubscription?.price,
      currency_code: "USD",
      remark: "Testing",
      subscriptionId: isTopup ? null : topupSubscription.id,
      coins: isTopup ? numberOfCoins.coins : null,
    };
    try {
      let res = await PaymentServices.createSaveOrder(payload);
      getProductSummary()
      toast.success("Success : " + res.data.message + " Now you can pay for ad post.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    getTopupDetailsHandler();
  }, []);

    return(
      <StyledModal
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      open={open}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
      keepMounted
    >
      <Box sx={style}>
        <h4 className="text-center" id="keep-mounted-modal-title">Add Coins to Post Ads</h4>
        <h6 className="text-center">Your current balance is "{balance}".</h6>
        <div className="row" id="keep-mounted-modal-description">
              <div className="col-xs-12">
             
                <div className="bg-primary p-3">
                 
                  <div className="input-type">
                    <input
                      type="text"
                      placeholder=" $"
                      className="form-control custom-input-number-2"
                      value={coinsValue}
                    />
                  </div>
                  <div className="text-center text-white mt-5">
                    {topupDetails?.map((item) => (
                      <span className="py-3 mx-2 " key={item.id}>
                        <a
                          className="rounded-pill"
                          onClick={() => setCoinsValue("$" + item.cost)}
                        >
                          <button className="py-2 px-3 ">+{item.coins}</button>
                        </a>
                      </span>
                    ))}
                  </div>
                  <div className="text-center py-3">
                    <button
                      //  onClick={()=>{
                      //   const stringValue =coinsValue
                      //   const numberValue = parseFloat(stringValue.replace("$", ""));
                      //   paymentHandler(numberValue,"Coins purchase.")}
                      // }
                      onClick={() => {
                        setShowPaypalBtnTopUp(true);
                        setIsTopup(true);
                      }}
                      type="button"
                      className="btn btn-primary side-use-btn mb-3"
                      disabled={coinsValue.length > 1 ? false : true}
                    >
                      Proceed to Pay
                    </button>
                    {showPaypalBtnTopUp && (
                      <Payment
                        isAdPost={true}
                        isTopup={true}
                        price={coinsValue}
                        handleClose={handleClose}
                        saveOrderHandler={saveOrderHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
             
            </div>
      </Box>
    </StyledModal>
    )
  }
  
  const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
      <div
        className={clsx({ 'MuiBackdrop-open': open }, className)}
        ref={ref}
        {...other}
      />
    );
  });
  
  
  const blue = {
    200: '#99CCF3',
    400: '#3399FF',
    500: '#007FFF',
  };
  
  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };
  
  const StyledModal = styled(Modal)(`
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.MuiModal-hidden {
      visibility: hidden;
    }
  `);
  
  const StyledBackdrop = styled(Backdrop)`
    z-index: -1;
    position: fixed;
    inset: 0;
    background-color: rgb(0 0 0 / 0.5);
    -webkit-tap-highlight-color: transparent;
  `;
  
  const style = (theme) => ({
    width: 400,
    borderRadius: '12px',
    padding: '16px 15px 24px 15px',
    backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
    boxShadow: `0px 2px 24px ${theme.palette.mode === 'dark' ? '#000' : '#383838'}`,
  });
  
  const TriggerButton = styled('button')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 600;
    box-sizing: border-box;
    min-height: calc(1.5em + 22px);
    border-radius: 12px;
    padding: 6px 12px;
    line-height: 1.5;
    background: transparent;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[100] : grey[900]};
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
    }
  
    &:focus-visible {
      border-color: ${blue[400]};
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
    `,
  );