import { Outlet, useLoaderData } from "react-router-dom";
import Header from "./header/header";
import { createContext } from "react";
import { useState } from "react";
import { Box, Fab, Fade, Toolbar, useScrollTrigger } from "@mui/material";
import PropTypes from "prop-types";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import { MessageProvider } from "./MessageContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppService from "./services/AppService";

export const UserContext = createContext();

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 999, // Set a high z-index value
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
function Root(props) {
  const currentCountry = useLoaderData();
  // const getCountryList = useLoaderData();
  console.log("currentCountry", currentCountry);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Add event listeners to check internet connection status
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Clean up event listeners when component unmounts
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []); // Empty dependency array ensures that effect runs once after initial render

  // Show toast message if there is no internet connection
  useEffect(() => {
    if (!isOnline) {
      toast.error("No internet connection!");
    }
  }, [isOnline]);

  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const getCountryList = () => {
      AppService.getCountryList()
        .then((response) => {
          setCountryList(response.data.data.country);
          console.log("response.data.data.country", response.data.data.country);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(`${error.response.data.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            // Handle other errors if needed
          }
          console.error(error);
        });
    };

    // Fetch country list only if it hasn't been fetched before
    if (countryList.length === 0) {
      getCountryList();
    }
  }, [countryList]);

  const checkIfValidCountry = (countryData, countryList) => {
    debugger;
    // Check if the country list is available before using it
    if (countryList?.length === 0) {
      // Country list is not available yet, handle accordingly
      console.log("Country list is not available yet");
      return false;
    }

    console.log("countryData", countryData);
    console.log("countryData.country_name", countryData.country_name);
    console.log("countryData.country_code", countryData.country_code);
    console.log("countryData", countryList);
    return (
      countryData &&
      countryList?.some(
        (country) =>
          countryData.country_name == country?.name &&
          countryData.country_code == country?.cca2
      )
    );
  };

  const [setcountryC, setSetCountryC] = useState(() => {
    debugger;
    // Check if currentCountry values match your country list
    const isValidCountry = checkIfValidCountry(currentCountry, countryList);

    // If not valid, set default values
    if (!isValidCountry) {
      return ["Saint Kitts And Nevis", "KN"];
    }

    // Otherwise, use the values from currentCountry
    return [currentCountry.country_name, currentCountry.country_code];
  });

  // const [setcountryC, setSetCountryC] = useState(["India", "IN"]);
  const [stateCategory, setStateCategory] = useState();
  const [mainCategoryId, setMainCategoryId] = useState();
  const [categotyName, setCategotyName] = useState();
  const [allcategorystate, setAllCategorystate] = useState(true);
  const [search, setSearch] = useState("");

  // Retrieve selected country from local storage when the component mounts
  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountryC");
    if (storedCountry) {
      const parsedCountry = JSON.parse(storedCountry);
      console.log(parsedCountry);
      setSetCountryC(parsedCountry);
    }
  }, []);

  return (
    <>
      <MessageProvider>
        <UserContext.Provider
          value={{
            countrySate: [setcountryC, setSetCountryC],
            categories: [stateCategory, setStateCategory],
            categoriesState: [allcategorystate, setAllCategorystate],
            categoryId: [mainCategoryId, setMainCategoryId],
            categoryNameGlobel: [categotyName, setCategotyName],
            searchContaxt: [search, setSearch],
          }}
        >
          <Header />
          {/* < ChatNotificationService/> */}
          <div>
            <div
              id="back-to-top-anchor"
              style={{ marginTop: "1px !important" }}
            />
            <Outlet />
            <ScrollTop {...props}>
              <Fab color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon />
              </Fab>
            </ScrollTop>
          </div>
        </UserContext.Provider>
      </MessageProvider>
    </>
  );
}

export default Root;

Root.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
