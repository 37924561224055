import http from "../services/http-common";

const sendData = (data) => {
  console.log(data);
  return http.post("helpsupport/contactus", data);
};

const ContactService = {
    sendData,
};

export default ContactService;
