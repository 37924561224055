import React, { useState, useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import ChatServices from "../services/chat.services";
import { getCurrentTimeWithAMPM } from "../helpers/DateTimeFormatter";
import { useLocation } from "react-router";
import { CHAT_API_BASE_URL } from '../../src/config';
import Footer from "../footer/footer";
var stompClient = null;

const SellerChatPage = () => {
  const location = useLocation();
  const sellerResponse = location.state?.data || {}; // Default to an empty object

  const [messages, setMessages] = useState([]);

  const [textMessage, setTextMessage] = useState("");
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const UserId = decoded.sub;
  const userName = decoded.name;
  const imageUrl = decoded.image_url||"./img/chat.png";
  const containerRef = useRef(null);

  const connect = () => {
    const Stomp = require("stompjs");
    var SockJS = require("sockjs-client");
    SockJS = new SockJS(CHAT_API_BASE_URL);
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError);
  };

  const onError = (error) => {
    console.log("Error:", error);
  };

  const onConnected = () => {
    stompClient.subscribe(
      "/user/" + UserId + "/queue/messages",
      onMessageReceived
    );
  };

  const onMessageReceived = (msg) => {
    const message = JSON.parse(msg.body);

    if (message.senderId != UserId) {
      let isMsg = messages.find((item) => item.id !== message.id);
      if (isMsg) {
        return;
      } else {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    }
  };

  const scrollToBottom = () => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  const sendMessage = () => {
    if (textMessage.trim() !== "" && stompClient) {
      const chatMessage = {
        senderId: Number(UserId),
        senderName: userName,
        recipientId: sellerResponse.id,
        recipientName: sellerResponse.name,
        content: textMessage,
        timestamp: new Date(),
      };
      stompClient.send("/app/chat", {}, JSON.stringify(chatMessage));
      setMessages([...messages, chatMessage]);
      setTextMessage("");
      setTimeout(() => {
        scrollToBottom()
      }, 1300);
    }
  };


  const openMessagesHandler = async () => {
    let senderId = Number(UserId);
    let recipientId = Number(sellerResponse.id);
    try {
      let res = await ChatServices.getMessages(senderId, recipientId);
      setMessages(res.data);
    } catch (error) {
      return error;
    }
  };



  useEffect(() => {
    connect();
    openMessagesHandler();
  }, []);

  return (
    <>
      {/* <!-- -------------Chat-by-user-admin-start------ --> */}

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item tabs-design" role="presentation">
                <button
                  className="nav-link  "
                  id="messages-tab"
                  data-toggle="tab"
                  data-target="#messages"
                  type="button"
                  role="tab"
                  aria-controls="messages"
                  aria-selected="false"
                >
                  Selling
                </button>
              </li>
            </ul>

            {/* <!-- Tab panes --> */}
            <div
              className="tab-pane "
              id="messages"
              role="tabpanel"
              aria-labelledby="messages-tab"
            >
              <div
                className="row"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-lg-6 my-3">
                  <div className="seller-chat-box">
                    <div className="chat-show-box">
                      <div className="user-name">
                        <div
                          className="msg-img"
                          style={{ backgroundImage:`url(${sellerResponse.imageUrl|| "./img/chat.png"})` }}
                        ></div>
                        <h3 className="main-user-ct">{sellerResponse?.name}</h3>
                      </div>
                      <div className="user-detail">
                        <a href={`tel:${sellerResponse?.mobile}`}>
                          <i className="fa fa-phone"></i>
                        </a>
                        <a href={`mailto:${sellerResponse?.email}`}>
                          <i className="fa fa-envelope"></i>
                        </a>
                      </div>
                    </div>
                    <div className="chat-message"  ref={containerRef}>
                      {messages?.map((item, index) => (
                        <div
                          key={index}
                          className={`msg ${
                            item.senderId === Number(UserId)
                              ? "right-msg"
                              : "left-msg"
                          } mt-3`}
                        >
                          <div
                            className="msg-img"
                            style={{ backgroundImage:item.senderId === Number(UserId)?`url(${imageUrl})`:`url(${sellerResponse.imageUrl})`}}
                          ></div>

                          <div className="msg-bubble">
                            <div className="msg-info">
                              <div className="msg-info-time">
                                {getCurrentTimeWithAMPM(item.timestamp)}
                              </div>
                            </div>

                            <div className="msg-text">{item.content}</div>
                          </div>
                        </div>
                      ))}
                      <button className="msger-inputarea">
                        <input
                          type="text"
                          className="msger-input"
                          placeholder="Enter your message..."
                          data-last-active-input=""
                          value={textMessage}
                          onChange={(e) => setTextMessage(e.target.value)}
                        />
                        <button
                          onClick={sendMessage}
                          type="button"
                          className="msger-send-btn"
                        >
                          Send
                        </button>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SellerChatPage;
