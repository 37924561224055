import http from "./http-common";
import authHeader from "./auth-header";

const createPayment = (data) =>
  http.post("/pay", data, {
    headers: authHeader(),
  });

const createSaveOrder = (data) =>
  http.post("api/save/order/new", data, {
    headers: authHeader(),
  });

const PaymentServices = {
  createPayment,
  createSaveOrder,
};

export default PaymentServices;
