import { Button, Link } from "@mui/material";
import jwtDecode from "jwt-decode";
import { Link as RouterLink } from "react-router-dom";
import AppService from "../services/AppService";
import { useEffect, useState } from "react";

function ReferEarn() {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;

  const [data, setData] = useState({});

  const getReferAndEarn = () => {
    AppService.getReferAndEarn()
      .then((respone) => {
        setData(respone.data.referandearn);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getReferAndEarn();
  }, []);
  return (
    <>
      <section className="container-web-sedo">
        <div className="container-fluid">
          {decoded ? (
            <div className="row">
              <div className="col-5 col-md-4 col-sm-12">
                <div className="regis-wrapers">
                  <div className="earn-up-box">
                    <img
                      src="images/All Icons/Frame-8.png"
                      alt="Refer & Earn"
                    />
                  </div>
                  <div className="text-plan">
                    <h2> Refer & Earns</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-sm-12 ">
                <div className="regis-text">
                  <h4>
                    {" "}
                    <samp className="className-balck ">
                      Refer Now and earn Upto{" "}
                      <span className="className-yellow">
                        {data?.referCoins} Coins Worth ${data?.referAmount}
                      </span>{" "}
                      to your Carib Bargains account.
                    </samp>
                  </h4>
                </div>
              </div>
              <div className="col-lg-2 col-md-3  col-sm-12 text-center">
                <div className="regis-text"></div>

                <Button
                  color="warning"
                  variant="contained"
                  component={RouterLink}
                  to={`/my-profile#referearn`}
                  style={{ color: "white" }}
                >
                  Refer Now
                </Button>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-5 col-md-4 col-sm-12">
                <div className="regis-wrapers">
                  <div className="earn-up-box">
                    <img
                      src="images/All Icons/Frame-8.png"
                      alt="Refer & Erans"
                    />
                  </div>
                  <div className="text-plan">
                    <h2> Register & Earns</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-5 col-sm-12 ">
                <div className="regis-text">
                  <h4>
                    {" "}
                    <samp className="className-balck ">
                      Register with us and earn{" "}
                      <span className="className-yellow">
                        {data?.welcomeCoins} Coins Worth ${data?.welcomeAmount}
                      </span>{" "}
                      to your Carib Bargains account.
                    </samp>
                  </h4>
                </div>
              </div>
              <div className="col-lg-2 col-md-3  col-sm-12 text-center">
                <div className="regis-text"></div>

                <Button
                  color="warning"
                  variant="contained"
                  component={RouterLink}
                  to={`signup`}
                  style={{ color: "white" }}
                >
                  Register Now
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ReferEarn;
