import axios from "axios";

// export default axios.create({
//   baseURL: "http://localhost:8080/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

//Server api access
export default axios.create({
  baseURL: "https://api.caribbargains.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

//  Testing Server access
// export default axios.create({
//   baseURL: "http://62.171.153.83:8080/carib-bargains-api-new/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
