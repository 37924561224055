import { Link } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Settings from "../../../services/settings";
import jwtDecode from "jwt-decode";
import PostService from "../../../services/PostService";
import Switch from "@mui/material/Switch";

const MySettings = () => {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  console.log("decoded", decoded);

  const UserId = decoded.sub;

  const [phone, setPhone] = useState();
  const [user, setUsers] = useState([]);

  useEffect(() => {
    const getUser = () => {
      PostService.getUser(UserId)
        .then((response) => {
          setPhone(response.data.data.hideMobile);
          setUsers(response.data.data);
          console.log("User data MySettings", response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    getUser();
  }, [UserId]);

  console.log("user", user);

  // Function to make the API call based on the 'phone' state
  const updatePhoneVisibility = () => {
    const requestData = {
      hideMobile: !phone, // Invert the checked state to 'hideMobile' value
      userId: UserId,
    };

    Settings.hideUserPhoneNo(requestData)
      .then((response) => {
        toast.success("Success: Phone Visibility Updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } 
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // }
        console.log(error);
      });
  };

  const handleInputChange = (event) => {
    const { checked } = event.target;
    setPhone(checked);
    updatePhoneVisibility(checked);
  };

  return (
    <div class="" id="settings-v">
      <h3>My Setting</h3>

      <div class="container-fluid mt-3">
        <div class="row">
          <div class="col-lg-12 p-0">
            <div class="mxt-pri">
              <div class="contains-box">
                <h4>Privacy Setting</h4>
                <div class="txt-mxt">
                  <div>
                    <p>Show My Phone number in ads</p>
                  </div>
                  <Switch
                      color="warning"
                      checked={phone}
                      onChange={handleInputChange}
                    />
            </div>
              </div>
              <hr />
              <div class="contains-box">
                <h4>Change Password</h4>
                <div class="txt-mxt">
                  <div>
                    <p>If you want to change your password</p>
                  </div>
                  <div>
                    <div class="verify-bt mr-0 mt-0">
                      <Link
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#change-password"
                        component={RouterLink}
                        to={"/changepassword"}
                      >
                        Click here
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MySettings;
