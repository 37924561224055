import React, { createContext, useState, useContext } from 'react';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [newMessageCount, setNewMessageCount] = useState(0);

  const incrementNewMessageCount = (msgCount) => {
    console.log("Incrementing message count");
    setNewMessageCount(msgCount);
  };

  const resetNewMessageCount = () => {
    setNewMessageCount(0);
  };

  console.log("newMessageCount",newMessageCount);

  return (
    <MessageContext.Provider
      value={{ newMessageCount, incrementNewMessageCount, resetNewMessageCount }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageCount = () => {
  return useContext(MessageContext);
};
