import React, { useEffect, useState } from "react";
import authHeader from "../../../services/auth-header";
import jwtDecode from "jwt-decode";
import Settings from "../../../services/settings";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import { auth } from "../../auth/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import {
  Link,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const Profile = () => {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const location = useLocation();
  const isWalletScreen = location.state?.walletScreen || false; // Default to an empty object
  const isSubscriptionScreen = location.state?.subscriptionScreen || false;
  const isChatScreen =
    location.state?.isChatScreen ||
    window.location.href.includes("chat") ||
    false;
  const isReferEran = window.location.href.includes("referearn") || false; // Default to an empty object

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [formatValue, setFormatValue] = useState(code);

  const [prevPhone, setPrevPhone] = useState("");
  const [prevPhoneCode, setPrevPhoneCode] = useState("");
  const [picture, setPicture] = useState("../images/profile-picture.webp");

  const getProfile = () => {
    Settings.getProfile(decoded.sub)
      .then((response) => {
        setName(response.data.data.name);
        setEmail(response.data.data.email);
        if (response.data.data.mobile === "") {
          setIsVerified(false);
        }
        setPhone(response.data.data.mobile);
        setPrevPhone(response.data.data.mobile);
        setPrevPhoneCode(response.data.data.code);
        setCode(response.data.data.code);
        if (response.data.data.imageUrl === "") {
          setPicture("../images/profile-picture.webp");
        } else {
          setPicture(response.data.data.imageUrl);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // //   setLoading(false);
        // }
        setLoading(false);
        console.log(error);
      });
  };

  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const data = response.data;

        console.log("data", data);

        // Set default value for the code field based on the API response
        const defaultCode = data.country_code; // Replace with your logic
        setCountryCode(defaultCode);
      } catch (error) {
        console.error("Error fetching IP API data:", error);
        // Handle errors as needed
      }
    };

    fetchData(); // Call the function inside useEffect
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const phoneNumberWithCode = `${code}${phone}`;
  const previousNumber = `${prevPhoneCode}${prevPhone}`;

  useEffect(() => {
    console.log("prevPhone", previousNumber, "Phone", phoneNumberWithCode);

    if (previousNumber === phoneNumberWithCode) {
      setIsVerified(true);
    }
  }, [previousNumber, phoneNumberWithCode]);

  const updateProfileImage = (event) => {
    debugger;
    const picture = event.target.files[0]; // Get the selected file
    setPicture(picture);
    Settings.updateProfileImg(picture, decoded.sub)
      .then((response) => {
        console.log(response.data.data);
        getProfile();
        window.location.reload();
        toast.success("Profile Image Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // //   setLoading(false);
        // }
        setLoading(false);
        console.log(error);
      });
  };

  const formattedCountryCode = code.startsWith("+") ? code : `+${code}`;

  var updateProfileData = {
    id: decoded.sub,
    name: name,
    email: email,
    mobile: phone,
    code: formattedCountryCode,
  };

  console.log(formatValue);

  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  // const [phone, setPhone] = useState(initialUserService.mobile);
  const [isVerified, setIsVerified] = useState(true);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const handleSend = (event) => {
    debugger;
    event.preventDefault();
    if (!phone) {
      toast.error("Please verify your mobile number!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setHasFilled(true);
    // setOtpDialogOpen(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+${code}${phone}`, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpDialogOpen(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        appVerifier = null;
        window.location.reload();
        console.log(error);
      });
  };

  const handleResendOtp = (event) => {
    debugger;
    event.preventDefault();
    setTimer(60); // Reset timer to 60 seconds
    setTimerReachedZero(false); // Reset timer reached zero flag
    if (!phone) {
      toast.error("Please verify your mobile number!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setHasFilled(true);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, `+${code}${phone}`, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpDialogOpen(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        appVerifier = null;
        window.location.reload();
        console.log(error);
      });
  };

  const verifyOtp = (event) => {
    debugger;
    // let otp = event.target.value;
    setOtp(otp);
    if (!otp) {
      toast.error("Please enter your Otp", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (otp.length > 6) {
      toast.error("Otp must be 6 number only", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (otp.length < 6) {
      toast.error("Otp must be 6 number only", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          let user = result.user;
          // console.log(user);
          console.log("User signed in successfully");
          // Close the confirmationResult window
          window.confirmationResult = null;

          // Reset the OTP dialog and data

          setOtpDialogOpen(false);
          setHasFilled(false);
          setIsVerified(true);
          toast.success("Success : OTP verified Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          toast.error("Invalid Otp!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.confirmationResult = null;
          // alert('User couldn\'t sign in (bad verification code?)');
        });
    }
  };

  const handleCloseOtpDialog = () => {
    window.confirmationResult = null;
    setOtpDialogOpen(false);
    setHasFilled(false);
  };

  //const phonevalue = code.split("+")[1];
  // const phonevalue = code.indexOf("+") == 0 ? `+${code}` : `+${code}`;
  // console.log("phonevalue", phonevalue);
  const updateProfile = () => {
    debugger;

    if (!name) {
      toast.error("Please enter your name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if email is blank
    }
    if (!email) {
      toast.error("Please enter your email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if email is blank
    }
    if (!phone) {
      toast.error("Please enter your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if email is blank
    }

    if (!isVerified) {
      toast.error("Please verify your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // const phoneCode = `${phonevalue}`; // Combine country code with "+"
    // const phoneCode = `+${updateProfileData.code.replace(/^\+/, "")}`;

    Settings.updateProfile(updateProfileData)
      .then((response) => {
        console.log(response.data.data);
        toast.success("Success: Profile Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          //   setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // //   setLoading(false);
        // }

        console.log(error);
      });
  };

  useEffect(() => {
    getProfile();
    console.log("useEffect");
  }, []);

  const [timer, setTimer] = useState(60);
  const [timerReachedZero, setTimerReachedZero] = useState(false);

  useEffect(() => {
    let interval;
    if (otpDialogOpen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerReachedZero(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [otpDialogOpen, timer]);

  if (!hasFilled) {
    return (
      <div
        className={`tab-pane ${
          isWalletScreen || isChatScreen || isReferEran || isSubscriptionScreen
            ? ""
            : "active"
        } app__container1`}
        id="home-v"
      >
        {loading ? (
          <div className="loading-spinner-overlay">
            <div className="loading-spinner">
              <CircularProgress size={60} /> {/* Customize size as needed */}
            </div>
          </div>
        ) : (
          <>
            <div>
              <h3>My Profile</h3>
              <div className="contact_form_inner">
                <div className="contact_field">
                  <div
                    className="text-center text-primary"
                    style={{ fontSize: "50px" }}
                  >
                    <div class="avatar-upload">
                      <div class="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => updateProfileImage(e)}
                        />
                        <label for="imageUpload"></label>
                      </div>
                      <div class="avatar-preview">
                        <div
                          id="imagePreview"
                          style={{ backgroundImage: `url(${picture})` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center pt-4"></div>
                  <input
                    type="text"
                    className="form-control form-group"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => {
                      // Check if the input length is within the limit before updating the state
                      if (e.target.value.length <= 45) {
                        setName(e.target.value);
                      }
                    }}
                    maxLength={45} // Set the maximum length to 35 characters
                  />{" "}
                  <input
                    type="text"
                    className="form-control form-group"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={true}
                  />
                  {/* <input type="number" className="form-control form-group " placeholder="+91" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
                  <div style={{ display: "flex" }}>
                    <PhoneInput
                      country={countryCode}
                      name="phone"
                      value={code + phone}
                      onChange={(value, formattedValue) => {
                        console.log("phoneNumberWithoutCode", value);
                        const phoneNumberWithoutCode = value.replace(
                          formattedValue.dialCode,
                          ""
                        );

                        setPhone(phoneNumberWithoutCode);
                        setCode(formattedValue.dialCode);
                        setIsVerified(false);
                      }}
                    />
                    {isVerified ? (
                      <CheckCircleIcon
                        style={{ color: "green" }}
                        sx={{ mt: 1 }}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSend}
                      >
                        Verify
                      </Button>
                    )}
                  </div>
                  {/* <div className="verify-btn-p"><button
                className="contact_form_submit-2 verify-btn">Verify </button></div> */}
                  <div className="row">
                    <div className="col-lg-6">
                      <button
                        className="contact_form_submit"
                        onClick={updateProfile}
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-lg-6 text-right align-text-bottom">
                      <div className=" pt-5 text-primary"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="recaptcha"></div>
          </>
        )}
      </div>
    );
  } else if (hasFilled) {
    return (
      <div className="app__container">
        <Dialog
          open={otpDialogOpen}
          onClose={handleCloseOtpDialog}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogContent>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseOtpDialog}
              aria-label="close"
              sx={{ position: "absolute", top: 5, right: 12 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ mt: "10px", mb: "10px" }}
              variant="h5"
              component="div"
            >
              Enter the OTP
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              label="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            {timerReachedZero ? (
              <div style={{ marginTop: 10 }}>
                <span variant="body2">If you didn't received a code? </span>
                <Link
                  variant="outlined"
                  color="primary"
                  onClick={handleResendOtp}
                  sx={{ mt: 2, cursor: "pointer" }}
                  size="small"
                >
                  Resend OTP
                </Link>
              </div>
            ) : (
              <div style={{ marginTop: "10px" }}>
                Time left:{" "}
                {Math.floor(timer / 60).toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                })}
                :
                {(timer % 60).toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                })}
              </div>
            )}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="warning"
              onClick={verifyOtp}
              loading={loading}
              loadingPosition="start"
              startIcon={loading ? <CircularProgress size={20} /> : null}
              sx={{ mt: 2 }}
            >
              Verify Code
            </LoadingButton>
          </DialogContent>
        </Dialog>
        <div id="recaptcha"></div>
      </div>
    );
  }
};
export default Profile;
