import http from "../services/http-common";

const getCountries = () => http.get(`/api/location/country`);

const getStateByCountryId = (id) => http.get(`/api/location/state/country/${id}`);

const getCityByStateId = (id) => http.get(`/api/location/city/${id}`);

const LocationService = {
    getCountries,
    getStateByCountryId,
    getCityByStateId
};

export default LocationService;
