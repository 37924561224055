import http from "../services/http-common";
import authHeader from "./auth-header";

//   const hideUserPhoneNo = (data) => {
//     debugger;
//     console.log(data);
//     return http.post(`/user/showmobile`, data);
//   };

const getRecommendations = (countryname) => {
  return http.get(`/product/getrecommendations/${countryname}`, {
    headers: authHeader(),
  });
};

const getMenu = () => {
  return http.get(`/category/subcategory`, {
    headers: authHeader(),
  });
};

const getCountryList = () => {
  return http.get(`/api/location/mycountrylist`);
};

const productLikeByUserId = (productId, status) => {
  return http.get(`/product/like?productId=${productId}&status=${status}`, {
    headers: authHeader(),
  });
};
const postIDProduct = (productId) => {
  return http.get(`/product/post/${productId}`, {
    headers: authHeader(),
  });
};

const getReferAndEarn = () => {
  return http.get(`/site-nav/getall`);
};
const AppService = {
  getRecommendations,
  getMenu,
  getCountryList,
  productLikeByUserId,
  postIDProduct,
  getReferAndEarn,
};

export default AppService;
