import http from "../services/http-common";
import authHeader from "./auth-header";

const create = (data) => {
  console.log(data);
  return http.post("auth/signup", data);
};

const login = (data) => {
  return http.post("auth/login", data);
};

const chnagePassword = (data) => {
  return http.post("user/changepassword", data,
  {
    headers: authHeader(), // Call the authHeader function to get the headers
  });
};

const forgotPassword = (data) => {
  return http.post("user/forgotpassword", data,
  {
    headers: authHeader(), // Call the authHeader function to get the headers
  });
};

const verfiyOtp = (data) => { debugger
  return http.post("user/verify", data,
  {
    headers: authHeader(), // Call the authHeader function to get the headers
  });
};

const resetPassword = (data) => { debugger
  return http.post("user/resetpassword", data,
  {
    headers: authHeader(), // Call the authHeader function to get the headers
  });
};

const logout = () => {
  localStorage.removeItem("user");
};


const AuthService = {
  create,
  login,
  chnagePassword,
  forgotPassword,
  verfiyOtp,
  resetPassword,
  logout
};

export default AuthService;
