// import React, { useEffect } from 'react';
// import { ACCESS_TOKEN } from '../../constant';
// import { useLocation, useNavigate } from 'react-router-dom';

// const OAuth2RedirectHandler = () => {
//     const navigate = useNavigate();
//     const location = useLocation();

//     const getUrlParameter = (name) => {
//         name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
//         const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
//         const results = regex.exec(location.search);
//         return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
//     };

//     useEffect(() => {
//         const token = getUrlParameter('token');
//         const error = getUrlParameter('error');

//         if (token) {
//             localStorage.setItem(ACCESS_TOKEN, token);
//             navigate('/my-profile');
//             window.location.reload();
//         } else {
//             navigate('/login', {
//                 state: {
//                     from: location,
//                     error: error,
//                 },
//             });
//         }
//     }, [navigate, location]);

//     return null; // You can return null since this component performs redirects
// };

// export default OAuth2RedirectHandler;

import React, { useEffect } from "react";
import { ACCESS_TOKEN } from "../../constant";
import { useLocation, useNavigate } from "react-router-dom";

const OAuth2RedirectHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  useEffect(() => {
    const token = getUrlParameter("token");
    const error = getUrlParameter("error");

    if (token) {
      localStorage.setItem(ACCESS_TOKEN, token);

      // Check if the token is already present in the local storage
      // before attempting to reload the page
      if (!localStorage.getItem(ACCESS_TOKEN)) {
        window.location.reload();
      }

      navigate("/my-profile");
    } else {
      navigate("/login", {
        state: {
          from: location,
          error: error,
        },
      });
    }
  }, [navigate, location]);

  return null; // You can return null since this component performs redirects
};

export default OAuth2RedirectHandler;
