import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import Settings from "../../../services/settings";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Productdata from "../../../productSearch/productitem";
import { CircularProgress } from "@mui/material";

const MyFavoriteAds = () => {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  // console.log("decoded",decoded);

  const [dataR, setDataR] = useState([]);
  const [loading, setLoading] = useState(true);

  const myFavAds = () => {
    Settings.myFavAds()
      .then((response) => {
        setDataR(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          // } else {
          //   toast.error("Something went wrong!", {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          //   setLoading(false);
        }

        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // Delay the API call by 1000 milliseconds (1 second)
    const delay = 1000;

    const timer = setTimeout(() => {
      myFavAds();
    }, delay);

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []);
  return (
    <div className="" id="messages-v">
      {loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <CircularProgress size={60} /> {/* Customize size as needed */}
          </div>
        </div>
      ) : (
        <>
          <h3>My Favorite Ads</h3>
          <section className="privacy-policy mt-4">
            <div className="container-fluid">
              <div className="row">
                {/* {dataR.length === 0 ? <img src="./images/ImageNotFound.png" style={{display:"block",width:'300px',margin:'0 auto'}}/> :
                    dataR?.map((item, i) =>
                        <Productdata  allData={dataR}  data={item}/>
                    )
                } */}
                {dataR.length === 0 ? (
                  <>
                    <img
                      src="./images/ImageNotFound.png"
                      alt="image not found"
                      style={{
                        display: "block",
                        width: "100px",
                        margin: "0 auto",
                        marginTop: "30px",
                      }}
                    />{" "}
                    <p
                      className="text-center"
                      style={{ fontWeight: "700", fontSize: "20px" }}
                    >
                      No Post Found
                    </p>
                    <p className="text-center" style={{ fontSize: "20px" }}>
                      No advertisement could be found here!
                    </p>{" "}
                  </>
                ) : (
                  dataR?.map((item, i) => (
                    <Productdata allData={dataR} data={item} />
                  ))
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default MyFavoriteAds;
