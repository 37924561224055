import http from "../services/http-common";
import authHeader from "./auth-header";


  const appFeedback = (data) => {
    debugger; 

    // Create an empty JavaScript object to store the formData contents
    const formDataObject = {};
  
    // Iterate over the formData object and populate the formDataObject
    data.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));
    console.log(data);
    return http.post(`/appfeedback`, data, { 
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data;",
      },
    });
  };

const AppFeedback = {
    appFeedback,
};

export default AppFeedback;
