import React from "react";
import ChatContent from "../modules/chat/ChatContent";
import ChatHeader from "../modules/chat/ChatHeader";
import Footer from "../footer/footer";
import { useLocation,useNavigate   } from "react-router-dom";
import { useEffect } from "react";

const ChatPage = () => {
  const location = useLocation()
  const navigate  = useNavigate ()
  const isChatScreen = location.state?.isChatScreen || window.location.href.includes("chat")|| false;
  
  useEffect(() => {
    // Your effect logic here

    return () => {
      // Cleanup function
      if (window.location.hash === '#chat') {
        // Remove the hash fragment from the URL
        navigate(window.location.pathname + window.location.search);
      }
    };
  }, [navigate]);
  
  return (
    <>
      {/* <!-- -------------Chat-by-user-admin-start------ --> */}
      <div className={`${isChatScreen ? "active" : ""}`} id="chat">
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="col-lg-12">
           <ChatHeader/>
            {/* <!-- Tab panes --> */}
            <ChatContent/>
          </div>
          <div className="col-lg-6"></div>
        </div>
      </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ChatPage;
