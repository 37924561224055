/*global google*/
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import Footer from "../footer/footer";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import ProductSlider from "../home/productSlider";
import { useNavigate } from "react-router-dom";
import PostService from "../services/PostService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import AppService from "../services/AppService";
import { RWebShare } from "react-web-share";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Chip, Stack, Typography } from "@mui/material";
/*global google*/
function Productpage() {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;

  const userId = decoded ? decoded.sub : null;

  // console.log("decoded",decoded);

  const [imagedata, setImagedata] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const productData = location.state.data;
  const currentUrl = window.location.host;
  // console.log("productData", productData);

  useEffect(() => {
    // Use setTimeout to change loading to false after 1000 milliseconds (1 second)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Clear the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(timer);
  }, []);
  console.log("productData", productData);
  // console.log("User ID", userId);
  const scriptAddedRef = useRef(false);
  const [sliderImage, setSliderImage] = useState(productData.productImages);
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [productData]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);

    // setTimeout(()=>{

    // },500)
    if (!scriptAddedRef.current) {
      const addScript = document.createElement("script");
      //   addScript.setAttribute("class", "twic");
      //   addScript.classList.className = "dd";
      addScript.innerHTML = `
          var container = document.getElementById("myCarousel");
          console.log("container", container);
          var options = { Dots: false };
  
          new Carousel(container, options, { Thumbs });
  
          Fancybox.bind("[data-fancybox]", {
            // Your custom options
          });
        `;
      const googleMap = document.createElement("script");
      googleMap.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyCFGhHTFklBNiQGjvV3h03rc_vN995emG0&callback=initAutocomplete";
      document.body.appendChild(addScript);
      document.body.appendChild(googleMap);
      scriptAddedRef.current = true;

      document.documentElement.scrollTop = 0;
      setLoad(false);
    }
  }, [productData]);

  async function initAutocomplete() {
    const map = new google.maps.Map(document.getElementById("googleMap"), {
      center: {
        lat: productData?.productAddresses.lat,
        lng: productData?.productAddresses.lng,
      },
      zoom: 18,
      mapTypeId: "roadmap",
    });
    var marker = new google.maps.Marker({
      position: {
        lat: productData.productAddresses.lat,
        lng: productData.productAddresses.lng,
      },
    });
    marker.setMap(map);
  }
  window.initAutocomplete = initAutocomplete;

  const [dataR, setDataR] = useState([]);

  const getRealtedProducts = () => {
    PostService.getRelatedProdcuts(productData.id)
      .then((response) => {
        setDataR(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });

        // }
        console.log(error);
      });
  };

  const handleChatScreen = () => {
    let sellerData = productData;
    navigate("/my-profile", {
      state: { sellerResponse: sellerData, isChatScreen: true },
    });
  };

  const handleChat = () => {
    navigate("/login");
  };
  useEffect(() => {
    getRealtedProducts();
    setSliderImage(productData.productImages);
  }, [productData.id]);

  let liked = productData.liked;
  const [likes, setLikes] = useState(liked);

  const handleShowNumber = () => {
    if (!token) {
      // Redirect to the login page if there's no token
      navigate("/login");
    } else {
      // Code to reveal the mobile number or take any other action
    }
  };

  console.log("Likes value", likes);

  const handleLikeClick = () => {
    debugger;
    // Check if the user is logged in
    if (token) {
      // Toggle the likes state
      const newStatus = !likes;

      // Update likes state immediately
      setLikes(newStatus);

      // Call the like function (you can pass productData.id as an argument here)
      AppService.productLikeByUserId(productData.id, newStatus)
        .then((response) => {
          console.log("Like response", response.data);
          if (newStatus) {
            toast.success("Success: Added to your Wishlist", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success("Success: Removed from your Wishlist", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(`${error.response.data.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          //   else {
          //     toast.error("Something went wrong!", {
          //       position: toast.POSITION.TOP_RIGHT,
          //     });
          //   }
          console.log(error);
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <h1 className="_2Gr10-s">
        {loading ? <Skeleton height={40} /> : productData?.title}
      </h1>
      <div className="section-fancy mt-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8">
              <div className="fancy-box-custom">
                {productData?.featured ? (
                  <div className="featured-s-carousel">
                    <h3>Featured</h3>
                  </div>
                ) : null}

                <div className="f-carousel" id="myCarousel">
                  {/* {loading ? (
                                    <Skeleton height={450} count={1}/>
                                ) :  */}

                  {sliderImage?.map((item) => (
                    <div
                      className="f-carousel__slide"
                      data-thumb-src={item.imageUrl}
                    >
                      <a href={item.imageUrl} data-fancybox="gallery">
                        <img
                          width="100%"
                          height="480"
                          alt=""
                          src={item.imageUrl}
                          data-lazy-src={item.imageUrl}
                        />
                      </a>
                    </div>
                  ))}

                  {/* }  */}
                </div>
              </div>
              <section className="_2Wq-x">
                {loading ? (
                  <Skeleton count={2} height={50} />
                ) : (
                  <>
                    <div className="rui-1SRev">
                      <div className="rui-2CYS9 ok1RR">
                        {productData?.attribute &&
                          productData?.attribute.length > 0 && (
                            <div>
                              <h3>
                                <span>Details</span>
                              </h3>
                              <div className="_3B4o-">
                                <div className="_3nSm3">
                                  {productData?.attribute.map((item) => (
                                    <div className="_1O2tT" key={item.id}>
                                      <div className="_2oHc_">
                                        <span className="_3V4pD _rt-bole">
                                          {item.type}
                                        </span>
                                        <span className="B6X7c">
                                          {item.name}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        <h3 className="_30eN9">
                          <span>Description</span>
                        </h3>
                        <div>
                          <p className="prod-detail">
                            {productData?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </section>
            </div>
            <div className="col-lg-4">
              <div className="rui-1SRev _7qT_t">
                <div className="rui-2CYS9">
                  <section className="_8S0h4">
                    {/* <span className="T8y-z">{loading ? <Skeleton width={250} /> : productData?.price}</span> */}
                    <span className="T8y-z">
                      {loading ? (
                        <Skeleton width={250} />
                      ) : productData?.price === "$0.00" ? (
                        <div>
                          {"$"}
                          {productData?.attribute?.map((item, index) => {
                            if (
                              item.type === "Salary From" ||
                              item.type === "Salary To"
                            ) {
                              return (
                                <span key={index}>
                                  {item.name}
                                  {item.type === "Salary From" ? "-" : null}
                                </span>
                              );
                            }
                            return null; // Return null for other items in the attribute array
                          })}
                        </div>
                      ) : (
                        productData?.price
                      )}
                    </span>
                    {/* <h1 className="_2Gr10-s" title="">
                      {loading ? <Skeleton height={40} /> : productData?.title}
                    </h1> */}

                    <div className="_3dpp8">
                      <div className="_3ipDN">
                        <div className="_3Uj8e">
                          {loading ? (
                            <Skeleton height={20} width={250} />
                          ) : (
                            <span className="_1RkZP">
                              {productData?.productAddresses.country}{" "}
                              {productData?.productAddresses.state}{" "}
                              {productData?.productAddresses.city}{" "}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="_3n70Q">
                        {loading ? (
                          <Skeleton height={20} width={70} count={1} />
                        ) : (
                          <span>
                            {dayjs(productData?.createdAt).format("DD/MMM")}
                          </span>
                        )}
                      </div>
                    </div>
                  </section>
                  <div className="_3G9pp" style={{ display: "flex" }}>
                    {loading ? (
                      <Skeleton height={30} width={25} />
                    ) : (
                      <RWebShare
                        data={{
                          text: `${productData?.description}`,
                          url: `https://caribbargains.com/shareRedirect?post=${productData.id}`,
                          title: `${productData?.title}`,
                        }}
                        onClick={() => console.log("shared successfully!")}
                      >
                        <button>
                          <i className="fa fa-share-alt" aria-hidden="true"></i>
                        </button>
                      </RWebShare>
                    )}
                    {loading ? (
                      <Skeleton height={30} width={25} />
                    ) : (
                      <button className="heartBtn" onClick={handleLikeClick}>
                        <i
                          className="fa fa-heart"
                          aria-hidden="true"
                          id="button"
                          style={{ color: likes ? "#f00" : "#ddd" }}
                        ></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="rui-1SRev _3faUP">
                <div className="rui-2CYS9">
                  <div className="_3Yuv9 kI9QF">
                    <div className="_1ibEV">
                      <span className="_3I5yr">
                        <div className="child-gv">
                          <div className="user-profi">
                            {loading && (
                              <Skeleton
                                circle
                                height={60}
                                width={60}
                                containerClassName="avatar-skeleton"
                              />
                            )}
                            {/* <img
                                                        src={productData?.userResponse?.imageUrl}
                                                        style={{ display: loading ? 'none' : undefined }}
                                                    /> */}
                            {productData?.userResponse?.imageUrl ? (
                              <img
                                src={productData?.userResponse?.imageUrl}
                                style={{
                                  display: loading ? "none" : undefined,
                                }}
                                alt={productData?.userResponse?.name}
                              />
                            ) : (
                              // Display your default image here
                              <img
                                src="../images/profile-picture.webp" // Replace this with the path to your default image
                                alt="User Profile"
                                style={{
                                  display: loading ? "none" : undefined,
                                }}
                              />
                            )}
                          </div>

                          <div className="user-nam-tc">
                            {loading ? (
                              <Skeleton width={200} height={30} />
                            ) : (
                              productData?.contactName
                            )}
                          </div>
                        </div>
                        <div
                          style={{ marginRight: "0px", marginBottom: "2px" }}
                        >
                          Member Since{" "}
                          <span>
                            {dayjs(productData?.createdAt).format("MMM/YY")}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>

                  {productData?.sold ? (
                    <button
                      type="button"
                      data-aut-id="btnSold"
                      className="rui-39-wj rui-3evoE rui-1JPTg rui-2NuAg"
                    >
                      <span>Sold Out</span>
                    </button>
                  ) : userId == null ? (
                    <button
                      onClick={handleChat}
                      type="button"
                      data-aut-id="btnLogin"
                      className="rui-39-wj rui-3evoE rui-1JPTg rui-2NuAg"
                    >
                      <span>Login to Chat</span>
                    </button>
                  ) : productData?.userResponse?.id == userId ? null : (
                    <button
                      onClick={handleChatScreen}
                      type="button"
                      data-aut-id="btnChat"
                      className="rui-39-wj rui-3evoE rui-1JPTg rui-2NuAg"
                    >
                      <span>Chat with seller</span>
                    </button>
                  )}

                  <div className="_2hevt">
                    {token ? (
                      <span className="g8ZWR">
                        <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                        {productData?.hideMobile ? (
                          loading ? (
                            <Skeleton width={120} height={25} />
                          ) : (
                            <span style={{ fontWeight: 600, fontSize: "14px" }}>
                              {productData?.contactPhone}
                            </span>
                          )
                        ) : (
                          <>
                            <span style={{ marginTop: "3px" }}>
                              ***********
                            </span>
                            <div className="_3AfFq">
                              You can only Chat with Seller
                            </div>
                          </>
                        )}
                      </span>
                    ) : (
                      <>
                        <span className="g8ZWR">
                          <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          ************
                        </span>
                        <div className="_3AfFq" onClick={handleShowNumber}>
                          Show number
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <section className="_1cHdI"></section>
              </div>

              <div className="rui-1SRev gp-Oc" tabindex="0" role="button">
                <div className="rui-2CYS9">
                  <h3 className="_1W1LZ">Posted from</h3>
                  <div className="_3Uj8e">
                    <span className="_1RkZP">
                      {productData?.productAddresses?.country}{" "}
                      {productData?.productAddresses?.state}{" "}
                      {productData?.productAddresses?.city}
                    </span>
                  </div>
                  <div
                    className="rui-2BUoa _39Ajk"
                    style={{ marginTop: "10px" }}
                  >
                    <div
                      id="googleMap"
                      style={{ width: "100%", height: "230px" }}
                    ></div>
                  </div>

                  {productData?.userResponse?.id == userId && (
                    <div>
                      <Typography
                        sx={{ fontWeight: "bold", mt: 2, fontSize: "18px" }}
                      >
                        List of Countries Posted
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "8px",
                          padding: "10px",
                          margin: "0 2px",
                        }}
                      >
                        {productData?.countryList?.map((item, index) => (
                          <Chip
                            key={index}
                            label={item}
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <section className="">
                <div className="container-fluid ">
                  <div className="row my-3">
                    <div className="col">
                      <ProductSlider
                        heading={"Related Ads"}
                        data={dataR}
                        view={false}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Productpage;
