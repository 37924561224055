import http from "./http-common";
import authHeader from "./auth-header";

const getAllChats = () =>
  http.get("/users/summaries-with-messages", {
    headers: authHeader(),
  });

const getAllBuyingChats = () =>
  http.get("/users/buy/summaries-with-messages", {
    headers: authHeader(),
  });
  
  const getAllSellingChats = () =>
  http.get("/users/sell/summaries-with-messages", {
    headers: authHeader(),
  }) 
  
  const getSupportChats = () =>
  http.get("/support/chat", {
    headers: authHeader(),
  });

const getMessages = (senderId,recipientId,productId) => {
 return http.get(`/messages/test/${senderId}/${recipientId}/${productId}`, {
    headers: authHeader(),
  });
};

const getMessagesSupport = (senderId,recipientId) => {
 return http.get(`/messages/${senderId}/${recipientId}`, {
    headers: authHeader(),
  });
};

const deleteChat = (senderId,recipientId,productId) => {
 return http.get(`/chats/${senderId}/${recipientId}/${productId}`, {
    headers: authHeader(),
  });
};

const blockChat = (blockId,status) => {
 return http.post(`/chat/block?blockId=${blockId}&status=${status}`,{}, {
    headers: authHeader(),
  });
};

const ChatServices = {
  getSupportChats,
  getAllChats,
  getMessages,
  deleteChat,
  getAllBuyingChats,
  getAllSellingChats,getMessagesSupport,blockChat
};

export default ChatServices;
