import React from 'react'
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

const ChatHeader = () => {
  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
    <li className="nav-item tabs-design active" role="presentation">
      <button
        className="nav-link   active"
        id="home-tab"
        data-toggle="tab"
        data-target="#home"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
      >
        All
      </button>
    </li>
    <li className="nav-item tabs-design" role="presentation">
      <button
        className="nav-link  "
        id="profile-tab"
        data-toggle="tab"
        data-target="#profile"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="false"
      >
        Buying
      </button>
    </li>
    <li className="nav-item tabs-design" role="presentation">
      <button
        className="nav-link  "
        id="messages-tab"
        data-toggle="tab"
        data-target="#messages"
        type="button"
        role="tab"
        aria-controls="messages"
        aria-selected="false"
      >
        Selling
      </button>
    </li>
    <li className="nav-item tabs-design" role="presentation" style={{float: "right"}}>
      <button
        className=""
        id="messages-tab"
        data-toggle="tab"
        data-target="#support"
        type="button"
        role="tab"
        aria-controls="support"
        aria-selected="false"
        style={{paddingBottom: "4px"}}
      >
          <LiveHelpIcon /> 
      </button>
    </li>
  </ul>
  )
}

export default ChatHeader;