import React, { useState, useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import ChatServices from "../../../services/chat.services";
import { getCurrentTimeWithAMPM } from "../../../helpers/DateTimeFormatter";
import { CHAT_API_BASE_URL } from "../../../config";
import {
  sliceAndEllipsis,
  sliceStringHandler,
} from "../../../helpers/StringSlicer";
var stompClient = null;

const SellingTabContent = () => {
  const [messages, setMessages] = useState([]);
  const [currentChatDetails, setCurrentChatDetails] = useState({});
  const [isBlockedChat, setIsBlockedChat] = useState(null);
  const [textMessage, setTextMessage] = useState("");
  const [allChats, setAllChats] = useState([]);
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const UserId = decoded.sub;
  const userName = decoded.name;
  const imageUrl = decoded.imageUrl || "../images/profile-picture.webp";
  const containerRef = useRef(null);

  const connect = () => {
    const Stomp = require("stompjs");
    var SockJS = require("sockjs-client");
    SockJS = new SockJS(CHAT_API_BASE_URL);
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError);
  };

  const onError = (error) => {
    console.log("Error:", error);
  };

  const onConnected = () => {
    stompClient.subscribe(
      "/user/" + UserId + "/queue/messages",
      onMessageReceived
    );
  };

  const onMessageReceived = (msg) => {
    const message = JSON.parse(msg.body);

    if (message.senderId != UserId) {
      // let isMsg = messages.find(item=>item?.id!==message.id)
      // if(isMsg){
      //   return
      // }else{
      setMessages((prevMessages) => [...prevMessages, message]);
      setTimeout(() => {
        scrollToBottom();
      }, 1300);
      getAllChatsHandlerAndOpen();
      // }
      // getAllChatsHandler();
    }
  };

  const scrollToBottom = () => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const sendMessage = () => {
    if (textMessage.trim() !== "" && stompClient) {
      const chatMessage = {
        senderId: Number(UserId),
        senderName: userName,
        recipientId: currentChatDetails.userSummary.id,
        recipientName: currentChatDetails.userSummary.name,
        content: textMessage,
        timestamp: new Date(),
        productId: currentChatDetails?.recentMessage?.productId,
        productTitle: currentChatDetails?.recentMessage?.productTitle,
      };
      stompClient.send("/app/chat", {}, JSON.stringify(chatMessage));
      setMessages([...messages, chatMessage]);
      setTextMessage("");
      getAllChatsHandler();
      setTimeout(() => {
        scrollToBottom();
      }, 1300);
    }
  };

  const getAllChatsHandlerAndOpen = async () => {
    try {
      let res = await ChatServices.getAllSellingChats();
      // setAllChats(res.data);
      if (res.data.length > 0) {
        const filteredChats = res.data.filter(
          (item) => item.productId !== null
        );
        setAllChats(filteredChats);
        openMessagesHandler(filteredChats[0]);
      }
    } catch (error) {}
  };

  const getAllChatsHandler = async () => {
    try {
      let res = await ChatServices.getAllSellingChats();
      const filteredChats = res.data.filter((item) => item.productId !== null);
      setAllChats(filteredChats);
    } catch (error) {}
  };

  const blockChatHandler = async (recipientId, status) => {
    try {
      await ChatServices.blockChat(recipientId, status);
      getAllChatsHandler();
      setIsBlockedChat(status);
    } catch (error) {
      return error;
    }
  };

  const deleteChatHandler = async (item) => {
    let { recipientId, senderId, productId } = item?.recentMessage;
    try {
      await ChatServices.deleteChat(senderId, recipientId, productId);
      getAllChatsHandler();
    } catch (error) {
      return error;
    }
  };
  const [activeItem, setActiveItem] = useState(null);

  const openMessagesHandler = async (item) => {
    setIsBlockedChat(false);
    setActiveItem(item?.productId);
    if (item?.recentMessage) {
      let { recipientId, senderId, productId } = item?.recentMessage;
      try {
        let res = await ChatServices.getMessages(
          senderId,
          recipientId,
          productId
        );
        setIsBlockedChat(res.data.isBlocked);
        setMessages(res.data.messagesList);
        setCurrentChatDetails(item);
        getAllChatsHandler();
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      } catch (error) {
        return error;
      }
    }
  };

  useEffect(() => {
    connect();
    getAllChatsHandlerAndOpen();
  }, []);
  return (
    <div
      className="tab-pane"
      id="messages"
      role="tabpanel"
      aria-labelledby="messages-tab"
    >
      {allChats.length > 0 ? (
        <div className="row">
          <div className="col-lg-6 p-right ">
            <ul className="chat-user">
              {allChats?.map((item) => (
                <li
                  key={item?.id}
                  onClick={() => openMessagesHandler(item)}
                  style={{ cursor: "pointer" }}
                  className={
                    activeItem === item?.productId ? "active-item" : ""
                  }
                >
                  <div className="min-cht-div">
                    <div className="user-img">
                      <img
                        src={
                          item?.userSummary.profilePicture ||
                          "../images/profile-picture.webp"
                        }
                        alt="profile image"
                      />
                    </div>
                    <div className="user-text">
                      <h3 className="users-chat">
                        {item?.userSummary.name}
                        {item.messageCount !== 0 && (
                          <span className="msgCount">{item.messageCount}</span>
                        )}
                      </h3>
                      <h3 style={{ fontSize: "15px", fontWeight: "500" }}>
                        {item?.recentMessage?.productTitle}
                      </h3>
                      <span className="time-show">
                        {" "}
                        {getCurrentTimeWithAMPM(item?.recentMessage?.timestamp)}
                      </span>
                      {item?.userSummary.name !== "ADMIN" && (
                        <span className="time-showd">
                          <div className="dropdown">
                            <button
                              className="dropdown-toggle bg-transparent"
                              type="button"
                              data-toggle="dropdown"
                              href="#"
                              aria-expanded="true"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </button>
                            <ul className="dropdown-menu modifies">
                              {!isBlockedChat ? (
                                <li
                                  onClick={() =>
                                    blockChatHandler(
                                      item?.userSummary?.id,
                                      true
                                    )
                                  }
                                >
                                  <span className="dropdown-item">
                                    <i class="fa fa-lock"></i>&nbsp; Block User
                                  </span>
                                </li>
                              ) : (
                                <li
                                  onClick={() =>
                                    blockChatHandler(
                                      item?.userSummary?.id,
                                      false
                                    )
                                  }
                                >
                                  <span className="dropdown-item">
                                    <i class="fa fa-unlock-alt"></i>&nbsp;
                                    Unblock User
                                  </span>
                                </li>
                              )}
                              <li onClick={() => deleteChatHandler(item)}>
                                <span className="dropdown-item text-danger">
                                  <i className="fa fa-trash-o"></i> &nbsp;
                                  Delete chat
                                </span>
                              </li>
                            </ul>
                          </div>
                        </span>
                      )}
                      <p>{sliceStringHandler(item?.recentMessage?.content)}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-6 p-left ">
            <div className="main-chat-box">
              <div className="chat-show-box">
                <div className="user-name">
                  <div
                    className="msg-img"
                    style={{
                      backgroundImage: `url(${
                        currentChatDetails?.userSummary?.profilePicture ||
                        "../images/profile-picture.webp"
                      })`,
                    }}
                  ></div>
                  <h3 className="main-user-ct">
                    {currentChatDetails?.userSummary?.name}
                  </h3>
                </div>
                {!isBlockedChat && (
                  <div className="user-detail">
                    <a href={`tel:${currentChatDetails?.userSummary?.mobile}`}>
                      <i className="fa fa-phone"></i>
                    </a>
                    <a
                      href={`mailto:${currentChatDetails?.userSummary?.email}`}
                    >
                      <i className="fa fa-envelope"></i>
                    </a>
                  </div>
                )}
              </div>
              <div
                style={{ borderBottom: "2px solid #DADBDB", margin: "10px 0" }}
              >
                <h5 style={{ marginLeft: "2rem", paddingBottom: "4px" }}>
                  {currentChatDetails?.recentMessage?.productTitle}
                </h5>
              </div>
              <div className="chat-message" ref={containerRef}>
                {messages?.map((item) => (
                  <div
                    className={`msg ${
                      item?.senderId === Number(UserId)
                        ? "right-msg"
                        : "left-msg"
                    } mt-3`}
                  >
                    <div
                      className="msg-img"
                      style={{
                        backgroundImage:
                          item?.senderId === Number(UserId)
                            ? `url(${imageUrl})`
                            : `url(${
                                currentChatDetails?.userSummary
                                  ?.profilePicture ||
                                "../images/profile-picture.webp"
                              })`,
                      }}
                    ></div>
                    <div className="msg-bubble">
                      <div className="msg-info">
                        <div className="msg-info-time">
                          {getCurrentTimeWithAMPM(item?.timestamp)}
                        </div>
                      </div>

                      <div
                        className="msg-text"
                        style={{ wordWrap: "break-word" }}
                      >
                        {item?.content}
                      </div>
                    </div>
                  </div>
                ))}
                {!isBlockedChat && (
                  <form className="msger-inputarea">
                    <input
                      type="text"
                      className="msger-input"
                      placeholder="Enter your message..."
                      data-last-active-input=""
                      value={textMessage}
                      onChange={(e) => setTextMessage(e.target.value)}
                    />
                    <button
                      onClick={sendMessage}
                      type="button"
                      className="msger-send-btn"
                    >
                      Send
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="no-chat-message">
              <img src="./img/speech-bubble.png" alt="Chat" />
              <h3 className="text_no">No Chat messages</h3>
              <p className="text_no">
                Start conversing to see your message here!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SellingTabContent;
