import logo from './logo.svg';
import './App.css';
import { UserContext } from './root';
import Menu from './home/menu/menu';
import ReferEarn from './home/refer&earn';
import CategoryCount from './home/categoryCount';
import { useEffect, useState } from 'react';
import ProductSlider from './home/productSlider';
import AppDownload from './home/appDownload';
import Footer from './footer/footer';
import { useContext } from "react";
import AppService from './services/AppService';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [dataR, setDataR] = useState([])
  const { countrySate, categories } = useContext(UserContext);
  const [setcountryC, setSetCountryC] = countrySate;
  // console.log("country", setcountryC)
  const getRecommendations = () =>{
    AppService.getRecommendations(setcountryC[0]).then((response) => {
      setDataR(response.data.data)
    }).catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(`${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // else {
      //   toast.error("Something went wrong!", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }

      console.log(error);
    });
  }
  const [menu, setMenu] = useState([])
  const getMenu = () =>{
    AppService.getMenu().then((response) =>{
      setMenu(response.data.data);
    }).catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } 
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // }
        console.log(error);
      });
}
  useEffect(() => {
    getRecommendations()
    getMenu();
  }, [setcountryC])
  return (
    <>
      <Menu />
      <ReferEarn />
      {/* <CategoryCount /> */}
      {
        dataR?.map((item, i, recommendationGroup) =>
          <ProductSlider heading={Object.keys(item)[0]} data={item[Object.keys(item)[0]]} menu={menu} />
        )
      }
    <AppDownload />
      <Footer />

    </>
  );
}

export default App;
