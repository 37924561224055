function AppDownload() {
  return (
    <>
      <section className="download-area-bg">
        <a name="downloadlink" id="downloadlink"></a>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="down-wrapes">
                <div className="mobik-phone">
                  <img src="images/Images/logo phone.png" alt="logo" />
                </div>
                <div className="center-tag-wrapers">
                  <div className="download-text">
                    <h1>Download The Carib Bargains</h1>

                    <h2 className="download-text-h2"> 
                      Buy or Sell anything using the app on your mobile. <br />{" "}
                      Find Jobs, Homes, Services and more.
                    </h2>
                  </div>
                  <div className=" img-display-flex  ">
                    <a
                      href="https://apps.apple.com/us/app/carib-bargains/id6461727224"
                      target="ios"
                    >
                      <div className="img-icone ">
                        <img
                          src="images/Buttons/Group 1000001787.png"
                          alt="ios"
                        />
                      </div>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.app.caribbargains"
                      target="android"
                    >
                      <div className="img-icone">
                        <img
                          src="images/Buttons/Group 1000001788.png"
                          alt="android"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3">
                        <div className="cover-up-side">
                            <div className="side-text">
                                <h2>Download via SMS</h2>

                            </div>
                            <div className="input-type">
                                <input type="text" placeholder=" Enter Mobile Number" className="form-control custom-input-number" />
                            </div>
                            <button type="button" className="btn btn-primary side-use-btn-number">Submit</button>
                        </div>
                    </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default AppDownload;
