export function getCurrentTimeWithAMPM(timestamp) {
    const now = new Date(timestamp);
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12;
  
    // Add leading zeros to minutes if necessary
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  
    // Create the formatted time string
    const formattedTime = `${hours12}:${formattedMinutes} ${ampm}`;
  
    return formattedTime;
  }


  export function formatTransactionTime(createdAt,dynamicDaysThreshold) {
    const now = new Date();
    const createdDate = new Date(createdAt);
    // Calculate the time difference in milliseconds
    const timeDifference = now - createdDate;
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
  
    if (daysDifference >= dynamicDaysThreshold) {
      // More than dynamicDaysThreshold days ago, return the date
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return createdDate.toLocaleDateString(undefined, options);
    } else if (daysDifference > 0) {
      // X days ago
      return `${daysDifference} days ago`;
    } else if (hoursDifference >= 1) {
      // X hours ago
      return `${hoursDifference} hours ago`;
    } else if (minutesDifference >= 1) {
      // X minutes ago
      return `${minutesDifference} minutes ago`;
    } else if (secondsDifference >= 1) {
      // X seconds ago
      return `${secondsDifference} seconds ago`;
    } else {
      // Less than 1 second ago, return "just now"
      return 'just now';
    }
  }
  
  
  
