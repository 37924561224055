import { useSearchParams } from "react-router-dom";
import AppService from "../services/AppService";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
function ShareRedirect() {
    let [searchParams, setSearchParams] = useSearchParams();
    console.log("searchParams", searchParams.get("post"));
    console.log("searchParams", searchParams.get("share"))
    const share = searchParams.get("share")
    const productId = searchParams.get("post")
    const navigate = useNavigate();
    function postUrl(){
        AppService.postIDProduct(productId).then((response) =>{
            console.log(response.data.data)
            navigate('/productdetail', { state: { data: response.data.data } })
        }).catch((error) => {
            if (error.response && error.response.data && error.response.data.message) {
              toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } 
            // else {
            //   toast.error("Something went wrong!", {
            //     position: toast.POSITION.TOP_RIGHT,
            //   });
            // }
            console.log(error);
          });
    }
    postUrl()
    return (<>
    {/* <img src="./img/logo.png" style={{display:"block",width:"300px",margin:'50px auto'}} /> */}
    <h3 style={{textAlign:"center",margin:'50px auto'}}>Please Wait...</h3>    
    </>);
}

export default ShareRedirect;