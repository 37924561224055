import http from "./http-common";
// import authHeader from "./auth-header";

const getCategories = () => http.get(`/category/subcategory`);

const getCategoryMeta = (id) => http.get(`category/categories/${id}`);

const getCategoriesBySlug = (slug) => http.get(`category/${slug}`);

const CategoriesService = {
  getCategories,
  getCategoryMeta,
  getCategoriesBySlug,
};

export default CategoriesService;
