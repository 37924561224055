import http from "../services/http-common";
import authHeader from "./auth-header";


  const hideUserPhoneNo = (data) => {
    debugger; 
    console.log(data);
    return http.post(`/user/showmobile`, data);
  };

  const myAds = (id) =>{
    return http.get(`/product/post/all/user/${id}`,{
      headers: authHeader(),
    })
  }

  const soldORPublish = (sold, publish, id) =>{
    return http.get(`/product/setstatus?sold=${sold}&publish=${publish}&productId=${id}`,{
      headers: authHeader(),
    })
  }

  const myFavAds = () =>{
    return http.get(`/product/wishlist`,{
      headers: authHeader(),
    } )
  }

  const getProfile = (id) =>{
    return http.get(`/user/${id}`,{
      headers: authHeader(),
    })
  }

  const updateProfile = (data) =>{
    return http.post(`/user/update`, data,{
      headers: authHeader(),
    })
  }



  const updateProfileImg = (file, id) => {
    let formData = new FormData();
  
    formData.append("image", file);
  
    return http.post(`/user/uploadimage?id=${id}`, formData, {
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      }
    });
  };
  

const Settings = {
    hideUserPhoneNo,
    myAds,
    soldORPublish,
    myFavAds,
    getProfile,
    updateProfile,
    updateProfileImg
};

export default Settings;
