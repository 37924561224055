import http from "./http-common";
import authHeader from "./auth-header";

  const getUserStatement = () =>
  http.get(
    `user/statement`,
    {
      headers: authHeader(),
    }
  );

  const UserService = {

    getUserStatement,
  };
  
  export default UserService;
  
