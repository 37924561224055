import { Container, Link } from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

export default function FAQ() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container component="main">
      <div id="contact" class="form">
        <div class="container">
          <div class="row my-3">
            <Link
              component={RouterLink}
              to={"/"}
              className="col-lg-12 text-center"
            >
              <img
                src="images/logo/Asset 9 2.png"
                alt="faq"
                className="desk_tops"
              />
            </Link>
            <div class="col-lg-12">
              <h2 className="mb-2">FAQ</h2>
              <ul class="list-unstyled li-space-lg">
                <li class="address">
                  Last Updated: Monday 11<sup>th</sup> September 2023
                </li>
              </ul>
            </div>
            <div class="col-lg-12">
              <h6>
                <b>1. What is Carib Bargains?</b>
              </h6>
              <p>
                Carib Bargains is a user-friendly online marketplace for
                Caribbean countries where users can buy and sell a wide range of
                new and pre-loved old items. It's a platform designed to connect
                buyers and sellers across the Caribbean, making it easy to
                discover great deals right in their local area.
              </p>
              <h6>
                <b>2. How do I create an account on Carib Bargains?</b>
              </h6>
              <p>
                Creating an account on Carib Bargains is simple. Just click on
                the 'Login/Register' button, fill in your details and you're
                ready to start buying or selling. You can also sign up using
                your Google or Facebook account for added convenience.
              </p>
              <h6>
                <b>3. How can I list an item for sale?</b>
              </h6>
              <p>
                Listing an item for sale is a breeze. Log in to your Carib
                Bargains account, click on the "Post Free Ad" button, and follow
                the step-by-step instructions to create a listing. Be sure to
                provide clear photos and a detailed description to attract
                potential buyers. If you are not logged in It will ask you to
                login first before posting the ad. Also if you want to post your
                ads for users across the countries you will be charged extra
                coins and it will be done.
              </p>
              <h6>
                <b>4. What payment methods are accepted on Carib Bargains?</b>
              </h6>
              <p>
                Carib Bargains supports a variety of payment methods, including
                credit/debit cards, apple pay to buy application coins to be
                able to post ads. On sign-up, successful referrals users get
                free coins to be able to post ads totally free.
              </p>
              <h6>
                <b>5. Do I need to pay for purchase done on Carib Bargains?</b>
              </h6>
              <p>
                No Carib Bargains doesn’t collect any payment for any sell or
                purchase being done. It is done buy parties beyond the app using
                any method including cash on their mutually agreed terms. We
                just collect payment to buy our subscriptions to add additional
                coins to be able to use our services to post new ads.
              </p>
              <h6>
                <b>6. How do I contact a seller or buyer?</b>
              </h6>
              <p>
                You can easily get in touch with other users by clicking on the
                "Contact" or “Chat” button on their listings. This opens a
                messaging platform where you can discuss details, negotiate
                prices, and finalize deals.
              </p>
              <h6>
                <b>
                  7. What should I do if I encounter a problem with a
                  transaction?
                </b>
              </h6>
              <p>
                If you encounter any issues during a transaction, we recommend
                reaching out to your payment method provider/bank to resolve the
                problem amicably or confirm it. If a resolution can't be
                reached, you can contact our support team, and we'll do our best
                to assist you in resolving the matter fairly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
