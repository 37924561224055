import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import jwtDecode from "jwt-decode";

function Footer() {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  // console.log("decoded",decoded);
  return (
    <>
      <section className="container-color-g">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12  col-sm-12 my-5">
              <div className="logo-footer">
                <img src="/images/logo/Asset 9 2.png" alt="caribbargains" />
              </div>
              <p className="midle-text mb-0">
                Discover the Hidden Treasures of the Caribbean, Right from Your
                Home!
              </p>

              <h2 className="midle-text">
                Carib Bargains – your ultimate destination for buying, selling,
                and discovering! It allows you to dive into a vibrant
                marketplace, specially crafted for Caribbean users. Here users
                can find their dream ride, from cars to super bikes, can explore
                top-notch electronics, home essentials, and more. Whether they
                are buying or selling, our user-friendly platform makes it a
                breeze.{" "}
              </h2>
              <div className="footer-menu-bar">
                <ul className="footer-menu">
                  <li>
                    <Link
                      component={RouterLink}
                      to={"/aboutus"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      About Us
                    </Link>
                  </li>

                  {/* <li><a href="./contactus.html">Contact
                                    Us</a></li> */}
                  {/* <li><a href="./career.html">Careers</a></li> */}
                  <li>
                    <Link
                      component={RouterLink}
                      to={"/faq"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      FAQs
                    </Link>
                  </li>

                  {!decoded ? (
                    <li>
                      <Link
                        component={RouterLink}
                        to={`/login`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Advertise With Us
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link
                        component={RouterLink}
                        to={`/categories`}
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        Advertise With Us
                      </Link>
                    </li>
                  )}

                  <li>
                    <Link
                      component={RouterLink}
                      to={`/privacy-policy`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      component={RouterLink}
                      to={`/term-condition`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link
                      component={RouterLink}
                      to={`/contactus`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      component={RouterLink}
                      to={"/sitemap"}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      Sitemap
                    </Link>
                  </li>

                  {/* <li><a href="./blogs.html">Blog</a></li>
                                <li><a href="./help.html">Help</a>
                                    <li><a href="./Premium-ads.html">Premium Ads</a></li>
                                </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="post-add-section">
        <div className="container-fluid">
          <div className="row">
            <div className="post-btn-wraper">
              <div className="po-cx-child">
                <p className="f-bottom-cx">
                  At Carib Bargains, you can buy, sell anything you can think
                  of.
                </p>
              </div>
              {/* <div className="po-cx-child">

                            <Link component={RouterLink} to={`categories`} type="button" className="btn btn-danger post-add" style={{color:"white", textDecoration:'none'}}>Post Free Ad</Link>
                        </div> */}

              {!decoded ? (
                <div className="po-cx-child">
                  <Link
                    component={RouterLink}
                    to={`/login`}
                    underline="none"
                    type="button"
                    className="btn btn-danger post-add"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Post Free Ads
                  </Link>
                </div>
              ) : (
                <div className="po-cx-child">
                  <Link
                    component={RouterLink}
                    to={`/categories`}
                    underline="none"
                    type="button"
                    className="btn btn-danger post-add"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Post Free Ads
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="container-color-g">
        <div className="container-fluid">
          <div className="row my-5">
            <div className="col-md-5 col-sm-12">
              <div className="social-section-app">
                <div className="text-cdx">
                  <div style={{ fontSize: "25px" }}>
                    <strong>Download The App :</strong>
                  </div>
                </div>
                <div className="icon-sec-cx">
                  <a
                    href="https://apps.apple.com/us/app/carib-bargains/id6461727224"
                    target="ios"
                  >
                    <img
                      className="play-s-cx"
                      src="/./images/Social Media Icons/apple-logo.png"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.app.caribbargains"
                    target="android"
                  >
                    <img
                      className="play-s-cx"
                      src="/./images/Social Media Icons/playstore.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-7 col-sm-12">
              <div className="cover-wrap custom-right-side">
                <div className="social-section-app-icon">
                  <div className="text-cdx">
                    <div style={{ fontSize: "25px" }}>
                      <strong>Follow Us :</strong>
                    </div>
                  </div>
                  <div className="icon-sec-cx">
                    <a
                      href="https://www.facebook.com/carib.bargain/"
                      target="facebook"
                    >
                      <img
                        className="play-s-cx"
                        src="/./images/Social Media Icons/facebook.png"
                        alt="facebook"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/carib_bargains/"
                      target="instagram"
                    >
                      <img
                        className="play-s-cx"
                        src="/./images/Social Media Icons/instagram.png"
                        alt="instagram"
                      />
                    </a>
                    <a
                      href="https://twitter.com/carib_bargain/"
                      target="twitter"
                    >
                      <img
                        className="play-s-cx"
                        src="/./images/Social Media Icons/twitter.png"
                        alt="twitter"
                      />
                    </a>
                    {/* <a href="#"><img className="play-s-cx" src="./images/Social Media Icons/whatsapp.png" alt="" /></a> */}
                    <a
                      href="https://www.youtube.com/channel/UCZVYwVKnaGCw7EsoEiiaWkw"
                      target="youtube"
                    >
                      <img
                        className="play-s-cx"
                        src="/./images/Social Media Icons/youtube.png"
                        alt="youtube"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
