import { Container, Link } from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

export default function TermCondition() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container component="main">
      <div id="contact" class="form">
        <div class="container">
          <div class="row my-3">
            <Link
              component={RouterLink}
              to={"/"}
              className="col-lg-12 text-center"
            >
              <img
                src="images/logo/Asset 9 2.png"
                alt="termcondition"
                className="desk_tops"
              />
            </Link>
            <div class="col-lg-12">
              <h2 className="mb-3">Terms and Conditions</h2>
              <ul class="list-unstyled li-space-lg">
                <li class="address">
                  Last Updated: Friday 17<sup>th</sup> August 2023
                </li>
              </ul>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                Please read these Terms and Conditions ("Terms") carefully
                before using <strong>Carib Bargains</strong> ("the App", "the
                Service", "we", "us", or "our"). By using the App, you agree to
                be bound by these Terms. If you do not agree to these Terms,
                please do not use the App.
              </p>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>User Conduct:</b>
              </p>
              <p>By using Carib Bargain, you agree not to:</p>
              <ol type="1">
                <li>
                  <p>
                    Use the App for any unlawful or prohibited purpose,
                    including but not limited to violating intellectual property
                    rights, engaging in fraud, or distributing malicious
                    software.
                  </p>
                </li>
                <li>
                  <p>Infringe any third-party rights.</p>
                </li>
                <li>
                  <p>
                    Distribute spam, chain letters, or promote pyramid schemes.
                  </p>
                </li>
                <li>
                  <p>
                    Distribute viruses or technologies that may harm Carib
                    Bargain or its users.
                  </p>
                </li>
                <li>
                  <p>
                    Impose an unreasonable load on our infrastructure or
                    interfere with Carib Bargain's operation.
                  </p>
                </li>
                <li>
                  <p>
                    Copy, modify, or distribute another person's content without
                    consent.
                  </p>
                </li>
                <li>
                  <p>
                    Use any other information on the App for your own purpose
                    without consent.
                  </p>
                </li>
                <li>
                  <p>Use automated means without our permission.</p>
                </li>
                <li>
                  <p>Bypass measures to restrict access to Carib Bargain.</p>
                </li>
                <li>
                  <p>Interfere with ratings or feedback systems.</p>
                </li>
                <li>
                  <p>
                    Infringe copyright, trademark, or other rights of third
                    parties.
                  </p>
                </li>
                <li>
                  <p>
                    You are solely responsible for any content you upload or
                    share through the App. We reserve the right to remove
                    content that violates these Terms or is inappropriate.
                  </p>
                </li>
              </ol>
            </div>

            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                <b>Abusing Carib Bargain:</b>
              </p>
              <p>
                For Reported problems like offensive content, and any breaches
                to us and if users cause problems, infringe rights, or violate
                policies. We may issue warnings, limit service, or remove
                content. We aren't obliged to monitor content.
              </p>
              <p>
                <b>Caribbean Marketplace:</b>
              </p>
              <p>
                Your listings or shop may appear on other sites, services,
                applications, and tools in the Caribbean region for promotion.
                By posting, you agree your content can be displayed on these
                platforms, subject to their policies.
              </p>
              <p>
                <b>Subscription and Services:</b>
              </p>

              <p>
                Our App uses subscriptions to buy coins/points to utilize its
                services. Those points can also be earned by simply registering
                there or referring app to friends or family members. We may
                suspend or terminate services at our discretion.{" "}
              </p>
              <p>
                <b>Content:</b>
              </p>
              <p>
                You agree not to copy, distribute, or modify Carib Bargain's
                content. Any content you provide must adhere to guidelines and
                may be displayed on various platforms.
              </p>

              <p>
                <b>Infringement: </b>
              </p>
              <p>
                Don't post content infringing on others' rights. We respect
                intellectual property rights and act against infringement.
              </p>
              <p>
                <b>Liability:</b>
              </p>
              <p>
                We aren't liable for postings, user actions, or quality of
                offerings and we are not involved in the actual transactions
                between users so it is advised to use Carib Bargain with
                precautions and at your own risk. In no event shall we be liable
                for any indirect, incidental, special, consequential, or
                punitive damages, or any loss of profits or revenues, whether
                incurred directly or indirectly.{" "}
              </p>

              <p>
                <b>Payment Services:</b>
              </p>
              <p>
                We don’t offer any payment service so don’t use any link for
                payment from any advertisement/post. We have only our own
                subscriptions to pay from the app not from the posts.{" "}
              </p>

              <p>
                <b>Release:</b>
              </p>
              <p>
                You release us from claims arising from disputes with other
                users.{" "}
              </p>

              <p>
                <b>Third Party Rights:</b>
              </p>
              <p>
                This Agreement doesn't grant third parties rights to enforce
                terms.{" "}
              </p>

              <p>
                <b>Data Privacy:</b>
              </p>
              <ol type="1">
                <li>
                  <p>
                    {" "}
                    <b>User Data:</b> We collect and process personal data
                    according to our Privacy Policy. By using the App, you
                    consent to our data practices as outlined in the Privacy
                    Policy.
                  </p>
                </li>
              </ol>

              <p>
                <b>Intellectual Property:</b>
              </p>
              <ol type="1">
                <li>
                  <p>
                    {" "}
                    <b>Ownership:</b> The App and its contents are protected by
                    intellectual property rights owned or licensed by us. You
                    may not reproduce, distribute, or create derivative works
                    without our express permission.
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <b>User Content:</b> By sharing content through the App, you
                    grant us a non-exclusive, worldwide, royalty-free license to
                    use, reproduce, modify, adapt, publish, translate, and
                    distribute your content.
                  </p>
                </li>
              </ol>

              <p>
                <b>Disclaimer of Warranty:</b>
              </p>
              <p>
                The App is provided "as is" and "as available." We do not
                warrant that the App will meet your requirements or that the
                operation of the App will be uninterrupted or error-free.{" "}
              </p>

              <p>
                <b>Termination:</b>
              </p>
              <p>
                We reserve the right to terminate or suspend your access to the
                App at any time and without notice, for any reason, including if
                we believe you have violated these Terms.
              </p>

              <p>
                <b>Governing Law:</b>
              </p>
              <p>
                These Terms are governed by and construed in accordance with the
                laws of Caribbean, without regard to its conflict of law
                principles.
              </p>

              <p>
                <b>Dispute Resolution:</b>
              </p>
              <p>
                We encourage dispute resolution through contact and alternative
                methods.
              </p>

              <p>
                <b>Mobile Devices Terms:</b>
              </p>
              <p>
                Using Carib Bargain on mobile is subject to additional terms,
                including IP rights.
              </p>

              <p>
                <b>Changes to Terms:</b>
              </p>
              <p>
                We reserve the right to modify these Terms at any time. We will
                notify users of any changes by posting the revised Terms within
                the App.
              </p>
              <p>
                <b>Contact Us:</b>
              </p>
              <p>
                If you have any questions about these Terms, please contact us
                at:
              </p>

              <p>
                <b>Email:</b>{" "}
                <a href="mailto: mailto:support@caribbargains.com">support </a>{" "}
              </p>
              <p>
                <b>Website:</b>{" "}
                <a href="https://www.caribbargains.com">
                  {" "}
                  https://www.caribbargains.com/
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
