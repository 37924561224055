import { useContext, useEffect, useState } from "react";
import { Avatar, Badge, Link } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { UserContext } from "../root";
import "./header.css";
import jwtDecode from "jwt-decode";
import PostService from "../services/PostService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppService from "../services/AppService";
import ReactFlagsSelect from "react-flags-select";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ChatIcon from "@mui/icons-material/Chat";
import { useMessageCount } from "../MessageContext";
import useChatNotificationService from "../hooks/useChatNotificationService";
import { Helmet } from "react-helmet-async";
import axios from "axios";

function Header() {
  const { newMessageCount } = useMessageCount();
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  // console.log("decoded", decoded);
  const UserId = decoded?.sub || null; // Use optional chaining to avoid null
  const [user, setUsers] = useState([]);
  const {
    countrySate,
    categories,
    categoriesState,
    categoryId,
    categoryNameGlobel,
    searchContaxt,
  } = useContext(UserContext);
  const [setcountryC, setSetCountryC] = countrySate;
  const [stateCategory, setStateCategory] = categories;
  const [mainCategoryId, setMainCategoryId] = categoryId;
  const [searchText, setSearchTest] = searchContaxt;

  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [mainCategoryName, setMainCategoryName] = categoryNameGlobel;
  const [allcategorystate, setAllCategorystate] = categoriesState;
  const [search, setSearch] = useState("");
  const [dataR, setDataR] = useState([]);
  const [hitBtn, setHitBtn] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingCountry, setLoadingCountry] = useState(true);

  const { getAllChatsRecentHandler, connect } = useChatNotificationService();
  // console.log("newMessageCount",newMessageCount)
  useEffect(() => {
    connect();
    getAllChatsRecentHandler();
  }, []);

  useEffect(() => {
    setLoading(true);

    if (UserId) {
      const getUser = () => {
        PostService.getUser(UserId)
          .then((response) => {
            setUsers(response.data.data);
            // console.log("User data", response.data.data);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      };

      getUser();
    }
  }, [UserId]);

  // console.log("user", user);
  function sc() {
    localStorage.setItem("country", setcountryC);
  }

  useEffect(() => {
    sc();
  }, [setcountryC]);

  const navigate = useNavigate();

  const getMenu = () => {
    AppService.getMenu()
      .then((response) => {
        setDataR(response.data.data);
        setStateCategory(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        // else {
        //     toast.error("Something went wrong!", {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        // }
        console.log(error);
      });
  };

  function categoryset(e) {
    debugger;
    // setCategory(e.target.value)
    // setStateCategory(e.target.value)
    const {
      id: categoryId,
      subCategory,
      allCategory,
      subcategotyName,
      slug,
    } = JSON.parse(e.target.value);
    // const categoryIdNumber = parseInt(categoryId, 10);
    setAllCategorystate(allCategory);
    setCategory(categoryId);
    setStateCategory(subCategory);
    setMainCategoryId(categoryId);
    setMainCategoryName(subcategotyName);
    setCategoryName(subcategotyName);
    setCategorySlug(slug);

    if (categoryId === "" || categoryId === null) {
      navigate(`/product/items`);
      return;
    }

    sendDataNew(categoryId, slug);
    console.log("categoryTest", category);
  }
  function searchset(e) {
    setSearch(e.target.value);
    // setSearchTest(e.target.value);
  }

  function sendDataNew(mycat, slug) {
    localStorage.setItem("searchsub", "true");
    setHitBtn(hitBtn + 1);
    // setMainCategoryName(mainCategoryName);
    navigate(`/${slug}`, {
      state: {
        category: mycat,
        subcategotyName: mainCategoryName,
        search: search,
        subcategory: "",
        allSubcategory: dataR,
        searchsubs: true,
        hitBtn: hitBtn,
      },
    });
  }

  function sendData(maincat, categorySlug) {
    // setSearch(e.target.value);
    console.log("categorySlug", categorySlug);
    if (category === "") {
      toast.error("Please select a category in search", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setSearchTest(search);
    localStorage.setItem("searchsub", "true");
    setHitBtn(hitBtn + 1);
    setMainCategoryName(categoryName);
    navigate(`/${categorySlug}`, {
      state: {
        category: category,
        search: search,
        subcategory: "",
        allSubcategory: dataR,
        searchsubs: true,
        hitBtn: hitBtn,
      },
    });
  }
  const [country, setCountry] = useState([]);

  const getCountryList = () => {
    AppService.getCountryList()
      .then((response) => {
        setCountry(response.data.data.country);
        console.log("response.data.data.country", response.data.data.country);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          // toast.error("Something went wrong!", {
          //     position: toast.POSITION.TOP_RIGHT,
          // });
        }
        console.log(error);
      });
  };

  // console.log("country", JSON.stringify(country));

  useEffect(() => {
    getMenu();
    getCountryList();
  }, []);

  const [countryCode, setCountryCode] = useState("");
  const [selected, setSelected] = useState(() => {
    // Initialize the state with the value from local storage or "KN" as a default
    const storedCountry = localStorage.getItem("selectedCountryC");
    const defaultCountry = localStorage.getItem("defaultCountry");

    const isDefaultCountryValid =
      defaultCountry && country?.cca2 && country.cca2.includes(defaultCountry);

    const defaultCountryValue = isDefaultCountryValid ? defaultCountry : "KN";
    return storedCountry ? JSON.parse(storedCountry)[1] : defaultCountryValue;
  });

  // const [selected, setSelected] = useState(() => {
  //   // Initialize the state with the value from local storage or "KN" as a default
  //   const storedCountry = localStorage.getItem("selectedCountryC");
  //   const defaultCountry = localStorage.getItem("defaultCountry");
  //   // const countryList = /* fetch the country list */;

  //   // Set "KN" as the default if defaultCountry is empty or null
  //   const defaultCountryValue = defaultCountry ? defaultCountry : "KN";

  //   // Check if the defaultCountryValue exists in the country list
  //   const isDefaultCountryValid = country.some(
  //     (country) => country.cca2 === defaultCountryValue
  //   );

  //   // If not, set "KN" as the default
  //   return isDefaultCountryValid ? JSON.parse(storedCountry)[1] : "KN";
  // });

  // const [countryCode, setCountryCode] = useState("");
  // const [selected, setSelected] = useState(() => {
  //   // Initialize the state with the value from local storage or "KN" as a default
  //   const storedCountry = localStorage.getItem("selectedCountryC");
  //   const defaultCountry = localStorage.getItem("defaultCountry");

  //   // Set "KN" as the default if defaultCountry is empty or null
  //   const defaultCountryValue = defaultCountry ? defaultCountry : "KN";

  //   // Check if defaultCountry matches any country ISO code from the list
  //   const isDefaultCountryValid = country.some(
  //     (countryObj) => countryObj.iso === defaultCountryValue
  //   );

  //   return isDefaultCountryValid
  //     ? storedCountry
  //       ? JSON.parse(storedCountry)[1]
  //       : defaultCountryValue
  //     : "KN";
  // });

  const defaultCountry2 = localStorage.getItem("defaultCountry");
  console.log("defaultCountry2", defaultCountry2);

  const fetchData = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      const data = response.data;

      console.log("data", data);

      // Set default value for the code field based on the API response
      const defaultCode = data.country_code; // Replace with your logic
      setCountryCode(defaultCode);
      setLoadingCountry(false);
      localStorage.setItem("defaultCountry", defaultCode);
      if (defaultCountry2 == "" || defaultCountry2 == null) {
        setSelected(defaultCode);
      }
    } catch (error) {
      console.error("Error fetching IP API data:", error);
      setLoadingCountry(false);
      // Handle errors as needed
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // console.log("countryCode", countryCode);

  const handleCountrySelect = (code) => {
    const selectedCountry = country.find((item) => item.cca2 === code);

    if (selectedCountry) {
      //   console.log("Selected Country Name:", selectedCountry.name);
      setSelected(code); // Update the selected country in ReactFlagsSelect
      setSetCountryC([selectedCountry.name, code]); // Update your state with the selected country name
      // Update localStorage
      localStorage.setItem(
        "selectedCountryC",
        JSON.stringify([selectedCountry.name, code])
      );
    } else {
      //   console.log("Country not found for code:", code);
    }
  };

  const countries = country?.map((country) => country.cca2);

  // Assuming you have a boolean value for blacklistCountries
  const blacklistCountries = false;

  // const [invisible, setInvisible] = useState(false);

  // const handleBadgeVisibility = () => {
  //   setInvisible(!invisible);
  // };

  return (
    <>
      <section className="sticky-header">
        <header id="header">
          <div className="topbar" style={{ backgroundColor: "#fff" }}>
            <div className="container-fluid">
              <div className="row custom-css-x">
                <div className="col-lg-4">
                  <div className="top-flex-cd">
                    <div class="top-cx-child">
                      <div class="dropdown">
                        <span
                          class="dropdown-toggle"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{ cursor: "pointer" }}
                        >
                          <img src="/img/Vector (1).png" alt="download" />
                          <span style={{ marginLeft: 5 }}>
                            {" "}
                            Download The App <i class="fa fa-angle-down"></i>
                          </span>
                        </span>

                        <div
                          class="dropdown-menu cxt_tys"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <ul class="topdr-img">
                            <li>
                              <a
                                href="https://apps.apple.com/us/app/carib-bargains/id6461727224"
                                class="dropdown-item"
                                target="ios"
                              >
                                <i class="fa fa-apple"></i>
                                Ios
                              </a>
                            </li>

                            <li>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.app.caribbargains"
                                class="dropdown-item"
                                target="android"
                              >
                                <i class="fa fa-android"></i>
                                Android
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="top-cd-child">
                                            <a href="#"> <span><img src="./images/All Icons/coin.png" alt="" /></span> Discount on
                                                Premium Ads </a>
                                        </div> */}
                  </div>
                </div>
                <div className="col-lg-4">
                  {!decoded ? (
                    <div className="mx-buttons">
                      <Link
                        component={RouterLink}
                        to={"/login"}
                        color="gray"
                        type="button"
                        underline="none"
                        className="btn btn-primary side-use-user"
                        data-toggle="modal"
                        data-target="#exampleModal"
                      >
                        <span className="font-icon">
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </span>
                        Login/Register
                      </Link>
                    </div>
                  ) : (
                    <div className="mx-buttons">
                      <Link
                        component={RouterLink}
                        to={"/my-profile"}
                        color="gray"
                        type="button"
                        underline="none"
                        className="btn btn-primary side-use-user"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <div className="avatar">
                            {loading && (
                              <Skeleton
                                circle
                                height="100%"
                                containerClassName="avatar-skeleton"
                              />
                            )}
                            <Avatar
                              alt={user.name}
                              src={user.imageUrl}
                              style={{ display: loading ? "none" : undefined }}
                            />
                          </div>
                          <span style={{ marginLeft: "8px" }}>
                            {loading ? <Skeleton width={70} /> : user?.name}
                            {!loading && (
                              <>
                                <Badge
                                  component={RouterLink}
                                  to={"/my-profile#chat"}
                                  color="error"
                                  variant="dot"
                                  invisible={newMessageCount === 0}
                                >
                                  <ChatIcon sx={{ ml: 3 }} />
                                </Badge>
                              </>
                            )}{" "}
                            {/* Assuming ChatIcon is your chat icon component */}
                          </span>
                        </span>
                      </Link>
                    </div>
                  )}
                </div>
                <div className="col-lg-4">
                  <div className="fd-wraping">
                    {!decoded ? (
                      <div className="dx-buton">
                        <Link
                          component={RouterLink}
                          to={`/login`}
                          underline="none"
                          type="button"
                          className="btn btn-primary side-use-btn"
                          style={{ color: "white" }}
                        >
                          Post Free Ads
                        </Link>
                      </div>
                    ) : (
                      <div className="dx-buton">
                        <Link
                          component={RouterLink}
                          to={`categories`}
                          underline="none"
                          type="button"
                          className="btn btn-primary side-use-btn"
                          style={{ color: "white" }}
                        >
                          Post Free Ads
                        </Link>
                      </div>
                    )}
                    <div className="flgx">
                      {/* <a href="#countryModal" className="country-flag popup-with-zoom-anim" data-toggle="modal" data-target="#modal-country">
                                                <img src="./images/All Icons/Flag.png" />
                                            </a> */}

                      {loadingCountry ? (
                        <Skeleton
                          height={35}
                          containerClassName="avatar-skeleton"
                        />
                      ) : (
                        <ReactFlagsSelect
                          selected={selected}
                          onSelect={handleCountrySelect}
                          countries={countries}
                          blacklistCountries={blacklistCountries}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-center">
            <div className="container-fluid ">
              <div className="row">
                <div className="col-lg-2 col-md-12">
                  {/* <Link component={RouterLink} to={`/`}> <img src="img/logo.png" alt="" width="180px"
                                        className="img-reponsive" />
                                    </Link> */}
                  <a href="/">
                    {" "}
                    <img
                      src="/img/logo.png"
                      alt="logo"
                      width="180px"
                      className="img-reponsive"
                    />
                  </a>
                </div>
                <div className="col-lg-10 col-md-12">
                  <div className="header-wrapeer">
                    <div className="fx-wrapings">
                      <div className="serch-fields">
                        <div className="col-lg-12 col-md-5 v-center header-search hidden-xs hidden-sm">
                          <form
                            className="searchform"
                            role="search"
                            style={{ width: "100% !important" }}
                          >
                            <div className="search-panel">
                              <select
                                className="mui-select" // Add a custom class for styling
                                id="category"
                                onChange={(e) => {
                                  categoryset(e);
                                }}
                              >
                                {/* <option className="level3" value={JSON.stringify({ id: "", subCategory: dataR,allCategory:true })}>All Category</option> */}
                                <option
                                  className="level3"
                                  value={JSON.stringify({ id: "" })}
                                >
                                  All Category
                                </option>

                                {dataR?.map((item) => (
                                  <option
                                    className="level3"
                                    value={JSON.stringify({
                                      id: item.id,
                                      slug: item.slug,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <input type="hidden" name="type" value="product" />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Find Cars, Mobiles, Jobs and more..."
                              onChange={(e) => searchset(e)}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  sendData(category, categorySlug);
                                }
                              }}
                            />
                            <span className="input-group-btn">
                              <button
                                className="button_search"
                                type="button"
                                onClick={(e) => {
                                  sendData(category, categorySlug);
                                }}
                                catdata={category}
                              >
                                <i className="ion-ios-search-strong"></i>
                              </button>
                            </span>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>
      <div
        className="modal fade"
        id="modal-country"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog country-dialog">
          <div className="modal-content country-content">
            <div className="modal-header country-header">
              <h5 className="modal-title country-title " id="exampleModalLabel">
                {" "}
                <i className="fa fa-map-marker" aria-hidden="true"></i> Select
                your country
              </h5>
              <button
                type="button"
                className="close country-close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="country-in">
                <ul>
                  <li>
                    <div className="count-bo-fg">
                      {/* <select 
                                            className='form-control' 
                                            style={{ height: "63px" }}
                                            onChange={(e) => {
                                                var value = e.target.value.split(",")
                                                setSetCountryC(value)
                                            }}
                                            >                                                  
                                             <option value="">{setcountryC}</option>
                                                {
                                                    country.map((item) =>
                                                <option key={item.cca2} value={[item.name, item.cca2]} selected={item.name === setcountryC} >{item.name}</option>                                                    )
                                                }
                                            </select> */}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
