export const sliceAndEllipsis = (inputString) => {
  let isSpace = hasNoSpaces(inputString);
  if (inputString.length > 20 && isSpace) {
    return inputString.slice(0, 20) + "...";
  }
  return inputString;
};

export const sliceStringHandler = (inputString) => {
  if (inputString.length > 25) {
    return inputString.slice(0, 25) + "...";
  }
  return inputString;
};

function hasNoSpaces(inputString) {
  const spacePattern = /\s/;
  return !spacePattern.test(inputString);
}
