import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import Settings from "../../../services/settings";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CircularProgress from "@mui/material/CircularProgress";

const MyAds = () => {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  // console.log("decoded",decoded);
  const [dataAds, setDataAds] = useState([]);
  const [loading, setLoading] = useState(true);

  const myAdsList = () => {
    Settings.myAds(decoded.sub)
      .then((response) => {
        setDataAds(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setLoading(false);
          console.log("No Data Found", error.response.data.message);
          // toast.error(`Error: ${error.response.data.message}`, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          //   setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   setLoading(false);
        // }
        setLoading(false);
        console.log(error);
      });
  };

  const soldPublish = (sold, publish, id) => {
    Settings.soldORPublish(sold, publish, id)
      .then((response) => {
        console.log(response.data.data);
        if (publish === !sold) {
          toast.success("Your Post Publish Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        } else if (sold) {
          toast.success("Your Post SOLD Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        } else {
          toast.success("Your Post UnPublish Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        myAdsList();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        // //   setLoading(false);
        // }
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    myAdsList();
  }, []);

  return (
    <div className="" id="profile-v">
      {loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <CircularProgress size={60} /> {/* Customize size as needed */}
          </div>
        </div>
      ) : (
        <>
          <h3>My Ads</h3>
          <section className="privacy-policy mt-4">
            <div className="container-fluid">
              <div className="row">
                {dataAds.length === 0 ? (
                  <>
                    <img
                      src="./images/ImageNotFound.png"
                      style={{
                        display: "block",
                        width: "180px",
                        margin: "0 auto",
                      }}
                      alt="image not found"
                    />{" "}
                    <p
                      className="text-center"
                      style={{ fontWeight: "700", fontSize: "20px" }}
                    >
                      No Post Found
                    </p>
                    <p className="text-center" style={{ fontSize: "20px" }}>
                      No advertisement could be found here!
                    </p>{" "}
                  </>
                ) : (
                  dataAds?.map((item, i) => (
                    <div className="col-lg-3 my-3">
                      <div className="swiper-slide">
                        <div className="fd-x-wraper">
                          {item.featured ? (
                            <div className="featured-s">
                              <h3>Featured</h3>
                            </div>
                          ) : null}

                          <div className="heart-cx-icon ch_anjani">
                            {!item.sold ? (
                              <div className="square-box anjani">
                                <button
                                  className="box_fg"
                                  onClick={() =>
                                    soldPublish(true, item.status, item.id)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Sold Ads"
                                >
                                  <i
                                    className="fa fa-tag"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            ) : null}

                            <div class="new">
                              <div class="form-groups">
                                <input
                                  type="checkbox"
                                  id={`html${item.id}`}
                                  checked={item.status}
                                  onChange={(e) =>
                                    soldPublish(
                                      item.sold,
                                      e.target.checked,
                                      item.id
                                    )
                                  }
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="Publish Ads"
                                />
                                <label for={`html${item.id}`}>&nbsp;</label>
                              </div>
                            </div>
                          </div>
                          <Link
                            to={"/productdetail"}
                            state={{ data: dataAds[i] }}
                          >
                            <div
                              className={`s-img ${item.sold ? "proImg" : null}`}
                            >
                              <img
                                src={item.productImages[0].imageUrl}
                                alt={item.name}
                              />
                            </div>
                          </Link>
                          <Link
                            to={"/productdetail"}
                            state={{ data: dataAds[i] }}
                          >
                            <div className="cover-wh">
                              <div className="cx-h-text">
                                <h1>
                                  {loading ? (
                                    <Skeleton width={250} />
                                  ) : item?.price === "$0.00" ? (
                                    <div>
                                      {"$"}
                                      {item?.attribute?.map((item, index) => {
                                        if (
                                          item.type === "Salary From" ||
                                          item.type === "Salary To"
                                        ) {
                                          return (
                                            <span key={index}>
                                              {item.name}
                                              {item.type === "Salary From"
                                                ? "-"
                                                : null}
                                            </span>
                                          );
                                        }
                                        return null; // Return null for other items in the attribute array
                                      })}
                                    </div>
                                  ) : (
                                    item?.price
                                  )}
                                </h1>
                                <p>{item.title}</p>
                              </div>
                              <div className="bottom-covers">
                                <div className="_3rmDx">
                                  <div className="_2VQu4">
                                    {item.productAddresses.state}{" "}
                                    {item.productAddresses.country}
                                  </div>
                                  <div className="_2jcGx">
                                    <span>
                                      {dayjs(item.createdAt).format("DD/MMM")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default MyAds;
