import http from "../services/http-common";
import authHeader from "./auth-header";

 const getDynamicFieldList = (id) => http.get(`/master/post/category/${id}`);

const getCountryList =() => http.get(`/api/location/mycountrylist`);

const getUser = (id) => http.get(`user/${id}`);

const postProductSummary = (categoryId, featured) =>
  http.post(
    `/product/price/summary?categoryId=${categoryId}&featured=${featured}`,
    {},
    {
      headers: authHeader(), // Call the authHeader function to get the headers
    }
  );

  const postCouponCode = (coupon) =>
  http.post(
    `/coupon/check?coupon=${coupon}`,
    {},
    {
      headers: authHeader(), // Call the authHeader function to get the headers
    }
  );

  // let config = {
  //   headers: {
  //     Authorization: `Bearer ${accessToken}`,
  //     "Content-Type": "multipart/form-data;",
  //   },
  // };

  const postAd = (data) => {
    debugger; 

    // Create an empty JavaScript object to store the formData contents
    const formDataObject = {};
  
    // Iterate over the formData object and populate the formDataObject
    data.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));
    console.log(data);
    return http.post(`/product/postad`, data, { 
      headers: {
        ...authHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const getRelatedProdcuts = (id) => {
    return http.get(`/product/post/related?productId=${id}`,{
      headers: authHeader()
    });
  }

  const searchProducts = (search,minprice,maxprice,categoryId,subCategorId,setcountry) =>{
    return http.get(`/product/post/all/category?name=${search}&minprice=${minprice}&maxprice=${maxprice}&categoryid=${categoryId}&subcategoryid=${subCategorId}&sort=productId&sort=desc&page=0&size=25&country=${setcountry}`,{
      headers: authHeader()
    })
  }

  const viewAllRecommandProducts = (searchs,setcountry) => {
    return http.get(`/product/viewall?title=${searchs}&country=${setcountry}`,{
      headers: authHeader()
    });
  }

  const getStateByCountryCode = (code) =>{
    return http.get(`/api/location/state/${code}`,{
      headers: authHeader()
    })
  }

const PostService = {
  getDynamicFieldList,
  postProductSummary,
  postCouponCode,
  postAd,
  getUser,
  getCountryList,
  getRelatedProdcuts,
  searchProducts,
  viewAllRecommandProducts,
  getStateByCountryCode
};

export default PostService;
