import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../../services/user.service";
import SubscriptionService from "../../../services/subscription.services";
import { formatTransactionTime } from "../../../helpers/DateTimeFormatter";
import { CircularProgress } from "@mui/material";

const TransactionList = ({ item }) => {
  const { description, totalAmount, credit, debit, createdAt } = item;

  return (
    <div className="col-xs-12  col-sm-12 p-0">
      <div className="box-my my-0 p-0 box-myboder box-myboder-2 ">
        <div className="my-points-2 widthsthd">
          {/* <div className="fafa-1">
            <div className="fafa-2">
              <div className="fafa-4">
                <i className="fa fa-clipboard" aria-hidden="true"></i>
              </div>
            </div>
          </div> */}
          <div className="width10">
            <img
              src={`${
                (description.includes("registered ") && "./img/amount.png") ||
                (description.includes("Welcome") && "./img/amount.png") ||
                (description.includes("posted") && "./img/add-post.png") ||
                "./img/referral-coins.png"
              }`}
              alt={`${
                (description.includes("registered ") && "./img/amount.png") ||
                (description.includes("Welcome") && "./img/amount.png") ||
                (description.includes("posted") && "./img/add-post.png") ||
                "./img/referral-coins.png"
              }`}
              style={{ width: "90px" }}
            />
          </div>
          <div className="pl-3 width60">
            <h4>{description}</h4>
            <p>{formatTransactionTime(createdAt, 7)}</p>
          </div>
          <div className="pl-3 width30 text-right">
            <h4
              className={`${debit !== null ? "text-danger" : "text-success"}`}
            >
              {debit !== null
                ? `-${debit}`
                : credit !== null
                ? `+${credit}`
                : "0"}
            </h4>
            <p>Balance Left : {totalAmount}</p>
          </div>
        </div>
      </div>
      <hr style={{ margin: "5px 0px" }} />
    </div>
  );
};

const MyWallet = () => {
  const [walletDetails, setWalletDetails] = useState(null);
  const [topupDetails, setTopupDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isWalletScreen = location.state?.walletScreen || false; // Default to an empty object
  const navigate = useNavigate();

  const getWalletDetailsHandler = async () => {
    let res = await UserService.getUserStatement();
    setWalletDetails(res.data.data);
    setLoading(false);
    try {
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTopupDetailsHandler = async () => {
    try {
      let res = await SubscriptionService.getTopup();
      setTopupDetails(res.data.data.topups);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getWalletDetailsHandler();
    getTopupDetailsHandler();
  }, []);

  return (
    <div className={`${isWalletScreen ? "active" : ""}`} id="mywallet-v">
      {loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <CircularProgress size={60} /> {/* Customize size as needed */}
          </div>
        </div>
      ) : (
        <>
          <h3>My Wallet</h3>

          <div className="container-fluid  mt-3">
            <div className="row p-0 bg-primary">
              <div className="col-xs-12 col-sm-6 p-0">
                <div className="box-my box-myboder  ">
                  <div className="my-points">
                    <div className="fafa-1">
                      <div className="fafa-2">
                        <div className="fafa-4">
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>

                    <div className="pl-5">
                      <p>Total Coins</p>
                      <h3>{walletDetails?.totalCredit}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6 p-0 ">
                <div className="box-my">
                  <div className="my-points">
                    <div className="fafa-1">
                      <div className="fafa-2">
                        <div className="fafa-3">
                          <i className="fa fa-usd" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>

                    <div className="pl-5">
                      <p>Balance Coins</p>
                      <h3>{walletDetails?.totalAmount}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3 p-0 ">
              <div className="col-xs-12 p-0">
                <div className="box-dot" onClick={() => navigate("/top-up")}>
                  <div className=" text-center text-primary py-3">
                    <h3>Top Up</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-3 p-0 ">
              <div className="col-xs-2 p-0">
                <div className="  ">
                  <div className="py-3">
                    <h4> Transaction ({walletDetails?.statement?.length})</h4>
                  </div>
                </div>
              </div>
              {/* <div className="col-xs-10 text-right p-0 ">
            <div className="  ">
              <div className="     py-3">
                <a href="#" className="text-white contact_form_submit-2">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDAyIDc5LjE2NDM1MiwgMjAyMC8wMS8zMC0xNTo1MDozOCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjEgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjZBQzNGOTZCNEQ0MDExRUVCRUVDOEEwMURGNzcyNEI0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjZBQzNGOTZDNEQ0MDExRUVCRUVDOEEwMURGNzcyNEI0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6NkFDM0Y5Njk0RDQwMTFFRUJFRUM4QTAxREY3NzI0QjQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NkFDM0Y5NkE0RDQwMTFFRUJFRUM4QTAxREY3NzI0QjQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6b5S23AAABRklEQVR42mL8//8/Ay0BEwONAc0tYPnw8SMyXwCI3YD4JRAfJMYAAX5+/BYgsaWB+BQQ/wZiESBeAcQpFPsAiOuBWAqIOYD4HhDbA7E2EF+EBuFvfAaghQA6eIYeB4xQDBOnNIn9Z3z/4QOMIwUNor9ALAzEy4A4jZpx8AwaNLBIPkSNVITsA7IAzAfAuHAAUhJAvAMo9oGq+QBo+DwgtQWIO4H4KpAvg+wDWCoiF7ACcQIQ6wHxdWj+ASWUK9hSEUXBjUaDUxEokhspjQNgkPwDMo8D8TtosFsAxZ9QO5LtgJQkNJI/kpOK2IDYE5r5tsNyOCn5AB9gB+LDQKwA5YOKFJCLf5FSFuEDElDDVaCReAeIVwPxC1LLIkKphJGSsohQ+IOKDiUkH4BK3V/UigNQWNsCsQeUv4NQMQ739milTwgABBgA+MBjJm2NPDwAAAAASUVORK5CYII="
                    alt=""
                  />{" "}
                </a>
              </div>
            </div>
          </div> */}
            </div>
            {/* <p>{walletDetails?.statement?.length}. Transactions </p> */}
            {walletDetails?.statement?.map((item) => (
              <TransactionList
                key={item.id}
                item={item}
                topupDetails={topupDetails}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MyWallet;
