import {
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Stack,
  CircularProgress,
  CssBaseline,
  Avatar,
  Link,
} from "@mui/material";

import { Box as MatBox } from "@mui/material";
import React from "react";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import HttpsIcon from "@mui/icons-material/Https";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fbLogo from "../../img/fb-logo.png";
import googleLogo from "../../img/google-logo.png";
import { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL } from "../../constant";
import { Modal } from "@mui/base/Modal";
import { styled, Box } from "@mui/system";
import PropTypes from "prop-types";
import clsx from "clsx";
import AuthService from "../../services/auth.service";
import { Helmet } from "react-helmet-async";

export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const initialUserService = {
    email: "",
    password: "",
    deviceType: "Web",
  };

  const [user, setUser] = React.useState(initialUserService);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const isValidEmail = (email) => {
    // Email validation regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleLogin = async () => {
    setLoading(true);

    if (!user.email) {
      toast.error("Please enter your email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }
    if (!user.password) {
      toast.error("Please enter your password", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }

    const isEmailValid = isValidEmail(user.email);

    // Display error messages for invalid fields
    if (!isEmailValid) {
      toast.error("Invalid email format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    AuthService.login(user)
      .then((response) => {
        setUser({ ...user });
        const localuser = localStorage.getItem("user");
        if (localuser) {
          localStorage.setItem("user", "");
        }
        if (response.data.data.accessToken) {
          localStorage.setItem("user", response.data.data.accessToken);
        }
        console.log(response.data.data);
        setLoading(false);
        // toast.success("Success : Loggedin Successfully", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        navigate("/");
        console.log(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the response contains a message, display it as the error message
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   setLoading(false);
        // }

        console.log(error);
      });
  };

  const forgetIntitalState = {
    email: "",
  };
  const [forgot, setForgot] = useState(forgetIntitalState);
  const [loadingForget, setLoadingForget] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);

  const handleInputChangeForget = (event) => {
    const { name, value } = event.target;
    setForgot({ ...forgot, [name]: value });
  };

  const handleForgotPassword = async () => {
    setLoadingForget(true);

    if (!forgot.email) {
      toast.error("Please enter your email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingForget(false);
      setForgotSuccess(false);
      return; // Return early if email is blank
    }

    const isEmailValid = isValidEmail(forgot.email);

    // Display error messages for invalid fields
    if (!isEmailValid) {
      toast.error("Invalid email format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingForget(false);
      setForgotSuccess(false);
      return;
    }

    AuthService.forgotPassword(forgot)
      .then((response) => {
        setForgot({ ...forgot });
        console.log(response.data.data);
        setForgotSuccess(true);
        setLoadingForget(false);
        toast.success("Success : OTP sent to your email!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the response contains a message, display it as the error message
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingForget(false);
          setForgotSuccess(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   setLoadingForget(false);
        //   setForgotSuccess(false)
        // }

        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Login | Carib Bargains</title>
        <meta name="description" content="Carib Bargains Login"></meta>
      </Helmet>

      <Container component="main" maxWidth="xs">
        <MatBox
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "15px",
            padding: "30px",
            background: "#fff",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "warning.main" }}>
            <HttpsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ mt: 1 }}
          >
            <SocialLogin />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ fontWeight: 500 }}
          >
            OR
          </Stack>
          <TextField
            name="email"
            label="Email address"
            className="input-label"
            value={user.email}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            autoFocus
            autoComplete="email"
          />

          <TextField
            fullWidth
            margin="normal"
            required
            autoComplete="password"
            name="password"
            label="Password"
            className="input-label"
            type={showPassword ? "text" : "password"}
            value={user.password}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <Checkbox
          name="remember"
          label="Remember me"
          checked={rememberMe}
          onChange={(e) => handleRememberMeChange(e.target.checked)}
        />
        </Stack> */}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="warning"
            onClick={handleLogin}
            loading={loading} // Use the 'loading' prop to show spinner
            loadingPosition="start" // Position the spinner at the start of the button
            startIcon={loading ? <CircularProgress size={20} /> : null} // Show spinner icon if loading
            sx={{ mt: 1, mb: 1 }}
          >
            Login
          </LoadingButton>

          <Grid container>
            <Grid item xs sx={{ mt: 2 }}>
              <div>
                <StyledModal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                  slots={{ backdrop: StyledBackdrop }}
                >
                  <Box sx={style}>
                    <h3 id="parent-modal-title">Forget Password?</h3>
                    <p id="parent-modal-description">
                      <TextField
                        name="email"
                        label="Email address"
                        value={forgot.email}
                        onChange={handleInputChangeForget}
                        fullWidth
                        margin="normal"
                        required
                        autoFocus
                      />
                    </p>
                    <ChildModal
                      loadingForget={loadingForget}
                      handleForgotPassword={handleForgotPassword}
                      forgotSuccess={forgotSuccess}
                      forgot={forgot}
                    />
                  </Box>
                </StyledModal>
              </div>
              <Link
                variant="body2"
                onClick={handleOpen}
                style={{ cursor: "pointer" }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <Link
                href="#"
                variant="body2"
                component={RouterLink}
                to={"/signup"}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </MatBox>
      </Container>
    </React.Fragment>
  );
}

const SocialLogin = () => {
  return (
    <div className="social-login">
      <a className="btn btn-block social-btn google" href={GOOGLE_AUTH_URL}>
        <img src={googleLogo} alt="Google" />
        <span className="c_mts">Log in with Google </span>{" "}
      </a>
      <a className="btn btn-block social-btn facebook" href={FACEBOOK_AUTH_URL}>
        <img src={fbLogo} alt="Facebook" /> Log in with Facebook
      </a>
    </div>
  );
};

function ChildModal(props) {
  const { loadingForget, handleForgotPassword, forgotSuccess, forgot } = props; // Extract loadingForget from props

  const navigate = useNavigate();
  console.log("forgot", forgot);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (forgotSuccess) {
      // If forgotSuccess is true, open the modal
      setOpen(true);
    }
  }, [forgotSuccess]);

  const handleOpen = () => {
    // Call handleForgotPassword function
    handleForgotPassword();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialEmail = forgot.email || ""; // Use an empty string if forgot.email is blank or undefined

  const initalState = {
    email: initialEmail,
    otp: "",
  };
  const [otpValue, setOtpValue] = useState(initalState.otp);
  const [loadingOTP, setLoadingOTP] = useState(false);
  // const [otpSuccess, setOtpSuccess] = useState(false);

  const handleInputChangeOtp = (event) => {
    const { name, value } = event.target;
    if (name === "otp") {
      setOtpValue(value);
    }
  };

  const handleOtpVerify = async () => {
    debugger;
    setLoadingOTP(true);

    if (!otpValue) {
      toast.error("Please enter OTP", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingOTP(false);
      return; // Return early if email is blank
    }

    // Display error messages for invalid fields
    if (otpValue.length > 6) {
      toast.error("Otp must be 6 number only", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoadingOTP(false);
      return;
    }
    console.log("otp", otpValue);
    AuthService.verfiyOtp({ email: initialEmail, otp: otpValue })
      .then((response) => {
        console.log(response.data.data);
        setLoadingOTP(false);
        setOpen(false);
        toast.success("Success : OTP Verified Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/resetpassword", {
          state: {
            email: initialEmail,
          },
        });
        console.log(response.data.data);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the response contains a message, display it as the error message
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingOTP(false);
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingOTP(false);
        }

        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="warning"
        loading={loadingForget} // Use the 'loading' prop to show spinner
        loadingPosition="start" // Position the spinner at the start of the button
        startIcon={loadingForget ? <CircularProgress size={20} /> : null} // Show spinner icon if loading
        sx={{ mt: 3, mb: 2 }}
      >
        Submit
      </Button>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={[style, { width: "600px" }]}>
          <h3 id="child-modal-title">Verify Otp</h3>
          <p id="child-modal-description">
            <TextField
              name="otp"
              label="Enter your 6 Digit OTP"
              value={otpValue}
              onChange={handleInputChangeOtp}
              fullWidth
              margin="normal"
              required
              autoFocus
              autoComplete="otp"
            />
          </p>
          <Button
            onClick={handleOtpVerify}
            variant="contained"
            color="warning"
            loading={loadingOTP} // Use the 'loading' prop to show spinner
            loadingPosition="start" // Position the spinner at the start of the button
            startIcon={loadingOTP ? <CircularProgress size={20} /> : null} // Show spinner icon if loading
            sx={{ mt: 3, mb: 2 }}
          >
            Verify OTP
          </Button>
        </Box>
      </StyledModal>
    </React.Fragment>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const blue = {
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = (theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "12px",
  padding: "16px 32px 24px 32px",
  backgroundColor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === "dark" ? "#000" : "#383838"
  }`,
});

const TriggerButton = styled(Button)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  border-radius: 12px;
  padding: 6px 12px;
  line-height: 1.5;
  background: transparent;
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[100] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:focus-visible {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[500] : blue[200]};
  }
  `
);
