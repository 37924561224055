import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./modules/auth/Login";
import { ToastContainer } from "react-toastify";
import SignUp from "./modules/auth/SignUp";
import Root from "./root";
import Productpage from "./productpage/productpage";
import ErrorPage from "./error-page";
import Categories from "./pages/Categories";
import PostAdForm from "./pages/PostAdForm";
import PostAdFormStep2 from "./pages/PostAdFormStep2";
import ThankYou from "./pages/ThankYou";
import ChangePassword from "./pages/ChangePassword";
import OAuth2RedirectHandler from "./pages/oauth2/OAuth2RedirectHandler";
import Page404 from "./Page404";
import ProductSearch from "./productSearch/productSearch";
import ResetPassword from "./pages/ResetPassword";
import ChatPage from "./pages/Chat";
import TopUpPage from "./pages/TopUp";
import MyProfilePage from "./pages/MyProfile";
import EULA from "./FooterLink/eula";
import PrivacyPolicy from "./FooterLink/privacypolicy";
import TermCondition from "./FooterLink/termcondition";
// import Myprofile from './myProfile/myprofile';
import AboutUs from "./FooterLink/AboutUs";
import FAQ from "./FooterLink/FAQ";
import ContactUs from "./FooterLink/contactus";
import SellerChatPage from "./pages/SellerChatPage";
import ShareRedirect from "./productpage/shareRedirect";
import FacebookAuthTest from "./modules/auth/FacebookAuthTest";
import { HelmetProvider } from "react-helmet-async";
import AllCategoriesProducts from "./productSearch/AllCategoriesProducts";
import { hydrate } from "react-dom";
import { render } from "react-dom";
import PrivateRoute from "./PrivateRoute";
import Sitemap from "./Sitemap";
import axios from "axios";
import AppService from "./services/AppService";

// const [country, setCountry] = useState([]);

// const getCountryList = () => {
//   AppService.getCountryList()
//     .then((response) => {
//       setCountry(response.data.data.country);
//       // console.log("response.data.data.country", response.data.data.country);
//     })
//     .catch((error) => {
//       if (
//         error.response &&
//         error.response.data &&
//         error.response.data.message
//       ) {
//         toast.error(`${error.response.data.message}`, {
//           position: toast.POSITION.TOP_RIGHT,
//         });
//       } else {
//         // toast.error("Something went wrong!", {
//         //     position: toast.POSITION.TOP_RIGHT,
//         // });
//       }
//       console.log(error);
//     });
// };

// // console.log("country", JSON.stringify(country));

// useEffect(() => {
//   getCountryList();
// }, []);

const currentPhoneCode = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    const data = response.data;

    console.log("data", data);

    // Set default value for the code field based on the API response
    const defaultCode = data.country_calling_code; // Replace with your logic
    return defaultCode;
  } catch (error) {
    console.error("Error fetching IP API data:", error);
    return "+91";
    // Handle errors as needed
  }
};

const currentCountryCode = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    const data = response.data;

    console.log("data", data);

    // Set default value for the code field based on the API response
    const defaultCode = data.country_code; // Replace with your logic
    return defaultCode;
  } catch (error) {
    console.error("Error fetching IP API data:", error);
    return "kn";
    // Handle errors as needed
  }
};

const currentCountry = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    const data = response.data;
    console.log("data", data);
    return data;
  } catch (error) {
    console.error("Error fetching IP API data:", error);
    return {
      country_name: "Saint Kitts And Nevis",
      country_code: "KN",
    };
    // Handle errors as needed
  }
};

const getCountryList = async () => {
  try {
    const response = await AppService.getCountryList();
    const countryList = response.data.data.country;

    // Assuming you want to set the countryList in localStorage
    localStorage.setItem("countryList", JSON.stringify(countryList));

    return countryList;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      // Handle specific error if needed
      // toast.error(`${error.response.data.message}`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } else {
      // Handle other errors if needed
    }

    console.error(error);
    throw error; // rethrow the error to propagate it further
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    loader: currentCountry,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <App /> },
      { path: "productdetail", element: <Productpage /> },
      { path: "/:slug", element: <ProductSearch /> },
      //  { path: `/:categoryName${"-c"}:categoryId`, element: <ProductSearch /> },
      { path: "product/items", element: <AllCategoriesProducts /> },
      { path: "shareRedirect", element: <ShareRedirect /> },
      {
        path: "/my-profile",
        element: (
          <PrivateRoute element={<MyProfilePage />} fallbackPath="/login" />
        ),
        loader: currentCountryCode,
      },
      {
        path: "/top-up",
        element: <PrivateRoute element={<TopUpPage />} fallbackPath="/login" />,
      },
      {
        path: "/chat",
        element: <PrivateRoute element={<ChatPage />} fallbackPath="/login" />,
      },
      {
        path: "/seller-chat",
        element: (
          <PrivateRoute element={<SellerChatPage />} fallbackPath="/login" />
        ),
      },

      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "postad",
        element: <PostAdForm />,
        loader: currentCountryCode,
      },
      {
        path: "postad-step-next",
        element: <PostAdFormStep2 />,
      },
      {
        path: "thank-you",
        element: <ThankYou />,
      },
      {
        path: "/changepassword",
        element: <ChangePassword />,
      },
      {
        path: "/contactus",
        element: <ContactUs />,
      },
      {
        path: "/sitemap",
        element: <Sitemap />,
      },
    ],
  },
  {
    path: "/oauth2/redirect",
    element: <OAuth2RedirectHandler />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
    loader: currentPhoneCode,
  },

  {
    path: "/resetpassword",
    element: <ResetPassword />,
  },
  {
    path: "/eula",
    element: <EULA />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/term-condition",
    element: <TermCondition />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },

  // {
  //   path: "/facebookauth",
  //   element: <FacebookAuthTest />,
  // },

  { path: "*", element: <Page404 /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <HelmetProvider>
      <RouterProvider router={router} />
      <ToastContainer /> {/* Place the ToastContainer here */}
    </HelmetProvider>
  </>
);

// if (root.hasChildNodes()) {
//   hydrate(<Root />, root);
// } else {
//   render(<Root />, root);
// }

reportWebVitals();
