import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "./footer/footer";
import CategoriesService from "./services/CategoriesService";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function Sitemap() {
  const [dataR, setDataR] = useState([]);
  const getCategories = async () => {
    try {
      const response = await CategoriesService.getCategories();
      setDataR(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Sitemap</title>
        <meta name="description" content="Carib Bargains Sitemap"></meta>
      </Helmet>
      <div class="container">
        <div class="row my-4">
          <div class="col-lg-12 text-center my-3">
            <div class="_2KAJG">
              <h2>Categories</h2>
              <p class="my-3">
                Browse through some of our most popular categories.
              </p>
            </div>
          </div>
          <div class="col-lg-12 text-center mb-3">
            <div class="dfgst_3">
              {dataR?.map((item) => (
                <div class="dght_3">
                  <Link to={`/${item.slug}`}>
                    <img
                      src={item?.icon}
                      alt={item?.name}
                      className="desk_topss"
                      style={{ height: "35px" }}
                    />
                    <p>{item?.name}</p>
                  </Link>
                </div>
              ))}

              {/* <div class="dght_3">
                <a href="">
                  <img
                    src="images/car/laundry.png"
                    alt="Electronics &amp; Appliances"
                  />
                  <p>Electronics &amp; Appliances</p>
                </a>
              </div>
              <div class="dght_3">
                <a href="">
                  <img src="images/car/property.png" alt="Properties" />
                  <p>Properties</p>
                </a>
              </div>
              <div class="dght_3">
                <a href="">
                  <img src="images/car/taxi.png" alt="Cars" />
                  <p>Cars</p>
                </a>
              </div>
              <div class="dght_3">
                <a href="">
                  <img src="images/car/computer-table.png" alt="Furnitures" />
                  <p>Furnitures</p>
                </a>
              </div> */}
            </div>
          </div>
          {/* <div class="col-lg-12 text-center my-3">
            <div class="_2KAJG">
              <h2>Locations</h2>
              <p class="my-3">
                Browse through some of our most popular locations.
              </p>
            </div>
          </div>
          <div class="col-lg-12 text-center mb-3">
            <div class="dfgt_2">
              <div class="dfgt_3">
                <a href="#">Maharashtra</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Uttar Pradesh</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Kerala</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Tamil Nadu</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Karnataka</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Delhi</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Telangana</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Gujarat</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Punjab</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Haryana</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Andhra Pradesh</a>
              </div>
              <div class="dfgt_3">
                <a href="#">West Bengal</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Rajasthan</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Madhya Pradesh</a>
              </div>
              <div class="dfgt_3">
                <a href="#">Bihar</a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
