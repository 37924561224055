import { useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import CategoriesService from "../../services/CategoriesService";
import { UserContext } from "../../root";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet-async";
function Menu() {
  const [dataR, setDataR] = useState([]);
  const { countrySate, categories, categoryId } = useContext(UserContext);
  const [setcountryC, setSetCountryC] = countrySate;
  const [stateCategory, setStateCategory] = categories;
  const [mainCategoryId, setMainCategoryId] = categoryId;

  const [loading, setLoading] = useState(false);
  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await CategoriesService.getCategories();
      setDataR(response.data.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | Carib Bargains</title>
        <meta name="description" content="Carib Bargains Home"></meta>
      </Helmet>
      <section className="categories-pad">
        <div className=" v2 nospc">
          <div className="container-fluid mt-3">
            <div className="row  custom-cx-df ">
              <div className="col-lg-3 col-md-12 col-sm-12 ">
                <div>
                  {loading ? (
                    <Skeleton height={40} count={10} />
                  ) : (
                    <div className="widget-verticalmenu">
                      <div className="vertical-wrapper">
                        <ul className="vertical-group">
                          {dataR?.map((item) => (
                            <li className="vertical-item level1 vertical-drop">
                              <a href="javascript:void(0)">
                                <img
                                  src={item.icon}
                                  alt={item.name}
                                  style={{ width: "12%" }}
                                />
                                &nbsp;<h2>{item.name}</h2>{" "}
                              </a>
                              <div className="menu-level-1 dropdown-menu vertical-menu v2 tvbg pd2 style1">
                                <ul className="level1">
                                  <li className="level2 col-md-5">
                                    <ul className="menu-level-2">
                                      {item.subCategory.map((submenu) => (
                                        <li className="level3">
                                          <Link to={`/${submenu.slug}`}>
                                            {submenu.name}
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                {loading ? (
                  <Skeleton height={450} count={1} />
                ) : (
                  <Swiper
                    navigation={true}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {/* <SwiperSlide>
                      <img src="img/banner.jpg" alt="" />
                    </SwiperSlide> */}
                    <SwiperSlide>
                      <div class="slide-txt">
                        <h2>
                          {" "}
                          Buy and Sell with <br className="desk-none" /> Ease
                        </h2>
                        <p>
                          Find an item, negotiate with <br /> seller and make
                          the deal done.
                        </p>
                      </div>

                      <img
                        src="img/slider-1.png"
                        alt="Buy and Sell with Ease"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <div class="slide-txt3">
                        <h2>
                          {" "}
                          Sell in Nearby
                          <br className="desk-none" /> Countries
                        </h2>
                        <p>
                          Bargain Beyond <br className="desk-none" /> Borders!
                          Reach Buyers <br /> in Nearby Countries Easily.
                        </p>
                      </div>
                      <img
                        src="img/slider-3.png"
                        alt="Sell in Nearby Countries"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <div class="slide-txt">
                        <h2>Premium Ad post</h2>
                        <p>
                          Boost your Ad perormanced by <br /> showing them
                          showcase positions.
                        </p>
                      </div>

                      <img src="img/slider-2.png" alt="Premium Ad post" />
                    </SwiperSlide>

                    <SwiperSlide>
                      <div class="slide-txt">
                        <h2>Refer & Earn</h2>
                        <p>
                          Invite Friends and get additional <br /> coins for
                          every successful referral.
                        </p>
                      </div>

                      <img src="img/slider-4.png" alt="Refer & Earn" />
                    </SwiperSlide>
                  </Swiper>
                )}
                {/* <div className="slide-home3">
                                <div className="e-slide v2 js-slider-3items">
                                    <div className="e-slide-img">
                                        <a href="#"><img src="./images/Header/home-screen.jpg" alt="" /></a>
                                        <div className="slide-content v2">
                                            <p className="cate v2">PROFESSIONL</p>
                                            <h2 className="v2" style={{ color: "#fff" }}>INTERIOR
                                                DESIGN <br />
                                                SERVICE</h2>

                                            <br />
                                            <a href=""> <span className="enquiry-now"> ENQUIRY NOW </span></a>
                                        </div>
                                    </div>
                                    <div className="e-slide-img">
                                        <a href="#"><img src="img/sli-2.png" alt="" /></a>
                                        <div className="slide-content v2">
                                            <p className="cate v2">PROFESSIONL</p>
                                            <h2 className="v2" style={{ color: "#fff" }}>INTERIOR
                                                DESIGN <br />
                                                SERVICE</h2>

                                            <br />
                                            <a href=""> <span className="enquiry-now"> ENQUIRY NOW </span></a>
                                        </div>
                                    </div>
                                    <div className="e-slide-img">
                                        <a href="#"><img src="img/sli-3.png" alt="" /></a>
                                        <div className="slide-content v2">
                                            <p className="cate v2">PROFESSIONL</p>
                                            <h2 className="v2" style={{ color: "#fff" }}>INTERIOR
                                                DESIGN <br />
                                                SERVICE</h2>

                                            <br />
                                            <a href=""> <span className="enquiry-now"> ENQUIRY NOW </span></a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Menu;
