import { useState, useEffect} from "react";
import jwtDecode from "jwt-decode";
import { CHAT_API_BASE_URL } from "../config";
import ChatServices from "../services/chat.services";
import { useMessageCount } from "../MessageContext";

var stompClient = null;
const useChatNotificationService = () => {
  const [messageCount,setMessageCount] = useState(0);
  const { incrementNewMessageCount } = useMessageCount();
  
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const UserId = decoded?.sub;
  const connect = () => {
    const Stomp = require("stompjs");
    var SockJS = require("sockjs-client");
    SockJS = new SockJS(CHAT_API_BASE_URL);
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError);
  };

  const onError = (error) => {
    console.log("Error:", error);
  };

  const onConnected = () => {
    stompClient.subscribe(
      "/user/" + UserId + "/queue/messages",
      onMessageReceived
    );
  };

  const onMessageReceived = (msg) => {
    const message = JSON.parse(msg.body);
   
    // console.log('Received a new message:', msg);
    if (message.senderId != UserId) {
        // setMessageCount(messageCount + 1);
        getAllChatsRecentHandler()
    }
  };

  const getAllChatsRecentHandler = async () => {
    
    try {
      let res = await ChatServices.getAllChats();
      let msgCount = res.data?.map(item=>item.messageCount)
      const sumCount = msgCount?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      incrementNewMessageCount(sumCount);
    } catch (error) {}
  };

  // useEffect(() => {
  //   connect();
  //   // getAllChatsRecentHandler()
  // }, []);

  return {messageCount,getAllChatsRecentHandler,setMessageCount,connect};
};

export default useChatNotificationService;
