import React from 'react'
import HomeTabContent from './tabs/HomeTabContent'
import BuyingTabContent from './tabs/BuyingTabContent'
import SellingTabContent from './tabs/SellingTabContent'
import SupportTabContent from './tabs/SupportTabContent'

const ChatContent = () => {
  return (
    <div className="tab-content">
     

             <HomeTabContent/>
             <BuyingTabContent/>
             <SellingTabContent/>
             <SupportTabContent/>
    
            </div>
  )
}

export default ChatContent