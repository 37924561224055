import jwtDecode from "jwt-decode";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import AppService from "../../../services/AppService";
const Referearn = () => {
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  // console.log("decoded",decoded);
  const isReferEran = window.location.href.includes("referearn") || false; // Default to an empty object
  const navigate = useNavigate();

  useEffect(() => {
    // Your effect logic here

    return () => {
      // Cleanup function
      if (window.location.hash === "#referearn") {
        // Remove the hash fragment from the URL
        navigate(window.location.pathname + window.location.search);
      }
    };
  }, [navigate]);

  const [data, setData] = useState({});

  const getReferAndEarn = () => {
    AppService.getReferAndEarn()
      .then((respone) => {
        setData(respone.data.referandearn);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getReferAndEarn();
  }, []);

  return (
    <div className={`${isReferEran ? "active" : ""}`} id="referearn">
      <div className="container">
        <div className="row ">
          <div className="col-lg-12 mb-3">
            <h3>Refer & Earn</h3>
          </div>
          <div className="col-lg-12 ">
            <div class="box-cover">
              <div class="referal-img">
                <img src="images/logo/coupon.png" alt="coupon" />
              </div>
              {/* <p>Use my referral \ code to downlaod the crib bargains app and earn 500 coins Worth $25. Also invite your friends to join your our app and both of you can earn 500 coins!</p> */}
              <div className="text-center">
                <b>
                  Refer Now and Earn Upto {data?.referCoins} coins worth $
                  {data?.referAmount}
                </b>
                <br />
                Share this referral code with your friends via
                SMS/Email/Whatsapp.
                <br />
                They will also earn {data?.referCoins} coins extra on successful
                registration with this referral code.
              </div>

              <div class="row my-3">
                <div class="col-lg-4 "></div>
                <div class="col-lg-4 ">
                  <div class="referal-code-box">
                    <h4 className="text-center refral-c">Referral code</h4>
                    <p className="text-center refral-c">
                      {decoded.referralCode}
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 "> </div>
              </div>

              <div className="row ">
                <div className="col-lg-12">
                  {/* <div className="btn-share">
 <div class="verify-bt mr-0 mt-3  text-center">
  <a class="btn btn-primary" >Share</a>
  <RWebShare
      data={{
      text:  `Hindi`,
      // url: `https://caribbargains.com/shareRedirect?post=${productData.id}`,
      title: `${decoded.referralCode}`,
      }}
  >
      <button><i className="fa fa-share-alt" aria-hidden="true"></i></button>
  </RWebShare>
 
 </div>
 </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Referearn;
