import React, { useEffect, useState } from "react";
import SubscriptionService from "../../../services/subscription.services";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { CircularProgress, Link } from "@mui/material";

function Subscription() {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isSubscriptionScreen = location?.state?.subscriptionScreen || false;

  const getSubscriptionHandler = async () => {
    try {
      let res = await SubscriptionService.getSubscription();
      setSubscriptionDetails(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  useEffect(() => {
    getSubscriptionHandler();
  }, [isSubscriptionScreen]);

  console.log("subscriptionDetails", subscriptionDetails);

  //   // Assuming you have the validityDate variable like this
  // const validityDate = "2023-10-20T05:57:13.283444"; // Replace this with your actual date

  // Convert the validityDate to a Date object
  const targetDate = new Date(subscriptionDetails?.validityDate);

  // Calculate the remaining days
  const currentDate = new Date();
  const timeDifference = targetDate - currentDate;
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  // if (loading) {
  //   // Display a loading indicator here if needed
  //   return <div>Loading...</div>;
  // }

  return (
    <div
      className={`${isSubscriptionScreen ? "active" : ""}`}
      id="subscription"
    >
      {loading ? (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <CircularProgress size={60} /> {/* Customize size as needed */}
          </div>
        </div>
      ) : (
        <div className="container">
          {subscriptionDetails ? (
            <div className="row">
              <div className="col-lg-12">
                <h3>Power Pass</h3>
                <p>Post more Ads at low cost by purchasing Power Pass</p>
              </div>
              <div className="col-lg-8 my-3">
                <div className="contains_box">
                  <div className="ms_x">
                    <div className="rows">
                      <div className="box_mains">
                        <div className="cons_df">
                          <h4 className="user-heads m-0">
                            Type : {subscriptionDetails?.subscription?.type}
                          </h4>
                        </div>
                        <div className="img_subs">
                          <img
                            src="images/logo/subscription.png"
                            alt="subscription"
                          />
                        </div>
                      </div>
                    </div>
                    <hr></hr>

                    <p className="user-heads">
                      ${subscriptionDetails?.subscription?.price}
                    </p>

                    <div className="box_mains">
                      <div className="cxts_cild">
                        <p className="type_tris">Discount</p>
                        <p className="type_tris">
                          {subscriptionDetails?.subscription?.discount}%
                        </p>
                      </div>
                      <div className="cxts_cild">
                        <p className="type_tris">Validity</p>
                        <p className="type_tris">{daysLeft} Days</p>
                      </div>
                      <div className="cxts_cild">
                        <p className="type_tris">Total Ad Post</p>
                        <p className="type_tris">
                          {subscriptionDetails?.addLeft} Ads
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                component={RouterLink}
                to={"/top-up"}
                underline="none"
                className="btn-primary side-use-btn"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  marginRight: "10px",
                  marginTop: "10px",
                  textDecoration: "none",
                  color: "white",
                }}
              >
                Buy Subscription
              </Link>
            </div>
          ) : (
            <div className="text-center" style={{ marginTop: "10%" }}>
              <div>
                <div class="no-chat-message mt-0">
                  <img src="./img/subscriptins.png" alt="subscriptins" />
                </div>
                <h3 class="text_no">No subscription found</h3>
                <p class="text_no">
                  <Link
                    component={RouterLink}
                    to={"/top-up"}
                    underline="none"
                    color="orange"
                    style={{ fontWeight: 600, fontSize: "16px" }}
                  >
                    Click here to Subscribe
                  </Link>
                </p>
                <Link
                  component={RouterLink}
                  to={"/top-up"}
                  underline="none"
                  className="btn-primary side-use-btn"
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    marginRight: "10px",
                    marginTop: "10px",
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  Buy Subscription
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Subscription;
