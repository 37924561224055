 import { Container } from "@mui/material";
import React, { useEffect } from "react";

  
  export default function EULA() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
   
    return (
      <Container component="main">
          <div id="contact" class="form">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <h2>End-User License Agreement (EULA) for Carib Bargains</h2>
          <ul class="list-unstyled li-space-lg">
            <li class="address">
              Last Updated: Monday 11<sup>th</sup> September 2023
            </li>
          </ul>
        </div>
        <div class="col-lg-12 pb-3 text-wrapper">
        </div>
        <div class="col-lg-12 pb-3 text-wrapper">
          <p><b>PLEASE READ THIS EULA CAREFULLY BEFORE USING CARIB BARGAINS.</b></p>
          <ol type="1">
            <li>
              <p>
                <b>Acceptance of Terms:</b> By downloading, installing, or using Carib Bargains (the "App" & the "Web"), you agree to be bound by the terms and conditions of this End-User License Agreement ("EULA"). If you do not agree to these terms, do not download, install, or use the App.
              </p>
            </li>
            <li>
              <p>
                <b>License Grant:</b>Carib Bargains grants you a non-exclusive, non-transferable, revocable license to use the App for personal purposes on a mobile device or computer owned or controlled by you, in accordance with this EULA.
              </p>
            </li>
            <li>
              <p>
                <b>Restrictions:</b> 
                <ol>
                    <li>
                        <p>
                            Modify, adapt, translate, reverse engineer, decompile, disassemble, or create derivative works based on the App.
                        </p>
                    </li>
                    <li>
                        <p>
                        Use the App for any purpose that is illegal or prohibited by this EULA.
                        </p>
                    </li>
                    <li>
                        <p>
                        Remove, alter, or obscure any copyright, trademark, or other proprietary notices from the App.
                        </p>
                    </li>
                    <li>
                        <p>
                        Circumvent or disable any security or technical features of the App.
                        </p>
                    </li>
                </ol>
              </p>
            </li>
            <li>
              <p>
                <b>Ownership:</b> The App is licensed, not sold. Carib Bargains retains all rights, title, and interest in and to the App, including all intellectual property rights.
              </p>
            </li>
            <li>
              <p>
                <b>Updates:</b> Carib Bargains may, from time to time, provide updates or upgrades to the App. You agree to install and use these updates or upgrades as a condition of your continued use of the App.
              </p>
            </li>
            <li>
              <p>
                <b>Privacy:</b> Your use of the App is also governed by our Privacy Policy, which is available at Privacy Policy. By using the App, you consent to the collection and use of your data in accordance with our Privacy Policy.
              </p>
            </li>
            <li>
              <p>
                <b>Termination:</b> This EULA is effective until terminated. Your rights under this license will terminate automatically without notice if you fail to comply with any term of this EULA. Upon termination, you must cease all use of the App and delete all copies of the App from your mobile device or computer.
              </p>
            </li>
            <li>
              <p>
                <b>No Tolerance for Objectionable Content or Abusive Users:</b> At Carib Bargains, we are committed to providing a safe and respectful online marketplace for all users. We maintain a strict policy against objectionable content and abusive behaviour within our community. This policy is designed to ensure that every user enjoys a positive and harassment-free experience while using our platform. We have zero tolerance for any form of content, communication, or behavior that violates our guidelines for respectful and responsible usage. If someone reports admin support for an objectionable or abusive content and after finding that to be true admin can block your access to the system and hide your data from everywhere including your posted ads, chats etc.
              </p>
            </li>
            <li>
              <p>
                <b>Disclaimer of Warranty:</b>THE APP IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
              </p>
            </li>
            <li>
              <p>
                <b>Limitation of Liability:</b>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, CARIB BARGAINS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OR INABILITY TO USE THE APP. 
              </p>
            </li>
            <li>
              <p>
                <b>Governing Law:</b>This EULA is governed by and construed in accordance with the laws of Caribbean’s without regard to its conflict of law principles.
              </p>
            </li>
            <li>
              <p>
                <b>Contact Information:</b>For any questions about this EULA, please <a href="https://www.caribbargains.com/index.php#contact"> contact</a> Carib Bargains.
              </p>
            </li>
            <li>
              <p>
                <b>Entire Agreement:</b>This EULA constitutes the entire agreement between you and Carib Bargains regarding the App and supersedes all prior and contemporaneous agreements and understandings, whether oral or written.
              </p>
            </li>
          </ol>
        </div>
       
       
        <div class="col-lg-12 pb-3 text-wrapper">
          
          <p>By accepting this EULA, you acknowledge that you have read, understood, and agree to be bound by its terms.</p>
          <p></p>
          
            
      </div>

    </div>

  </div>
  </div>
      </Container>
    );
  }
  
