import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyALfySRDlcA2XjL_J0rR8aGa-VcqdranXU",
//   authDomain: "caribbargains-2c856.firebaseapp.com",
//   projectId: "caribbargains-2c856",
//   storageBucket: "caribbargains-2c856.appspot.com",
//   messagingSenderId: "962167781087",
//   appId: "1:962167781087:web:9e072bf87acc67e7219b6a",
//   measurementId: "G-DSVCGZSJ82"
// };
// const firebaseConfig = {
//   apiKey: "AIzaSyBpz0rIBdnHyABXQq7YfHXQUPHSVMF8JYg",
//   authDomain: "caribbarainsauth.firebaseapp.com",
//   projectId: "caribbarainsauth",
//   storageBucket: "caribbarainsauth.appspot.com",
//   messagingSenderId: "976366143207",
//   appId: "1:976366143207:web:2659c7380d5613c27fc442",
//   measurementId: "G-T7X886QXZ7"
// };

const firebaseConfig = {
  apiKey: "AIzaSyC_hCruSVJveTi_4vVxaTSs0Qk3xxQbcmM",
  authDomain: "caribbargainsnew.firebaseapp.com",
  projectId: "caribbargainsnew",
  storageBucket: "caribbargainsnew.appspot.com",
  messagingSenderId: "645958508707",
  appId: "1:645958508707:web:0ef8f19f8267b391729f16",
  measurementId: "G-PXFGE601LG"
};


const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);