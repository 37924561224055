import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import dayjs from "dayjs";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppService from "../services/AppService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CategoriesService from "../services/CategoriesService";
import { Helmet } from "react-helmet-async";

function Productdata(prop) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let { id } = useParams();
  console.log("useParams", id);

  const [getMetaData, setMetaData] = useState();

  const getCategoryMetaData = () => {
    CategoriesService.getCategoryMeta(id)
      .then((response) => {
        setMetaData(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getCategoryMetaData();
  }, []);

  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;

  const data = prop.data;
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState(prop.allData);

  useEffect(() => {
    // When the `allData` prop changes, set `loading` to true
    setLoading(true);

    // Simulate a loading delay (1 second)
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
      setAllData(prop.allData); // Update the `allData` state
    }, 1000);

    // Clear the timeout if the component unmounts before the timeout completes
    return () => clearTimeout(loadingTimeout);
  }, [prop.allData]);

  // console.log("Productdata", allData)
  const initializeLikes = () => {
    const defaultLikes = {};
    allData.forEach((data) => {
      defaultLikes[data.id] = data.liked || false; // Use data.liked if available, or default to false
    });
    return defaultLikes;
  };
  const [likes, setLikes] = useState(initializeLikes());

  // const like = (productId) => {
  //     const newStatus = !likes[productId];
  //     setLikes((prevLikes) => ({
  //       ...prevLikes,
  //       [productId]: newStatus,
  //     }));

  //     AppService.productLikeByUserId(productId, newStatus)
  //       .then((response) => {
  //         console.log('Like response', response.data);
  //         if(newStatus){
  //             toast.success("Success: Added to your Wishlist", {
  //                 position: toast.POSITION.TOP_RIGHT,
  //               });
  //         }else{
  //             toast.success("Success: Removed from your Wishlist", {
  //                 position: toast.POSITION.TOP_RIGHT,
  //               });
  //         }

  //       })
  //       .catch((error) => {
  //         if (
  //           error.response &&
  //           error.response.data &&
  //           error.response.data.message
  //         ) {
  //           toast.error(`Error: ${error.response.data.message}`, {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         } else {
  //           toast.error("Something went wrong!", {
  //             position: toast.POSITION.TOP_RIGHT,
  //           });
  //         }
  //         console.log(error);
  //       });
  //   };

  const navigate = useNavigate();

  const handleLikeClick = () => {
    // Check if the user is logged in (you need to implement this logic)
    if (token) {
      const newStatus = !likes[data.id];

      // Update likes state
      setLikes((prevLikes) => ({
        ...prevLikes,
        [data.id]: newStatus,
      }));

      // Make API call to update the like status
      AppService.productLikeByUserId(data.id, newStatus)
        .then((response) => {
          console.log("Like response", response.data);
          if (newStatus) {
            toast.success("Success: Added to your Wishlist", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success("Success: Removed from your Wishlist", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(`${error.response.data.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          //   else {
          //       toast.error("Something went wrong!", {
          //           position: toast.POSITION.TOP_RIGHT,
          //       });
          //   }
          console.log(error);
        });
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    // Update likes when data changes
    setLikes(initializeLikes());
  }, [allData]);

  function truncateDescription(description) {
    if (description.length <= 60) {
      return description;
    }

    // Find the last space character within the first 80 characters
    const lastSpaceIndex = description.lastIndexOf(" ", 60);

    if (lastSpaceIndex !== -1) {
      // If a space is found within the first 80 characters, truncate at that space
      return description.slice(0, lastSpaceIndex) + "...";
    } else {
      // If no space is found within the first 80 characters, simply truncate at 80 characters
      return description.slice(0, 60) + "...";
    }
  }

  return (
    <>
      <Helmet>
        <title>{getMetaData?.name}</title>
        <meta name="description" content={getMetaData?.metaDescription}></meta>
        <meta name="keywordx" content={getMetaData?.metaKeyword}></meta>
      </Helmet>
      <div className="col-lg-3 my-3">
        {loading ? (
          <Skeleton
            count={1}
            wrapper={InlineWrapperWithMargin}
            inline
            width={210}
            height={275}
          />
        ) : (
          <div className="swiper-slide">
            <div className="fd-x-wraper">
              {data.featured ? (
                <div className="featured-s">
                  <h3>Featured</h3>
                </div>
              ) : null}

              <div className="heart-cx-icon">
                <button className="heartBtn" onClick={handleLikeClick}>
                  <i
                    className="fa fa-heart"
                    aria-hidden="true"
                    id="button"
                    style={{ color: likes[data.id] ? "#f00" : "#ddd" }}
                  ></i>
                </button>
              </div>
              <Link to={"/productdetail"} state={{ data: data }}>
                {/* <div className="s-img"> <img src={data.productImages[0].imageUrl} alt="" />
                        </div> */}
                <div className={`s-img ${data.sold ? "proImg" : null}`}>
                  <img src={data.productImages[0].imageUrl} alt={data?.title} />
                </div>
                <div className="cover-wh">
                  <div className="cx-h-text">
                    {/* <h1>{data.price}</h1> */}
                    <h3>
                      {loading ? (
                        <Skeleton width={250} />
                      ) : data?.price === "$0.00" ? (
                        <div>
                          {"$"}
                          {/* {data?.attribute?.map((item, index) => {
                                          if (index === 1 || index === 3) {
                                            return (
                                              <span key={index}>
                                              {item.name}{index === 1 ? '-' : null}
                                              </span>
                                            );
                                          }
                                          return null; // Return null for other indexes to skip rendering
                                        })} */}
                          {data?.attribute?.map((item, index) => {
                            if (
                              item.type === "Salary From" ||
                              item.type === "Salary To"
                            ) {
                              return (
                                <span key={index}>
                                  {item.name}
                                  {item.type === "Salary From" ? "-" : null}
                                </span>
                              );
                            }
                            return null; // Return null for other items in the attribute array
                          })}
                        </div>
                      ) : (
                        data?.price
                      )}
                    </h3>
                    <h2 className="cx-h-text2">
                      {truncateDescription(data.title)}
                    </h2>
                  </div>
                  <div className="bottom-covers">
                    <div className="_3rmDx">
                      <div className="_2VQu4">
                        {data.productAddresses.state}{" "}
                        {data.productAddresses.country}
                      </div>
                      <div className="_2jcGx">
                        <span>{dayjs(data.createdAt).format("DD/MMM")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Productdata;

function InlineWrapperWithMargin({ children }) {
  return React.createElement(
    "span",
    { style: { marginRight: "1rem " } },
    children
  );
}
