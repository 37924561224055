import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Stack,
  CircularProgress,
  CssBaseline,
  Avatar,
  Link,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { useLoaderData, useNavigate } from "react-router-dom";
import HttpsIcon from "@mui/icons-material/Https";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../../services/auth.service";
import { auth } from "./firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";

export default function SignUp() {
  const navigate = useNavigate();

  const phonecode = useLoaderData();

  console.log("phonecode", phonecode);

  const initialUserService = {
    name: "",
    email: "",
    mobile: "",
    password: "",
    code: phonecode,
    deviceType: "Web",
    referralCode: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  // const [phone, setPhone] = useState(initialUserService.mobile);
  const [isVerified, setIsVerified] = useState(false);
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  // const [countryCode, setCountrycode] = useState('');
  const [loading, setLoading] = useState(false);
  const [prevPhone, setPrevPhone] = useState("");
  const [prevPhoneCode, setPrevPhoneCode] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  let phoneNumber;

  const handleSend = (event) => {
    debugger;
    event.preventDefault();
    // Combine the countryCode and user.mobile
    phoneNumber = `+${user.code}${user.mobile}`;
    setPreviousNumber(phoneNumber);

    if (!user.code) {
      toast.error("Please select ISD Code", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }

    if (!user.mobile) {
      toast.error("Please fill your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }
    setHasFilled(true);
    generateRecaptcha();

    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpDialogOpen(true);
        toast.success("Success: OTP Sent Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
        // toast.error("Something went wrong!", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        // Remove the reCAPTCHA verifier if an error occurs
        window.recaptchaVerifier = null;
        appVerifier = null;
      });
  };

  const handleResendOtp = (event) => {
    debugger;
    event.preventDefault();

    // Logic to resend OTP
    setTimer(60); // Reset timer to 60 seconds
    setTimerReachedZero(false); // Reset timer reached zero flag
    // Combine the countryCode and user.mobile
    phoneNumber = `+${user.code}${user.mobile}`;

    if (!user.code) {
      toast.error("Please select ISD Code", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }

    if (!user.mobile) {
      toast.error("Please fill your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }
    setHasFilled(true);

    let appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpDialogOpen(true);
        toast.success("Success: OTP Sent Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
        // toast.error("Something went wrong!", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        // Remove the reCAPTCHA verifier if an error occurs
        window.recaptchaVerifier = null;
        appVerifier = null;
      });
  };

  const verifyOtp = (event) => {
    debugger;
    // let otp = event.target.value;
    setOtp(otp);
    if (!otp) {
      toast.error("Please enter your Otp", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (otp.length > 6) {
      toast.error("Otp must be 6 number only", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (otp.length < 6) {
      toast.error("Otp must be 6 number only", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          let user = result.user;
          // console.log(user);
          setPrevPhone(user.mobile);
          setPrevPhoneCode(user.code);
          console.log("User signed in successfully");
          // Close the confirmationResult window
          window.confirmationResult = null;

          // Reset the OTP dialog and data

          setOtpDialogOpen(false);
          setHasFilled(false);
          setIsVerified(true);
          toast.success("Success : OTP verified Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          toast.error("Invalid Otp!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          // alert('User couldn\'t sign in (bad verification code?)');
        });
    }
  };

  const handleCloseOtpDialog = () => {
    window.confirmationResult = null;
    window.recaptchaVerifier.clear();

    // Reset the OTP dialog and data

    setOtpDialogOpen(false);
    setHasFilled(false);
  };

  const [user, setUser] = useState(initialUserService);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("https://ipapi.co/json/");
  //       const data = response.data;

  //       console.log("data", data);

  //       // Set default value for the code field based on the API response
  //       const defaultCode = data.country_calling_code; // Replace with your logic
  //       setUser((prevUser) => ({ ...prevUser, code: defaultCode }));
  //     } catch (error) {
  //       console.error("Error fetching IP API data:", error);
  //       // Handle errors as needed
  //     }
  //   };

  //   fetchData(); // Call the function inside useEffect
  // }, []); // Empty dependency array means this effect runs once when the component mounts

  const [termsCondition, setTermsCondition] = useState(false);

  const handleTermsConditionChange = (e) => {
    setTermsCondition(e.target.checked);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const isValidEmail = (email) => {
    // Email validation regular expression
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  const isValidPhoneNumber = (mobile) => {
    // Phone number validation regular expression (adjust as needed)
    const phonePattern =
      /^\+?\d{10}$|^\+?\d{15}$|^\+?\d+\s?\d{10}$|^\+?\d+\s?\d{15}$/;
    return phonePattern.test(mobile);
  };

  const isValidName = (name) => {
    // Regular expression to allow only letters and spaces
    const namePattern = /^[A-Za-z\s]+$/;
    return namePattern.test(name);
  };

  const isValidReferralCode = (referralCode) => {
    // Regular expression to allow only numbers
    const codePattern = /^\d+$/;
    return codePattern.test(referralCode);
  };

  const handleSignup = async () => {
    debugger;
    setLoading(true);

    if (!user.name.trim()) {
      toast.error("Please Enter your name", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }
    if (!isValidName(user.name)) {
      toast.error("Name can only contain letters and spaces", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!user.email.trim()) {
      toast.error("Please enter your email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }

    const isEmailValid = isValidEmail(user.email);

    // Display error messages for invalid fields
    if (!isEmailValid) {
      toast.error("Invalid email format", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!user.code) {
      // Handle the case where the user hasn't selected a country code
      toast.error("Please select a ISD code", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }
    if (!user.mobile) {
      toast.error("Please enter your phone number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }
    if (!isVerified) {
      toast.error("Please Verifiy your mobile number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }
    if (!user.password) {
      toast.error("Please enter your password", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }

    if (user.referralCode !== "" && !isValidReferralCode(user.referralCode)) {
      toast.error("Referral code can only contain numbers", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (!termsCondition) {
      toast.error("Please Checked term & Condtion!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return; // Return early if email is blank
    }

    const isPhoneNumberValid = isValidPhoneNumber(user.mobile);

    if (!isPhoneNumberValid) {
      toast.error("Invalid phone number", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    const phoneNumberWithCode = `${user.code}`; // Combine country code with "+"

    AuthService.create({ ...user, code: phoneNumberWithCode })
      .then((response) => {
        setUser(initialUserService);
        setLoading(false);
        toast.success("Success : SignUp Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsVerified(false);
        console.log(response.data);
        navigate("/login");
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the response contains a message, display it as the error message
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        // else {
        //   toast.error("Something went wrong!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //   });
        //   setLoading(false);
        // }

        console.log(error);
      });
  };
  const [timer, setTimer] = useState(60);
  const [timerReachedZero, setTimerReachedZero] = useState(false);

  useEffect(() => {
    let interval;
    if (otpDialogOpen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerReachedZero(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [otpDialogOpen, timer]);

  const [previousNumbers, setPreviousNumber] = useState("");

  const phoneNumberWithCode = `+${user.code}${user.mobile}`;
  // // const  previousNumber= `${prevPhoneCode}${prevPhone}`;

  useEffect(() => {
    console.log("prevPhone", previousNumbers, "Phone", phoneNumberWithCode);

    if (previousNumbers === phoneNumberWithCode) {
      setIsVerified(true);
    }
  }, [user.code, user.mobile]);

  const [isItemSelected, setIsItemSelected] = useState(false);

  if (!hasFilled) {
    return (
      <div className="app__container">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Helmet>
            <title>Register | Carib Bargains</title>
            <meta name="description" content="Carib Bargains Register"></meta>
          </Helmet>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              borderRadius: "15px",
              padding: "30px",
              background: "#fff",
            }}
          >
            <Avatar sx={{ bgcolor: "warning.main" }}>
              <HttpsIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
            <TextField
              id="fullname"
              name="name"
              label="Full Name"
              value={user.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              autoFocus
              autoComplete="Full Name"
              inputProps={{ maxLength: 45 }}
            />

            <TextField
              id="email"
              name="email"
              label="Email address"
              value={user.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              autoComplete="email"
            />
            <TextField
              name="mobile"
              id="mobile"
              label="Phone number"
              type="number"
              fullWidth
              value={user.mobile}
              onChange={(event) => {
                const { name, value } = event.target;
                setUser({ ...user, [name]: value });
                setIsVerified(false); // Set isVerified to false when mobile number changes
              }}
              margin="normal"
              required
              autoComplete="Phone Number"
              InputProps={{
                inputProps: {
                  inputMode: "numeric", // Enables numeric keyboard on mobile devices
                  pattern: "[0-9]*", // Allows only numeric input
                },
                startAdornment: (
                  <FormControl variant="standard" sx={{ ml: -1, minWidth: 80 }}>
                    <Select
                      id="code"
                      label="code"
                      name="code"
                      value={user.code}
                      onChange={(e) => {
                        setUser({ ...user, code: e.target.value });
                        setIsVerified(false);
                      }}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.phone} value={country.phone}>
                          {country.label} ({country.phone})
                          {/* {isItemSelected ? `(+${country.phone})` : `${country.label} (${country.phone})`} */}
                          {/* {isItemSelected ? `${country.label} (${country.phone})` : `(+${country.phone})`} */}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ),
                endAdornment: (
                  <React.Fragment>
                    {isVerified ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSend}
                      >
                        Verify
                      </Button>
                    )}
                  </React.Fragment>
                ),
              }}
            />

            <TextField
              fullWidth
              margin="normal"
              id="password"
              required
              autoComplete="password"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={user.password}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              id="referralCode"
              name="referralCode"
              label="Refferal Code"
              value={user.referralCode}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              autoComplete="Refferal Code"
              inputProps={{ maxLength: 6 }}
            />
            <FormControlLabel
              id="termsCondition"
              control={<Checkbox checked={termsCondition} />} // Use the checked prop to set the checkbox state
              onChange={handleTermsConditionChange}
              // value={termsCondition}
              // onChange={handleTermsConditionChange}
              label={
                <Typography>
                  By creating an account, you agree to our{" "}
                  <a
                    href="https://caribbargains.com/term-condition"
                    target="_blank"
                    // rel="noopener noreferrer"
                    style={{ color: "blue", fontWeight: "bold" }}
                  >
                    Terms and Conditions
                  </a>
                </Typography>
              }
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="warning"
              onClick={handleSignup}
              loading={loading} // Use the 'loading' prop to show spinner
              loadingPosition="start" // Position the spinner at the start of the button
              startIcon={loading ? <CircularProgress size={20} /> : null} // Show spinner icon if loading
              sx={{ mt: 1, mb: 2 }}
            >
              Sign Up
            </LoadingButton>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  href="#"
                  variant="body2"
                  component={RouterLink}
                  to={"/login"}
                >
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <div id="recaptcha"></div>
      </div>
    );
  } else if (hasFilled) {
    return (
      <div className="app__container">
        <Dialog
          open={otpDialogOpen}
          onClose={handleCloseOtpDialog}
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogContent>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseOtpDialog}
              aria-label="close"
              sx={{ position: "absolute", top: 5, right: 12 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ mt: "10px", mb: "10px" }}
              variant="h5"
              component="div"
            >
              Enter the OTP
            </Typography>
            <TextField
              id="otp"
              fullWidth
              variant="outlined"
              label="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            {timerReachedZero ? (
              <div style={{ marginTop: 10 }}>
                <span variant="body2">If you didn't received a code? </span>
                <Link
                  variant="outlined"
                  color="primary"
                  onClick={handleResendOtp}
                  sx={{ mt: 2, cursor: "pointer" }}
                  size="small"
                >
                  Resend OTP
                </Link>
              </div>
            ) : (
              <div style={{ marginTop: "10px" }}>
                Time left:{" "}
                {Math.floor(timer / 60).toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                })}
                :
                {(timer % 60).toLocaleString(undefined, {
                  minimumIntegerDigits: 2,
                })}
              </div>
            )}
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="warning"
              onClick={verifyOtp}
              loading={loading}
              loadingPosition="start"
              startIcon={loading ? <CircularProgress size={20} /> : null}
              sx={{ mt: 2 }}
            >
              Verify Code
            </LoadingButton>
          </DialogContent>
        </Dialog>
        <div id="recaptcha"></div>
      </div>
    );
  }
}

const countryCodes = [
  { code: "AD", label: "Andorra", phone: "+376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "+971",
  },
  { code: "AF", label: "Afghanistan", phone: "+93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "+1-268",
  },
  { code: "AI", label: "Anguilla", phone: "+1-264" },
  { code: "AL", label: "Albania", phone: "+355" },
  { code: "AM", label: "Armenia", phone: "+374" },
  { code: "AO", label: "Angola", phone: "+244" },
  { code: "AQ", label: "Antarctica", phone: "+672" },
  { code: "AR", label: "Argentina", phone: "+54" },
  { code: "AS", label: "American Samoa", phone: "+1-684" },
  { code: "AT", label: "Austria", phone: "+43" },
  {
    code: "AU",
    label: "Australia",
    phone: "+61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "+297" },
  { code: "AX", label: "Alland Islands", phone: "+358" },
  { code: "AZ", label: "Azerbaijan", phone: "+994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "+387",
  },
  { code: "BB", label: "Barbados", phone: "+1-246" },
  { code: "BD", label: "Bangladesh", phone: "+880" },
  { code: "BE", label: "Belgium", phone: "+32" },
  { code: "BF", label: "Burkina Faso", phone: "+226" },
  { code: "BG", label: "Bulgaria", phone: "+359" },
  { code: "BH", label: "Bahrain", phone: "+973" },
  { code: "BI", label: "Burundi", phone: "+257" },
  { code: "BJ", label: "Benin", phone: "+229" },
  { code: "BL", label: "Saint Barthelemy", phone: "+590" },
  { code: "BM", label: "Bermuda", phone: "+1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "+673" },
  { code: "BO", label: "Bolivia", phone: "+591" },
  { code: "BR", label: "Brazil", phone: "+55" },
  { code: "BS", label: "Bahamas", phone: "+1-242" },
  { code: "BT", label: "Bhutan", phone: "+975" },
  { code: "BV", label: "Bouvet Island", phone: "+47" },
  { code: "BW", label: "Botswana", phone: "+267" },
  { code: "BY", label: "Belarus", phone: "+375" },
  { code: "BZ", label: "Belize", phone: "+501" },
  {
    code: "CA",
    label: "Canada",
    phone: "+1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "+61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "+243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "+236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "+242",
  },
  { code: "CH", label: "Switzerland", phone: "+41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "+225" },
  { code: "CK", label: "Cook Islands", phone: "+682" },
  { code: "CL", label: "Chile", phone: "+56" },
  { code: "CM", label: "Cameroon", phone: "+237" },
  { code: "CN", label: "China", phone: "+86" },
  { code: "CO", label: "Colombia", phone: "+57" },
  { code: "CR", label: "Costa Rica", phone: "+506" },
  { code: "CU", label: "Cuba", phone: "+53" },
  { code: "CV", label: "Cape Verde", phone: "+238" },
  { code: "CW", label: "Curacao", phone: "+599" },
  { code: "CX", label: "Christmas Island", phone: "+61" },
  { code: "CY", label: "Cyprus", phone: "+357" },
  { code: "CZ", label: "Czech Republic", phone: "+420" },
  {
    code: "DE",
    label: "Germany",
    phone: "+49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "+253" },
  { code: "DK", label: "Denmark", phone: "+45" },
  { code: "DM", label: "Dominica", phone: "+1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "+1-809",
  },
  { code: "DZ", label: "Algeria", phone: "+213" },
  { code: "EC", label: "Ecuador", phone: "+593" },
  { code: "EE", label: "Estonia", phone: "+372" },
  { code: "EG", label: "Egypt", phone: "+20" },
  { code: "EH", label: "Western Sahara", phone: "+212" },
  { code: "ER", label: "Eritrea", phone: "+291" },
  { code: "ES", label: "Spain", phone: "+34" },
  { code: "ET", label: "Ethiopia", phone: "+251" },
  { code: "FI", label: "Finland", phone: "+358" },
  { code: "FJ", label: "Fiji", phone: "+679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "+500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "+691",
  },
  { code: "FO", label: "Faroe Islands", phone: "+298" },
  {
    code: "FR",
    label: "France",
    phone: "+33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "+241" },
  { code: "GB", label: "United Kingdom", phone: "+44" },
  { code: "GD", label: "Grenada", phone: "+1-473" },
  { code: "GE", label: "Georgia", phone: "+995" },
  { code: "GF", label: "French Guiana", phone: "+594" },
  { code: "GG", label: "Guernsey", phone: "+44" },
  { code: "GH", label: "Ghana", phone: "+233" },
  { code: "GI", label: "Gibraltar", phone: "+350" },
  { code: "GL", label: "Greenland", phone: "+299" },
  { code: "GM", label: "Gambia", phone: "+220" },
  { code: "GN", label: "Guinea", phone: "+224" },
  { code: "GP", label: "Guadeloupe", phone: "+590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "+240" },
  { code: "GR", label: "Greece", phone: "+30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "+500",
  },
  { code: "GT", label: "Guatemala", phone: "+502" },
  { code: "GU", label: "Guam", phone: "+1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "+245" },
  { code: "GY", label: "Guyana", phone: "+592" },
  { code: "HK", label: "Hong Kong", phone: "+852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "+672",
  },
  { code: "HN", label: "Honduras", phone: "+504" },
  { code: "HR", label: "Croatia", phone: "+385" },
  { code: "HT", label: "Haiti", phone: "+509" },
  { code: "HU", label: "Hungary", phone: "+36" },
  { code: "ID", label: "Indonesia", phone: "+62" },
  { code: "IE", label: "Ireland", phone: "+353" },
  { code: "IL", label: "Israel", phone: "+972" },
  { code: "IM", label: "Isle of Man", phone: "+44" },
  { code: "IN", label: "India", phone: "+91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "+246",
  },
  { code: "IQ", label: "Iraq", phone: "+964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "+98",
  },
  { code: "IS", label: "Iceland", phone: "+354" },
  { code: "IT", label: "Italy", phone: "+39" },
  { code: "JE", label: "Jersey", phone: "+44" },
  { code: "JM", label: "Jamaica", phone: "+1-876" },
  { code: "JO", label: "Jordan", phone: "+962" },
  {
    code: "JP",
    label: "Japan",
    phone: "+81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "+254" },
  { code: "KG", label: "Kyrgyzstan", phone: "+996" },
  { code: "KH", label: "Cambodia", phone: "+855" },
  { code: "KI", label: "Kiribati", phone: "+686" },
  { code: "KM", label: "Comoros", phone: "+269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "+1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "+850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "+82" },
  { code: "KW", label: "Kuwait", phone: "+965" },
  { code: "KY", label: "Cayman Islands", phone: "+1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "+7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "+856",
  },
  { code: "LB", label: "Lebanon", phone: "+961" },
  { code: "LC", label: "Saint Lucia", phone: "+1-758" },
  { code: "LI", label: "Liechtenstein", phone: "+423" },
  { code: "LK", label: "Sri Lanka", phone: "+94" },
  { code: "LR", label: "Liberia", phone: "+231" },
  { code: "LS", label: "Lesotho", phone: "+266" },
  { code: "LT", label: "Lithuania", phone: "+370" },
  { code: "LU", label: "Luxembourg", phone: "+352" },
  { code: "LV", label: "Latvia", phone: "+371" },
  { code: "LY", label: "Libya", phone: "+218" },
  { code: "MA", label: "Morocco", phone: "+212" },
  { code: "MC", label: "Monaco", phone: "+377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "+373",
  },
  { code: "ME", label: "Montenegro", phone: "+382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "+590",
  },
  { code: "MG", label: "Madagascar", phone: "+261" },
  { code: "MH", label: "Marshall Islands", phone: "+692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "+389",
  },
  { code: "ML", label: "Mali", phone: "+223" },
  { code: "MM", label: "Myanmar", phone: "+95" },
  { code: "MN", label: "Mongolia", phone: "+976" },
  { code: "MO", label: "Macao", phone: "+853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "+1-670",
  },
  { code: "MQ", label: "Martinique", phone: "+596" },
  { code: "MR", label: "Mauritania", phone: "+222" },
  { code: "MS", label: "Montserrat", phone: "+1-664" },
  { code: "MT", label: "Malta", phone: "+356" },
  { code: "MU", label: "Mauritius", phone: "+230" },
  { code: "MV", label: "Maldives", phone: "+960" },
  { code: "MW", label: "Malawi", phone: "+265" },
  { code: "MX", label: "Mexico", phone: "+52" },
  { code: "MY", label: "Malaysia", phone: "+60" },
  { code: "MZ", label: "Mozambique", phone: "+258" },
  { code: "NA", label: "Namibia", phone: "+264" },
  { code: "NC", label: "New Caledonia", phone: "+687" },
  { code: "NE", label: "Niger", phone: "+227" },
  { code: "NF", label: "Norfolk Island", phone: "+672" },
  { code: "NG", label: "Nigeria", phone: "+234" },
  { code: "NI", label: "Nicaragua", phone: "+505" },
  { code: "NL", label: "Netherlands", phone: "+31" },
  { code: "NO", label: "Norway", phone: "+47" },
  { code: "NP", label: "Nepal", phone: "+977" },
  { code: "NR", label: "Nauru", phone: "+674" },
  { code: "NU", label: "Niue", phone: "+683" },
  { code: "NZ", label: "New Zealand", phone: "+64" },
  { code: "OM", label: "Oman", phone: "+968" },
  { code: "PA", label: "Panama", phone: "+507" },
  { code: "PE", label: "Peru", phone: "+51" },
  { code: "PF", label: "French Polynesia", phone: "+689" },
  { code: "PG", label: "Papua New Guinea", phone: "+675" },
  { code: "PH", label: "Philippines", phone: "+63" },
  { code: "PK", label: "Pakistan", phone: "+92" },
  { code: "PL", label: "Poland", phone: "+48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "+508",
  },
  { code: "PN", label: "Pitcairn", phone: "+870" },
  { code: "PR", label: "Puerto Rico", phone: "+1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "+970",
  },
  { code: "PT", label: "Portugal", phone: "+351" },
  { code: "PW", label: "Palau", phone: "+680" },
  { code: "PY", label: "Paraguay", phone: "+595" },
  { code: "QA", label: "Qatar", phone: "+974" },
  { code: "RE", label: "Reunion", phone: "+262" },
  { code: "RO", label: "Romania", phone: "+40" },
  { code: "RS", label: "Serbia", phone: "+381" },
  { code: "RU", label: "Russian Federation", phone: "+7" },
  { code: "RW", label: "Rwanda", phone: "+250" },
  { code: "SA", label: "Saudi Arabia", phone: "+966" },
  { code: "SB", label: "Solomon Islands", phone: "+677" },
  { code: "SC", label: "Seychelles", phone: "+248" },
  { code: "SD", label: "Sudan", phone: "+249" },
  { code: "SE", label: "Sweden", phone: "+46" },
  { code: "SG", label: "Singapore", phone: "+65" },
  { code: "SH", label: "Saint Helena", phone: "+290" },
  { code: "SI", label: "Slovenia", phone: "+386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "+47",
  },
  { code: "SK", label: "Slovakia", phone: "+421" },
  { code: "SL", label: "Sierra Leone", phone: "+232" },
  { code: "SM", label: "San Marino", phone: "+378" },
  { code: "SN", label: "Senegal", phone: "+221" },
  { code: "SO", label: "Somalia", phone: "+252" },
  { code: "SR", label: "Suriname", phone: "+597" },
  { code: "SS", label: "South Sudan", phone: "+211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "+239",
  },
  { code: "SV", label: "El Salvador", phone: "+503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "+1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "+963",
  },
  { code: "SZ", label: "Swaziland", phone: "+268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "+1-649",
  },
  { code: "TD", label: "Chad", phone: "+235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "+262",
  },
  { code: "TG", label: "Togo", phone: "+228" },
  { code: "TH", label: "Thailand", phone: "+66" },
  { code: "TJ", label: "Tajikistan", phone: "+992" },
  { code: "TK", label: "Tokelau", phone: "+690" },
  { code: "TL", label: "Timor-Leste", phone: "+670" },
  { code: "TM", label: "Turkmenistan", phone: "+993" },
  { code: "TN", label: "Tunisia", phone: "+216" },
  { code: "TO", label: "Tonga", phone: "+676" },
  { code: "TR", label: "Turkey", phone: "+90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "+1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "+688" },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "+886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "+255",
  },
  { code: "UA", label: "Ukraine", phone: "+380" },
  { code: "UG", label: "Uganda", phone: "+256" },
  {
    code: "US",
    label: "United States",
    phone: "+1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "+598" },
  { code: "UZ", label: "Uzbekistan", phone: "+998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "+379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "+1-784",
  },
  { code: "VE", label: "Venezuela", phone: "+58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "+1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "+1-340",
  },
  { code: "VN", label: "Vietnam", phone: "+84" },
  { code: "VU", label: "Vanuatu", phone: "+678" },
  { code: "WF", label: "Wallis and Futuna", phone: "+681" },
  { code: "WS", label: "Samoa", phone: "+685" },
  { code: "XK", label: "Kosovo", phone: "+383" },
  { code: "YE", label: "Yemen", phone: "+967" },
  { code: "YT", label: "Mayotte", phone: "+262" },
  { code: "ZA", label: "South Africa", phone: "+27" },
  { code: "ZM", label: "Zambia", phone: "+260" },
  { code: "ZW", label: "Zimbabwe", phone: "+263" },
];
