import {
    Box,
    Container,
    TextField,
    Typography,
    Stack,
    CircularProgress,
    CssBaseline,
    InputAdornment,
    IconButton
  } from "@mui/material";
  import React, { useEffect } from "react";
  import AuthService from "../services/auth.service";
  import { useState } from "react";
  import { LoadingButton } from "@mui/lab";
  import { toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import PostService from "../services/PostService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
  
  export default function ResetPassword() {
    // Use the useLocation hook to access the location state
const location = useLocation();
const { email } = location.state || {};

console.log("email",email);

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    const initialState = {
      email:"",
      password:"",
      newpassword:""
    };
  
    const [password, setPassword] = useState(initialState.password);
    const [newpassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
  
    console.log("user", initialState);
    const handleInputChangeOtp = (event) => {
      const { name, value } = event.target;
      if (name === "password") {
        setPassword(value);
      } else if(name === 'newpassword'){
        setNewPassword(value);
      }
    };
    const handleResetPassword = () => { debugger;
      setLoading(true);
  
      if (!newpassword) {
        toast.error("New Password field can't be blank", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (newpassword.length < 6) {
        toast.error("Password length can't be less then 6 characters", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
      if (!password) {
        toast.error("Confirm Password field can't be blank", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
      if (password.length < 6) {
        toast.error("Password length can't be less then 6 characters", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (password !== newpassword) {
        toast.error("Both password must be same!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
  
      AuthService.resetPassword({email:email, password:password})
        .then((response) => {
          console.log(response.data.data);
          setLoading(false);
          toast.success("Success : Password Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/login');
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(`${error.response.data.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          } 
          // else {
          //   toast.error("Something went wrong!", {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          //   setLoading(false);
          // }
  
          console.log(error);
        });
    };
  
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "15px",
            padding: "30px",
            background: "#fff",
          }}
        >
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            required
            autoComplete="oldPassword"
            name="newpassword"
            label="New Password"
            value={newpassword}
            onChange={handleInputChangeOtp}
            autoFocus
          />
           <TextField
            fullWidth
            margin="normal"
            required
            autoComplete="password"
            name="password"
            label="Confirm Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handleInputChangeOtp}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
          />
  
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="warning"
            onClick={handleResetPassword}
            loading={loading} // Use the 'loading' prop to show spinner
            loadingPosition="start" // Position the spinner at the start of the button
            startIcon={loading ? <CircularProgress size={20} /> : null} // Show spinner icon if loading
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
  
        </Box>
      </Container>
    );
  }
  
  
  
  
  