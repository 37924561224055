import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService, { getUserStatement } from "../../../services/user.service";
import { IconButton, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AppFeedback from "../../../services/feedback.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Feedback = () => {

  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);

  const [feedback, setFeedback] = useState('');
  const [description, setDescription] = useState('Good');

  const selectFiles = (event) => {
    const files = event.target.files;

    let images = [];

    if (selectedFiles.length + files.length > 6) {
      // If selecting these files exceeds the limit, show an error message or take appropriate action.
      // For now, let's just console.log an error message.
      console.error("You can only select up to 6 images.");
      return;
    }

    for (let i = 0; i < files.length; i++) {
      images.push(URL.createObjectURL(files[i]));
    }

    // Update selectedFiles with the selected files
    setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...files]);
    setImagePreviews([...imagePreviews, ...images]);
    setProgressInfos({ val: [] });
    setMessage([]);
  };

  const handleInputChangeOtp = (event) => {
    const { name, value } = event.target;
    if (name === "feedback") {
      setFeedback(value);
    } 
  };



  const postFeedback = () =>{ debugger;

    //  Check if selectedFiles is not empty
    //  if (selectedFiles.length === 0) {
    //   toast.error("Error: No files selected.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    // Check if the number of selected files is greater than 6
    // if (selectedFiles.length > 6) {
    //   toast.error("Error: You can only select up to 6 images.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    if (!feedback) {
      toast.error("Please fill the text field", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; // Return early if email is blank
    }

    let formData = new FormData();
     // Append the 'files' field with the selectedFilesIMage
    
    formData.append("feedback", feedback);
    formData.append("description", description);
    for (const file of selectedFiles) {
      formData.append('files', file);
    }

    // Create an empty JavaScript object to store the formData contents
    const formDataObject = {};
  
    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log(formDataObject);
    AppFeedback.appFeedback(formData).then((response) =>{
      toast.success("Success : Feedback Send Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFeedback("");
      setImagePreviews([]);
      setSelectedFiles([]);

      console.log(response.data.data);
    }).catch((error) => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // If the response contains a message, display it as the error message
        toast.error(`Error: ${error.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // else {
      //   toast.error("Something went wrong!", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }

      console.log(error);
    });
  }
 return (
    <div class="" id="feedback">
    <h3>Feedback</h3>

    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-lg-12 p-0">
          <div class="mxt-pri">
            <div class="contains-box">
        <Typography component="h5" variant="h6" sx={{ mb: 1 }}>
          Upload up to 6 photos
        </Typography>

        <div>
          <div className="row">
            <div className="col-8">
              <div className="upload-btn">
                <label className="btn btn-default p-0 upload__btn">
                  <p className="mb-0 p-2">
                  <i class="fa fa-camera" aria-hidden="true"></i> Add Photo
                  </p>

                  <input
                    type="file"
                    multiple
                    name="files"
                    accept="image/*"
                    onChange={selectFiles}
                    className="upload__inputfile"
                  />
                </label>
              </div>
            </div>
          </div>

          {progressInfos &&
            progressInfos.val.length > 0 &&
            progressInfos.val.map((progressInfo, index) => (
              <div className="mb-2" key={index}>
                <span>{progressInfo.fileName}</span>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={progressInfo.percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: progressInfo.percentage + "%" }}
                  >
                    {progressInfo.percentage}%
                  </div>
                </div>
              </div>
            ))}

          {imagePreviews && (
            <div className="image-wrap">
              {imagePreviews.map((img, i) => {
                const handleRemoveImage = () => {
                  // Remove the image from imagePreviews
                  const updatedPreviews = [...imagePreviews];
                  updatedPreviews.splice(i, 1);

                  // Remove the image from selectedFiles if present
                  const updatedSelectedFiles = selectedFiles.filter(
                    (file, index) => index !== i
                  );

                  // Update the state
                  setImagePreviews(updatedPreviews);
                  setSelectedFiles(updatedSelectedFiles);
                };

                return (
                  <div key={i} className="image-container">
                    <div className="image-preview">
                      <img
                        className="preview"
                        src={img}
                        alt={"image-" + i}
                        width={150}
                        height={100}
                      />
                      <IconButton
                        className="remove-icon"
                        onClick={handleRemoveImage}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {message.length > 0 && (
            <div className="alert alert-secondary mt-2" role="alert">
              <ul>
                {message.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ul>
            </div>
          )}

          {imageInfos.length > 0 && (
            <div className="card mt-3">
              <div className="card-header">List of Images</div>
              <ul className="list-group list-group-flush">
                {imageInfos &&
                  imageInfos.map((img, index) => (
                    <li className="list-group-item" key={index}>
                      <p>
                        <a href={img.url}>{img.name}</a>
                      </p>
                      <img src={img.url} alt={img.name} height="80px" />
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
            </div>

            <div class="contains-box">
              <div class="txt-mxts">
                <div class="form-floating my-4">
                  <textarea
                    class="form-control"
                    placeholder="Tell us how we can improve..."
                    id="floatingTextarea"
                    rows="4"
                    name="feedback"
                    value={feedback}
                    onChange={handleInputChangeOtp}
                  ></textarea>
                </div>
                {/* <div>
                  <ul className="usser-feeback">
                    <li>
                      <p>pamdey ji hello what are you doing tos is not sure </p>

                    </li>
                    <li>
                      <p>pamdey ji hello what are you doing tos is not sure </p>

                    </li>
                    <li>
                      <p>pamdey ji hello what are you doing tos is not sure </p>

                    </li>
                  </ul>
                </div> */}

                <div class="submi_btn">
                  <button
                    type="button"
                    className="btn btn-primary side-use-btn"
                    onClick={postFeedback}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Feedback;
