import {
    Box,
    Container,
    TextField,
    Typography,
    Stack,
    CircularProgress,
    CssBaseline,
    InputAdornment,
    IconButton
  } from "@mui/material";
  import React, { useEffect } from "react";
  import AuthService from "../services/auth.service";
  import { useState } from "react";
  import { LoadingButton } from "@mui/lab";
  import { toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import jwtDecode from "jwt-decode";
  import PostService from "../services/PostService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
  
  export default function ChangePassword() {

    const navigate = useNavigate();
    const token = localStorage.getItem("user");
    const decoded = token ? jwtDecode(token) : null;
    console.log("decoded", decoded);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
  
    const UserId = decoded.sub;
  
    const [users, setUsers] = useState({});
    const [user, setUser] = useState({
      email: "", // Initialize email with an empty string
      oldPassword: "",
      newPassword: ""
    });
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      const getUser = () => {
        PostService.getUser(UserId)
          .then((response) => {
            const email = response.data.data.email;
            setUsers(response.data.data);
            setUser((prevUser) => ({
              ...prevUser,
              email: email || "" // Update email in the user state
            }));
            console.log("User data", response.data.data);
          })
          .catch((e) => {
            console.log(e);
          });
      };
  
      getUser();
    }, [UserId]);
  
    console.log("user", user);
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setUser({ ...user, [name]: value });
    };
  
    const handleLogin = async () => {
      setLoading(true);
  
      if (!user.oldPassword) {
        toast.error("Please enter your Old Password", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
      if (!user.newPassword) {
        toast.error("Please enter your New Password", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (user.oldPassword == user.newPassword) {
        toast.error("New Password must be different!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
  
      AuthService.chnagePassword(user)
        .then((response) => {
          setUser({ ...user });
          console.log(response.data.data);
          setLoading(false);
          toast.success("Password Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });

          // Prevent going back to the previous page
          window.history.pushState(null, "", "/"); // Replace current history entry with the root URL

          setLoading(false);
          AuthService.logout();
          // Navigate to the login page and reload the window
          navigate("/login");
          window.location.reload();
                  
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(`${error.response.data.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          } 
          // else {
          //   toast.error("Something went wrong!", {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          //   setLoading(false);
          // }
  
          console.log(error);
        });
    };
  
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "15px",
            padding: "30px",
            background: "#fff",
          }}
        >
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <TextField
            fullWidth
            margin="normal"
            required
            autoComplete="oldPassword"
            name="oldPassword"
            label="Old Password"
            value={user.oldPassword}
            onChange={handleInputChange}
            autoFocus
          />
           <TextField
            fullWidth
            margin="normal"
            required
            autoComplete="newPassword"
            name="newPassword"
            label="New Password"
            type={showPassword ? "text" : "password"}
            value={user.newPassword}
            onChange={handleInputChange}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
          />
  
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="warning"
            onClick={handleLogin}
            loading={loading} // Use the 'loading' prop to show spinner
            loadingPosition="start" // Position the spinner at the start of the button
            startIcon={loading ? <CircularProgress size={20} /> : null} // Show spinner icon if loading
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </LoadingButton>
  
        </Box>
      </Container>
    );
  }
  
  
  
  
  