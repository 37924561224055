import {
  Box,
  Button,
  Container,
  CssBaseline,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { LoadingButton } from "@mui/lab";
import { Link as RouterLink } from "react-router-dom";

export default function ThankYou() {
  const navigate = useNavigate();

  const BackToHome = () => {
    navigate("/");
  };
  useEffect(() => {
    window.onpopstate = () => {
      navigate("/");
      window.location.reload();
    };
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          borderRadius: "15px",
          padding: "30px",
          background: "#fff",
        }}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography component="h5" variant="h6">
            <ThumbUpIcon color="warning" fontSize="large" />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{ mt: 3 }}
        >
          <Typography component="h5" variant="h6" sx={{ fontSize: 24 }}>
            Thank You
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{ mt: 2 }}
        >
          <Typography component="h6" variant="p">
            Your AD is successfully Posted!
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{ mt: 3, mb: 2 }}
        >
          <Button
            onClick={BackToHome}
            color="warning"
            variant="contained"
            fullWidth
            id="thankyoubtn"
            size="large"
            sx={{ mt: 3, mb: 2, textDecoration: "none" }}
          >
            {" "}
            Back To Home
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}
