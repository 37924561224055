import http from "./http-common";
import authHeader from "./auth-header";

  const getTopup = () =>
  http.post(
    '/subscription/topup',
    {
      headers: authHeader(),
    }
  );

  const getSubscription = () =>
  http.get(
    `user/subscription/get`,
    {
      headers: authHeader(),
    }
  );

  const SubscriptionService = {
    getTopup,
    getSubscription
  };
  
  export default SubscriptionService;
  
