import React, { useState, useEffect, useRef } from "react";
import jwtDecode from "jwt-decode";
import ChatServices from "../../../services/chat.services";
import { getCurrentTimeWithAMPM } from "../../../helpers/DateTimeFormatter";
import { CHAT_API_BASE_URL } from "../../../config";
import { useLocation } from "react-router-dom";
import {
  sliceAndEllipsis,
  sliceStringHandler,
} from "../../../helpers/StringSlicer";
import { useMessageCount } from "../../../MessageContext";
import useChatNotificationService from "../../../hooks/useChatNotificationService";

var stompClient = null;
const SupportTabContent = () => {
  const { incrementNewMessageCount } = useMessageCount();
  const [messages, setMessages] = useState([]);
  const [currentChatDetails, setCurrentChatDetails] = useState({});
  const [textMessage, setTextMessage] = useState("");
  const [allChats, setAllChats] = useState(null);
  const location = useLocation();
  // const sellerResponse = location.state?.data || {}; // Default to an empty object
  const [sellerResponse, setSellerResponse] = useState(
    location.state?.data || null
  );
  const { getAllChatsRecentHandler } = useChatNotificationService();

  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const UserId = decoded.sub;
  const userName = decoded.name;
  const imageUrl = decoded.imageUrl || "../images/profile-picture.webp";
  const containerRef = useRef(null);
  const connect = () => {
    const Stomp = require("stompjs");
    var SockJS = require("sockjs-client");
    SockJS = new SockJS(CHAT_API_BASE_URL);
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError);
  };

  const onError = (error) => {
    console.log("Error:", error);
  };

  const onConnected = () => {
    stompClient.subscribe(
      "/user/" + UserId + "/queue/messages",
      onMessageReceived
    );
  };

  const onMessageReceived = (msg) => {
    const message = JSON.parse(msg.body);
    if (message.senderId !== UserId) {
      // let isMsg = messages.find((item) => item.id !== message.id);
      // if (isMsg) {
      //   return;
      // } else {
      setMessages((prevMessages) => [...prevMessages, message]);
      setTimeout(() => {
        scrollToBottom();
      }, 1300);
      getAllChatsHandlerAndOpen();
      // getAllChatsHandler();
      // }
    }
  };

  const scrollToBottom = () => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  const sendMessage = () => {
    if (textMessage.trim() !== "" && stompClient) {
      const chatMessage = {
        senderId: Number(UserId),
        senderName: userName,
        recipientId:
          currentChatDetails?.userSummary?.id ||
          sellerResponse?.userResponse?.id,
        recipientName:
          currentChatDetails?.userSummary?.name ||
          sellerResponse?.userResponse?.name,
        content: textMessage,
        timestamp: new Date(),
      };
      stompClient.send("/app/chat", {}, JSON.stringify(chatMessage));
      setMessages([...messages, chatMessage]);
      setTextMessage("");
      getAllChatsHandler();
      setTimeout(() => {
        scrollToBottom();
      }, 1300);
    }
  };

  const getAllChatsHandlerAndOpen = async () => {
    try {
      let res = await ChatServices.getSupportChats();
      setAllChats(res.data);

      openMessagesHandler(res.data);
    } catch (error) {}
  };

  const getAllChatsHandler = async () => {
    try {
      let res = await ChatServices.getSupportChats();
      setAllChats(res.data);
    } catch (error) {}
  };

  const [activeItem, setActiveItem] = useState(null);

  const openMessagesHandler = async (item) => {
    setActiveItem(item.recentMessage.chatId);
    if (item?.recentMessage) {
      let { recipientId, senderId } = item.recentMessage;
      try {
        let res = await ChatServices.getMessagesSupport(senderId, recipientId);
        setMessages(res.data);
        setCurrentChatDetails(item);
        getAllChatsRecentHandler();
        getAllChatsHandler();
        setTimeout(() => {
          scrollToBottom();
        }, 1000);
      } catch (error) {
        return error;
      }
    }
  };

  useEffect(() => {
    connect();
    getAllChatsHandlerAndOpen();
  }, []);

  return (
    <div
      className="tab-pane"
      id="support"
      role="tabpanel"
      aria-labelledby="support-tab"
    >
      <div className="row">
        <div className="col-lg-6 p-right ">
          {allChats === null ? (
            <div className="row">
              <div className="col-lg-12">
                <div className="no-chat-message">
                  <img src="./img/speech-bubble.png" alt="chat-icon" />
                  <h3 className="text_no">No Chat messages</h3>
                  <p className="text_no">
                    Start conversing to see your message here!
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <ul className="chat-user">
              <li
                key={allChats.id}
                onClick={() => openMessagesHandler(allChats)}
                style={{ cursor: "pointer" }}
                className={
                  activeItem === allChats.recentMessage.chatId
                    ? "active-item"
                    : ""
                }
              >
                <div className="min-cht-div">
                  <div className="user-img">
                    <img
                      src={
                        allChats.userSummary.profilePicture ||
                        "../images/profile-picture.webp"
                      }
                      alt="profile image"
                    />
                  </div>
                  <div className="user-text">
                    <h3 className="users-chat">
                      {allChats.userSummary.name}
                      {allChats.messageCount !== 0 && (
                        <span className="msgCount">
                          {allChats.messageCount}
                        </span>
                      )}
                    </h3>
                    <h3 style={{ fontSize: "15px", fontWeight: "500" }}>
                      {allChats?.recentMessage?.productTitle}
                    </h3>
                    <span className="time-show">
                      {" "}
                      {getCurrentTimeWithAMPM(
                        allChats?.recentMessage?.timestamp
                      )}
                    </span>

                    <p>
                      {sliceStringHandler(allChats?.recentMessage?.content)}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
        <div className="col-lg-6 p-left ">
          <div className="main-chat-box">
            <div className="chat-show-box">
              <div className="user-name">
                <div
                  className="msg-img"
                  style={{
                    backgroundImage: `url(${
                      currentChatDetails?.userSummary?.profilePicture ||
                      sellerResponse?.userResponse?.imageUrl ||
                      "../images/profile-picture.webp"
                    })`,
                  }}
                ></div>
                <h3 className="main-user-ct">
                  {currentChatDetails?.userSummary?.name ||
                    sellerResponse?.userResponse?.name}
                </h3>
              </div>
              <div className="user-detail">
                <a
                  href={`tel:${
                    currentChatDetails?.userSummary?.mobile ||
                    sellerResponse?.userResponse?.mobile
                  }`}
                >
                  <i className="fa fa-phone"></i>
                </a>
                <a
                  href={`mailto:${
                    currentChatDetails?.userSummary?.email ||
                    sellerResponse?.userResponse?.email
                  }`}
                >
                  <i className="fa fa-envelope"></i>
                </a>
              </div>
            </div>
            <div
              style={{ borderBottom: "2px solid #DADBDB", margin: "10px 0" }}
            >
              <h5 style={{ marginLeft: "2rem", paddingBottom: "4px" }}>
                {currentChatDetails?.recentMessage?.productTitle ||
                  sellerResponse?.title}
              </h5>
            </div>
            <div className="chat-message" ref={containerRef}>
              {messages?.map((item, index) => (
                <div
                  key={index}
                  className={`msg ${
                    item.senderId === Number(UserId) ? "right-msg" : "left-msg"
                  } mt-3`}
                >
                  <div
                    className="msg-img"
                    style={{
                      backgroundImage:
                        item.senderId === Number(UserId)
                          ? `url(${imageUrl})`
                          : `url(${
                              currentChatDetails?.userSummary?.profilePicture ||
                              sellerResponse?.userResponse?.imageUrl ||
                              "../images/profile-picture.webp"
                            })`,
                    }}
                  ></div>

                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-time">
                        {getCurrentTimeWithAMPM(item?.timestamp)}
                      </div>
                    </div>

                    <div
                      className="msg-text"
                      style={{ wordWrap: "break-word" }}
                    >
                      {item?.content}
                    </div>
                  </div>
                </div>
              ))}
              <div className="msger-inputarea">
                <input
                  type="text"
                  className="msger-input"
                  placeholder="Enter your message..."
                  data-last-active-input=""
                  value={textMessage}
                  onChange={(e) => setTextMessage(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                    }
                  }}
                />
                <button
                  onClick={sendMessage}
                  type="button"
                  className="msger-send-btn"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTabContent;
