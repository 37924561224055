import React, { useEffect, useState } from "react";
import "../styles/my-profile.css";
import Profile from "../modules/profile/components/Profile";
import MyAds from "../modules/profile/components/MyAds";
import MyFavoriteAds from "../modules/profile/components/MyFavoriteAds";
import MyWallet from "../modules/profile/components/MyWallet";
import Feedback from "../modules/profile/components/Feedback";
import MySettings from "../modules/profile/components/MySettings";
import "../myProfile/myprofile.css";
import jwtDecode from "jwt-decode";
import AuthService from "../services/auth.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PostService from "../services/PostService";
import Subscription from "../modules/profile/components/Subscription";
import Footer from "../footer/footer";
import Referearn from "../modules/profile/components/Referearn";
import ChatPage from "./Chat";
const MyProfilePage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const location = useLocation();
  const isWalletScreen = location.state?.walletScreen || false;
  const isSubscriptionScreen = location.state?.subscriptionScreen || false;
  const isChatScreen =
    location.state?.isChatScreen ||
    window.location.href.includes("chat") ||
    false;
  const isReferEran = window.location.href.includes("referearn") || false;

  const [user, setUsers] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    isReferEran
      ? "referearn"
      : isChatScreen
      ? "chat"
      : isSubscriptionScreen
      ? "subscription"
      : isWalletScreen
      ? "wallet"
      : "profile"
  );
  const [picture, setPicture] = useState("../images/profile-picture.webp");
  const asideLinkHandler = (tabName) => {
    setCurrentTab(tabName);
  };
  const UserId = decoded.sub;

  useEffect(() => {
    if (isChatScreen) {
      setCurrentTab("chat");
    }
  }, [isChatScreen]);

  useEffect(() => {
    const getUser = () => {
      PostService.getUser(UserId)
        .then((response) => {
          setUsers(response.data.data);
          console.log("User data", response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    getUser();
  }, [UserId]);

  const logOut = () => {
    AuthService.logout();
    // Navigate to the home page and reload the window
    navigate("/");
    window.location.reload();
  };

  function truncateDescription(name) {
    if (name?.length <= 30) {
      return name;
    }

    // Find the last space character within the first 80 characters
    const lastSpaceIndex = name?.lastIndexOf(" ", 30);

    if (lastSpaceIndex !== -1) {
      // If a space is found within the first 80 characters, truncate at that space
      return name?.slice(0, lastSpaceIndex) + "...";
    } else {
      // If no space is found within the first 80 characters, simply truncate at 80 characters
      return name?.slice(0, 30) + "...";
    }
  }

  return (
    <>
      <section className="privacy-policy mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-xs-12 col-sm-3 my-tab">
                  <div className="search-filed bg-primary mb-3 ">
                    <div className="min-box-user">
                      {/* <div
                      className="msg-img"
                      style={{ backgroundImage: "url(./img/chat.png)" }}
                    ></div> */}
                      {user.imageUrl === "" ? (
                        <div className="user-img">
                          <img
                            // style={{
                            //   backgroundImage:
                            //     "url(../images/profile-picture.webp)",
                            // }}
                            src="../images/profile-picture.webp"
                            alt="User Profile"
                          />
                        </div>
                      ) : (
                        <div className="user-img">
                          <img src={user.imageUrl} alt={user.name} />
                        </div>
                      )}
                      <div className="DxGiT2">
                        <div className="_2QZ3Er">Hello,</div>
                        <div className="_1ruvv2">
                          {truncateDescription(user?.name)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <ul className="nav nav-tabs tabs-left sideways">
                  <li className={`${isWalletScreen?"":"active"}`}>
                    <a href="#home-v" data-toggle="tab">
                      {" "}
                      My Profile
                    </a>
                  </li>
                  <li>
                    <a href="#profile-v" data-toggle="tab">
                      My Ads
                    </a>
                  </li>
                  <li>
                    <a href="#messages-v" data-toggle="tab">
                      My Favorite Ads
                    </a>
                  </li>
                  <li  className={`${isWalletScreen?"active":""}`}> 
                    <a href="#mywallet-v" data-toggle="tab">
                      My Wallet
                    </a>
                  </li>
                  <li>
                    <Link to={"/chat"}>
                      My Chat
                    </Link>
                  </li>
                  <li>
                  <a href="#subscription" data-toggle="tab">
                      Subscription
                  </a>
                  </li>
                  <li>
                    <a href="#settings-v" data-toggle="tab">
                     Settings
                    </a>
                  </li>
                  <li>
                    <a href="#feedback" data-toggle="tab">
                      Feedback
                    </a>
                  </li>
                  <li>
                    <a href="#referearn" data-toggle="tab">
                    Refer & Earn
                    </a>
                </li>
                  <li>
                    <a href="#" data-toggle="modal" data-target="#logout">
                     Logout
                    </a>
                  </li>
                </ul> */}
                  <ul className="nav nav-tabs tabs-left sideways">
                    <li
                      onClick={() => asideLinkHandler("profile")}
                      className={`${
                        isWalletScreen ||
                        isChatScreen ||
                        isReferEran ||
                        isSubscriptionScreen ||
                        currentTab === "ads" ||
                        currentTab === "fav-ads" ||
                        currentTab === "wallet" ||
                        currentTab === "chat" ||
                        currentTab === "referearn" ||
                        currentTab === "settings" ||
                        currentTab === "feedback" ||
                        currentTab === "subscription"
                          ? ""
                          : "active"
                      }`}
                    >
                      <a href="#home-v" data-toggle="tab">
                        {" "}
                        <i className="fa fa-user"></i> My Profile
                      </a>
                    </li>
                    <li
                      onClick={() => asideLinkHandler("ads")}
                      className={`${currentTab !== "ads" ? "" : "active"}`}
                    >
                      <a href="#profile-v" data-toggle="tab">
                        <i className="fa fa-bullhorn" aria-hidden="true"></i> My
                        Ads
                      </a>
                    </li>
                    <li
                      onClick={() => asideLinkHandler("fav-ads")}
                      className={`${currentTab !== "fav-ads" ? "" : "active"}`}
                    >
                      <a href="#messages-v" data-toggle="tab">
                        <i
                          className="fa fa-heart"
                          aria-hidden="true"
                          id="button"
                        ></i>{" "}
                        My Favorite Ads
                      </a>
                    </li>

                    <li
                      onClick={() => asideLinkHandler("wallet")}
                      className={`${currentTab === "wallet" ? "active" : ""}`}
                    >
                      <a href="#mywallet-v" data-toggle="tab">
                        <i
                          className="fa fa-google-wallet"
                          aria-hidden="true"
                        ></i>{" "}
                        My Wallet
                      </a>
                    </li>
                    {/* <li>
                    <Link to={"/chat"}>
                    <i className="fa fa-comment-o" aria-hidden="true"></i>      My Chat
                    </Link>
                  </li> */}

                    <li
                      onClick={() => asideLinkHandler("chat")}
                      className={`${currentTab === "chat" ? "active" : ""}`}
                    >
                      <a href="#chat" data-toggle="tab">
                        <i className="fa fa-comments" aria-hidden="true"></i> My
                        Chat
                      </a>
                    </li>
                    <li
                      onClick={() => asideLinkHandler("subscription")}
                      className={`${
                        currentTab === "subscription" ? "active" : ""
                      }`}
                    >
                      <a href="#subscription" data-toggle="tab">
                        <i className="fa fa-hand-o-up" aria-hidden="true"></i>{" "}
                        Subscription
                      </a>
                    </li>
                    <li
                      onClick={() => asideLinkHandler("settings")}
                      className={`${currentTab === "settings" ? "active" : ""}`}
                    >
                      <a href="#settings-v" data-toggle="tab">
                        <i className="fa fa-cog" aria-hidden="true"></i>{" "}
                        Settings
                      </a>
                    </li>
                    <li
                      onClick={() => asideLinkHandler("feedback")}
                      className={`${currentTab === "feedback" ? "active" : ""}`}
                    >
                      <a href="#feedback" data-toggle="tab">
                        <i className="fa fa-comment" aria-hidden="true"></i>{" "}
                        Feedback
                      </a>
                    </li>
                    <li
                      onClick={() => asideLinkHandler("referearn")}
                      className={`${
                        currentTab === "referearn" ? "active" : ""
                      }`}
                    >
                      <a href="#referearn" data-toggle="tab">
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                        Refer & Earn
                      </a>
                    </li>
                    <li>
                      <a href="#" data-toggle="modal" data-target="#logout">
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-xs-12 col-sm-9">
                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    {currentTab === "profile" && <Profile />}
                    {currentTab === "ads" && <MyAds />}
                    {currentTab === "fav-ads" && <MyFavoriteAds />}
                    {currentTab === "wallet" && <MyWallet />}
                    {currentTab === "settings" && <MySettings />}
                    {currentTab === "feedback" && <Feedback />}
                    {currentTab === "subscription" && <Subscription />}
                    {currentTab === "referearn" && <Referearn />}
                    {currentTab === "chat" && <ChatPage />}
                  </div>
                </div>
              </div>

              <div
                className="modal fade in"
                id="logout"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="false"
              >
                <div className="modal-dialog country-dialog">
                  <div className="modal-content country-content">
                    <div className="modal-header country-header">
                      <h5
                        className="modal-title country-title"
                        id="exampleModalLabel"
                      >
                        Are You Sure?
                      </h5>
                      <button
                        type="button"
                        className="close country-close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <p
                            id="exampleModalLabel"
                            style={{
                              marginLeft: 10,
                              fontWeight: 800,
                              fontSize: "15px",
                            }}
                          >
                            Do you want to logout
                          </p>
                          <div className="wraped-fo">
                            <div className="verify-bt mr-0 mb-4 mt-5 bts-center">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={logOut}
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MyProfilePage;
